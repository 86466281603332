import { useNavigate } from 'react-router-dom';
import { useAuth } from './AuthProvider';
import { useUnauthAxios } from '../../api/auth/useUnauthAxios';
import { LoginApiResponse } from '../../api/auth/login-api-response';
import { UserRole } from './model/user-role.enum';
import parseJWT from 'jwt-decode';

export interface ICompany {
  companyId: string;
  email: string;
  fromInvestmentCompany: string;
  industry: string;
  logos: string[];
  name: string;
  type: string;
}

interface IParsedToken {
  codes: string[];
  companies: ICompany[];
  expirs: string;
  name: string;
  role: string;
  userId: string;
  username: string;
}

export const useLogin = () => {
  const { setUser, setLoading, setCompanies } = useAuth();
  const navigate = useNavigate();
  const unauthAxios = useUnauthAxios();

  return (username: string, password: string) => {
    setLoading(true);

    return unauthAxios
      .post<LoginApiResponse>(`${process.env.REACT_APP_API_URL!}/login`, {
        username,
        password,
      })
      .then((response) => response.data)
      .then(({ result }) => {
        const parsedToken: IParsedToken = parseJWT(result);
        setCompanies(parsedToken.companies);

        return {
          username,
          // TODO: set user role from JWT
          role: UserRole.SYSTEM_ADMIN,
          token: result,
          userId: parsedToken.userId,
        };
      })
      .then((user) => {
        setUser(user);
        navigate('/');
      })
      .finally(() => {
        setLoading(false);
      });
  };
};
