import AddIcon from '@mui/icons-material/Add';
import DeleteIcon from '@mui/icons-material/Delete';
import {
  Button,
  FormControl,
  Grid,
  IconButton,
  InputLabel,
  MenuItem,
  Select,
  SelectChangeEvent,
  TextField,
} from '@mui/material';
import { t } from 'i18next';
import { observer } from 'mobx-react';
import { useEffect, useState } from 'react';
import styled from 'styled-components';
import QuestionsTable from '../../components/QuestionsTable';
import { Template } from '../../model';
import { useStores } from '../../stores/hooks/useStores';
import { NewQuestionTemplate } from './NewQuestionTemplate';
import SaveAsModal from './SaveAsModal';

const QuestionTemplates = () => {
  const [currentTemplateName, setCurrentTemplateName] = useState('');
  const [selectedTemplate, setSelectedTemplate] = useState<Template | null>(
    null,
  );
  const {
    questionTemplateStore: {
      isNewTemplate,
      setIsNewTemplate,
      setCurrentTemplateId,
      addQuestionGroup,
      getSelectedTemplate,
      currentTemplateId,
      questionTemplate,
      updateQuestionTemplate,
      deleteTemplate,
      templates,
      getTemplates,
      setQuestionTemplateName,
      totalNumberOfGroups,
      totalNumberOfQuestions,
      clearQuestionTemplate,
      setIsSaveAsDialogOpen,
    },
  } = useStores();

  useEffect(() => {
    getTemplates();

    if (currentTemplateId) {
      getSelectedTemplate();
    }

    // eslint-disable-next-line
  }, [currentTemplateId]);

  const handleChange = (event: SelectChangeEvent) => {
    setCurrentTemplateName(event.target.value as string);
  };

  return (
    <PageWrapper>
      <div
        style={{
          position: 'sticky',
          top: 0,
          zIndex: 1,
          paddingTop: '10px 0',
          background: '#fff',
        }}
      >
        <Grid container spacing={2}>
          <Grid item xs={6}>
            <FormControl fullWidth>
              <InputLabel>{t('common.questionTemplates')}</InputLabel>
              <Select
                value={currentTemplateName}
                label={t('common.questionTemplates')}
                onChange={handleChange}
              >
                {templates &&
                  templates
                    .filter((t) => !!t)
                    .map((template: Template, index: number) => {
                      if (!template) return null;

                      return (
                        <MenuItem
                          key={index}
                          value={template.name}
                          onClick={() => {
                            setSelectedTemplate(template);
                            setCurrentTemplateId(template.templatesId);
                          }}
                        >
                          {template.name}
                        </MenuItem>
                      );
                    })}
              </Select>
            </FormControl>
          </Grid>

          <Grid
            item
            xs={6}
            style={{
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'flex-start',
            }}
          >
            {!selectedTemplate ? (
              <StyledButton
                variant="contained"
                color="primary"
                onClick={() => {
                  setIsNewTemplate(true);
                }}
              >
                {t('common.addNewTemplate')}
              </StyledButton>
            ) : (
              <>
                <StyledButton
                  variant="contained"
                  color="primary"
                  onClick={async () => {
                    if (
                      window.confirm(
                        t('reports.areYouSureOverwriteQuestionTemplate') ||
                          'Are you sure you want to delete question template?',
                      )
                    ) {
                      await updateQuestionTemplate();
                    }
                  }}
                >
                  {t('common.save')}
                </StyledButton>

                <StyledButton
                  variant="contained"
                  color="primary"
                  onClick={() => setIsSaveAsDialogOpen(true)}
                >
                  {t('common.saveAs')}
                </StyledButton>

                <StyledButton
                  onClick={() => {
                    clearQuestionTemplate();
                    setCurrentTemplateName('');
                    setSelectedTemplate(null);
                  }}
                >
                  {t('common.cancel')}
                </StyledButton>

                <IconButton
                  title={t('common.deleteTemplate') || ''}
                  onClick={async () => {
                    await deleteTemplate();
                  }}
                >
                  <DeleteIcon />
                </IconButton>
              </>
            )}
          </Grid>
        </Grid>
      </div>

      {!isNewTemplate && questionTemplate.name && (
        <Grid container spacing={2}>
          <Grid
            item
            xs={6}
            style={{
              marginTop: '20px',
            }}
          >
            <TextField
              label={t('common.QuestionTemplateName')}
              variant="outlined"
              fullWidth
              name="industry"
              value={questionTemplate.name}
              onChange={(e) => setQuestionTemplateName(e)}
            />
          </Grid>

          <Grid
            item
            xs={6}
            style={{
              marginTop: '20px',
              display: 'flex',
              justifyContent: 'space-evenly',
              alignItems: 'flex-start',
              flexDirection: 'column',
            }}
          >
            <div>
              {t('common.groups')}:{' '}
              <span style={{ fontWeight: 600 }}>
                {Math.max(0, totalNumberOfGroups - 1)}
              </span>
            </div>

            <div>
              {t('common.questions')}:{' '}
              <span style={{ fontWeight: 600 }}>{totalNumberOfQuestions}</span>
            </div>
          </Grid>
        </Grid>
      )}

      {isNewTemplate && <NewQuestionTemplate />}

      <div key={`table-wrapper-${questionTemplate.template.length}`}>
        {!isNewTemplate &&
          questionTemplate &&
          questionTemplate.template.map((template: any, index: number) => {
            //  skip last group
            if (index === questionTemplate.template.length - 1) {
              return null;
            }

            return (
              <QuestionsTable
                template={template}
                key={index}
                ordinalNumber={index + 1}
                totalNumberOfGroups={questionTemplate.template.length}
              />
            );
          })}
      </div>

      {questionTemplate?.name && !isNewTemplate && (
        <Button
          variant="contained"
          startIcon={<AddIcon />}
          onClick={() => addQuestionGroup()}
          style={{ marginTop: '20px' }}
        >
          Group
        </Button>
      )}

      <SaveAsModal />
    </PageWrapper>
  );
};

export default observer(QuestionTemplates);

const PageWrapper = styled.div`
  width: 1130px;
`;

const StyledButton = styled(Button)`
  margin-right: 20px;
`;
