import { ChangeEvent, useContext } from 'react';
import styled from 'styled-components';
import { PagePrintContext } from '../context/PagePrintContext';
import { Checkbox, FormControlLabel } from '@mui/material';
import { t } from 'i18next';
interface PrintCheckboxProps {
  title: string;
}
const pagesArray: string[] = [];

export const PrintCheckbox = ({ title }: PrintCheckboxProps) => {
  const { setSkippedPages, numberOfSkippedPages, setNumberOfSkippedPages } =
    useContext(PagePrintContext);
  let numOfSkippedPages = numberOfSkippedPages;

  const handleCheckboxChange = (event: ChangeEvent<HTMLInputElement>) => {
    event.target.checked
      ? pagesArray.push(title)
      : pagesArray.splice(pagesArray.indexOf(title), 1);

    setSkippedPages((prevSkippedPages) => {
      if (event.target.checked) {
        return [...prevSkippedPages, title];
      } else {
        return prevSkippedPages.filter((page) => page !== title);
      }
    });

    setNumberOfSkippedPages(
      event.target.checked ? numOfSkippedPages + 1 : numOfSkippedPages - 1,
    );
  };
  return (
    <CheckboxWrapper className="no-print">
      <FormControlLabel
        control={<Checkbox color="success" onChange={handleCheckboxChange} />}
        label={t('reports.skipPage')}
      />
    </CheckboxWrapper>
  );
};

const CheckboxWrapper = styled.div`
  @media print {
    &.no-print {
      display: none;
    }
  }
`;
