import React, { useState, ChangeEvent } from 'react';
import styled from 'styled-components';
import { getTextSize, TextSizeType } from './Text';
import { t } from 'i18next';

interface TextAreaProps {
  backgroundColor?: boolean;
  fontSize?: TextSizeType;
}

export const TextArea = ({
  fontSize = 'm',
  backgroundColor = true,
}: TextAreaProps) => {
  const [value, setValue] = useState('');

  const handleChange = (event: ChangeEvent<HTMLTextAreaElement>) => {
    setValue(event.target.value);
    event.target.style.height = 'auto';
    event.target.style.height = event.target.scrollHeight + 'px';
  };

  return (
    <StyledTextarea
      className={!value ? 'no-print' : ''}
      value={value}
      onChange={handleChange}
      placeholder={t('reports.feedbackText') as string}
      fontSize={fontSize}
      backgroundColor={backgroundColor}
    />
  );
};

const StyledTextarea = styled.textarea<TextAreaProps>`
  width: 100%;
  height: auto;
  border: none;
  resize: none;
  font-size: ${({ fontSize }) => fontSize && getTextSize(fontSize)};
  background-color: #eeeeee;
  border-radius: 5px;
  padding: 5px 10px;
  box-sizing: border-box;

  :focus {
    outline: none;
  }
  @media print {
    &.no-print {
      display: none;
    }
  }
`;
