/**
 * Check if a string contains only numbers and letters (no spaces and special characters ).
 * @param {string} value - The string to be validated.
 * @returns {boolean} - True if the string is valid; otherwise, false.
 */
export const containsOnlyNumbersAndLetters = (value: string): boolean =>
  /^[a-zA-Z0-9]*$/.test(value);

/**
 * Check if a string contains only numbers, letters, and hyphens (no spaces and special characters ).
 * @param {string} value - The string to be validated.
 * @returns {boolean} - True if the string is valid; otherwise, false.
 */
export const containsOnlyNumbersLettersAndHyphens = (value: string): boolean =>
  /^[a-zA-Z0-9-]*$/.test(value);

export const getGroupAlphabet = (index: number) => {
  return String.fromCharCode(65 + index);
};
