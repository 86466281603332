import { t } from 'i18next';
import { useContext, useEffect } from 'react';
import styled from 'styled-components';
import { PagePrintContext } from '../../context/PagePrintContext';
import { PrintPage } from '../PrintPage/PrintPage';
import { Content } from './components/Content';
import { Header } from './components/Header';

interface IContentPageProps {
  rows: { name: string; pages: string }[];
}
export const ContentPage: React.FC<IContentPageProps> = ({ rows }) => {
  const { skippedPages, setNumberOfSkippedPages } =
    useContext(PagePrintContext);
  let numOfSkippedPages = 0;

  useEffect(() => {
    setNumberOfSkippedPages(numOfSkippedPages);
  }, [numOfSkippedPages, skippedPages, setNumberOfSkippedPages]);

  return (
    <PrintPage
      className={skippedPages.includes(t('reports.content')) ? 'no-print' : ''}
    >
      <Header title={t('reports.content')} />

      <Content>
        {rows.map(({ name, pages }, key) => {
          const isSkippable = skippedPages.includes(t(`reports.${name}`));
          isSkippable && numOfSkippedPages++;
          return (
            <Row key={key} className={isSkippable ? 'no-print hidden' : ''}>
              <Text>{t(`reports.${name}`)}</Text>
              <Text id="page">{pages}</Text>
              <Text id="print-only">{+pages - numOfSkippedPages}</Text>
            </Row>
          );
        })}
      </Content>
    </PrintPage>
  );
};

const Row = styled.div`
  background: #eeeeee;
  border-radius: 8px;
  width: 100%;
  height: 52px;
  padding: 0 20px;

  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 10px;
  @media print {
    &.no-print {
      display: none;
    }
  }
  &.hidden {
    display: none;
  }
`;

const Text = styled.div`
  color: #1c2328;
  font-size: 20px;
  font-weight: 600;
  &#print-only {
    display: none;
  }
  @media print {
    &#print-only {
      display: block;
    }
    &#page {
      display: none;
    }
  }
`;
