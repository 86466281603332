import DeleteIcon from '@mui/icons-material/Delete';
import LinkIcon from '@mui/icons-material/Link';
import PersonAddIcon from '@mui/icons-material/PersonAdd';
import { Button } from '@mui/material';
import { DataGrid, GridColDef } from '@mui/x-data-grid';
import axios from 'axios';
import { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import styled from 'styled-components';
import { useAuth } from '../../providers/auth';
import { InviteRespondents } from './InviteRespondents';
import { t } from 'i18next';

export const Respondents = () => {
  const { surveyId, periodId } = useParams();
  const [inviteRespondentsView, setInviteRespondentsView] = useState(false);
  const { user } = useAuth();

  const [allRespondents, setAllRespondents] = useState([]);

  useEffect(() => {
    const fetchAllRespondents = async () => {
      const response = await axios.post(`/listRespondentsPerSurvey`, {
        mode: 'list',
        object: 'userdata',
        code: surveyId,
        date1: '',
        date2: '',
        type: '',
        period: periodId,
      });

      setAllRespondents(
        response.data.result.map((user: any) => ({
          ...user,
          id: user.password,
        })),
      );
    };

    if (surveyId) {
      fetchAllRespondents();
    }
    //eslint-disable-next-line react-hooks/exhaustive-deps
  }, [surveyId]);

  const columns: GridColDef[] = [
    { field: 'username', headerName: t('common.userName') || '', flex: 1 },
    { field: 'password', headerName: t('common.password') || '', flex: 1 },
    {
      field: 'timeinvited',
      headerName: t('common.timeInvited') || 'Time invited',
      flex: 1,
    },
    {
      field: 'timeanswered',
      headerName: t('common.timeAnswered') || 'Time answered',
      flex: 1,
    },
    {
      field: 'actions',
      headerName: t('common.actions') || 'Actions',
      align: 'center',
      headerAlign: 'center',
      width: 100,
      renderCell: (params) => {
        return (
          <ActionsWrapper onClick={() => handleDeleteItem(params.row.password)}>
            <DeleteIcon />
          </ActionsWrapper>
        );
      },
    },
  ];

  const handleDeleteItem = async (password: string) => {
    const removeRespondent = async () => {
      await axios.delete(`/deleteRespondent`, {
        headers: {
          Authorization: `Bearer ${user?.token}`,
        },

        data: {
          mode: 'delete',
          object: 'userdata',
          id: `${password}_${surveyId}_${periodId}`,
        },
      });
    };

    await removeRespondent();
  };

  return (
    <>
      <ButtonsWrapper>
        <StyledButton
          variant="contained"
          startIcon={<PersonAddIcon />}
          onClick={() => {
            setInviteRespondentsView(true);
          }}
        >
          {t('common.inviteRespondents')}
        </StyledButton>

        {inviteRespondentsView && (
          <StyledButton
            variant="outlined"
            onClick={() => {
              setInviteRespondentsView(false);
            }}
          >
            {t('common.cancel')}
          </StyledButton>
        )}

        <StyledButton
          variant="outlined"
          startIcon={<LinkIcon />}
          onClick={() => {
            window.prompt(
              t('common.linkDescription') || '',
              `https://www.login.governanceatwork.io/?c=${surveyId}&l=en`,
            );
          }}
        >
          {t('common.getLink')}
        </StyledButton>
      </ButtonsWrapper>

      {inviteRespondentsView ? (
        <InviteRespondents />
      ) : (
        <AllRespondentsTableWrapper>
          {allRespondents.length !== 0 ? (
            <DataGrid
              rows={allRespondents}
              columns={columns}
              initialState={{
                pagination: {
                  paginationModel: { page: 0, pageSize: 10 },
                },
              }}
              pageSizeOptions={[5, 10, 15]}
            />
          ) : (
            t('common.noRespondents')
          )}
        </AllRespondentsTableWrapper>
      )}
    </>
  );
};

const StyledButton = styled(Button)`
  margin-right: 20px;
`;

const AllRespondentsTableWrapper = styled.div`
  margin-top: 20px;
`;

const ButtonsWrapper = styled.div`
  display: flex;
  align-items: center;
`;

const ActionsWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;

  color: #ccc;

  &:hover {
    color: black;
    cursor: pointer;
  }
`;
