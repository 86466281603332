import { Outlet } from 'react-router-dom';
import styled from 'styled-components';
import { NavigationMenu } from '../NavigationMenu/NavigationMenu';
import SelectCompanyDialog from '../SelectCompanyDialog/SelectCompanyDialog';

export const Layout: React.FC = () => {
  return (
    <LayoutWrapper>
      <NavigationMenu />
      <SelectCompanyDialog />

      {/* An <Outlet> renders whatever child route is currently active,
          so you can think about this <Outlet> as a placeholder for
          the child routes we defined above. */}
      <Outlet />
    </LayoutWrapper>
  );
};

const LayoutWrapper = styled.div`
  display: flex;
  justify-content: center;
  padding: 20px;
`;
