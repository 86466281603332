import styled from 'styled-components';

interface ISortedListProps {
  heading: string;
  listItems: string[];
}

export const SortedList: React.FC<ISortedListProps> = ({
  heading,
  listItems,
}) => {
  return (
    <div>
      <Heading>{heading}</Heading>

      <ListWrapper>
        {listItems.map((item, index) => (
          <li key={index}>{item}</li>
        ))}
      </ListWrapper>
    </div>
  );
};

const Heading = styled.div`
  color: #1f4b58;
  font-weight: 600;
  font-size: 20px;
  margin-bottom: 20px;
`;

const ListWrapper = styled.ol`
  padding-left: 16px;

  li {
    font-size: 18px;
    font-weight: 400;
    color: #1f4b58;
  }
`;
