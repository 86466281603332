import axios from 'axios';
import { useEffect, useState } from 'react';
import { Report } from '../../model';
import { ReportsApiResponse } from './model/reports-api-response';

export const useReports = (
  clientId: string | null,
  period: string | null,
): Report[] => {
  const [reports, setReports] = useState<Report[]>([]);

  const fetchData = async () => {
    if (clientId === null || period === null) {
      setReports([]);
    } else {
      try {
        await axios.post('/define_reports_2', {
          code: clientId,
          period: period,
        });

        const response = await axios.get<ReportsApiResponse>(
          `reportdata/getfiles/${clientId}/ `,
        );

        const reportsResponse = response.data.result.Reports;
        const mappedReports = Object.keys(reportsResponse).map((id) => ({
          id,
          displayName: reportsResponse[id],
        }));

        setReports(mappedReports);
      } catch (error) {
        console.error('Error fetching reports:', error);
      }
    }
  };

  useEffect(() => {
    fetchData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [clientId, period]);

  return reports;
};
