import { Box, Button, Grid, TextField } from '@mui/material';
import axios from 'axios';
import { useFormik } from 'formik';
import { useParams } from 'react-router-dom';
import { toast } from 'react-toastify';
import { getSelectedCompanyFromLocalStorage } from '../../utils/LocalStorage';
import { t } from 'i18next';

export const InviteMultiple = () => {
  const { surveyId, periodId } = useParams();
  const selectedCompany = getSelectedCompanyFromLocalStorage();

  const formik = useFormik({
    initialValues: {
      subject: '',
      body: `
      Dear board collegue,
      <br />
      As a member of the board at ${selectedCompany?.name}, you are invited to our annual Board Evaluation.
      <br />
      You can access the evaluation by following the link below. The evaluation takes approximately 10 minutes and needs to be completed by deadline.
      <br />
      Here is the link to the Board Evaluation: {url}
      <br />
      If you have any questions or notice any issues you are welcome to contact Jonathan Kalma: jonathan.kalma@kunskapspartner.se from Kunskapspartner, who is administrating the evaluation.
      <br />
      Thank you for your participation.
  `,
      smsbody: '',
      respondents: null,
    },
    onSubmit: (values: any) => {
      try {
        const fileReader = new FileReader();

        fileReader.onload = async (event) => {
          if (event && event.target) {
            const parsedCSVUsers = event.target.result;
            await axios.post('/createRespondent ', {
              mode: 'save',
              respondent_type: 'S',
              respondents_filetype: '',
              number: '',
              code: `${surveyId}_${periodId}`,
              language: 'en',
              object: 'respondents',
              id: '',
              companyId: selectedCompany?.companyId,
              invite: 'true',
              new: '',
              ...values,
              respondents: parsedCSVUsers,
            });

            toast.success(t('common.usersInvited'));
          }
        };

        fileReader.readAsText(values.respondents);
      } catch (error) {
        toast.error(t('common.somethingWentWrong'));
      }
    },
  });

  return (
    <form onSubmit={formik.handleSubmit}>
      <Grid container spacing={2}>
        <Grid item xs={12}>
          <TextField
            label={t('common.emailSubject')}
            variant="outlined"
            fullWidth
            required
            {...formik.getFieldProps('subject')}
          />
        </Grid>
        <Grid item xs={12}>
          <TextField
            label={t('common.emailBody')}
            multiline
            rows={7}
            variant="outlined"
            fullWidth
            required
            {...formik.getFieldProps('body')}
          />
        </Grid>{' '}
        <Grid item xs={6}>
          <Button
            onClick={() => {
              const filesElem = document.getElementById('files');
              if (filesElem) {
                filesElem.click();
              }
            }}
            variant="contained"
            color="primary"
          >
            {t('common.uploadCSVFile')}
          </Button>
          <TextField
            id="files"
            type="file"
            variant="outlined"
            style={{
              display: 'none',
            }}
            inputProps={{ accept: '.csv' }}
            helperText={formik.touched.respondents && formik.errors.respondents}
            onChange={(event) => {
              const file = (event.target as HTMLInputElement).files;

              if (file) {
                formik.setFieldValue('respondents', file[0]);
              }
            }}
          />
        </Grid>
        <Grid item xs={6}>
          <Box display="flex" justifyContent="flex-end">
            <Button type="submit" variant="contained" color="primary">
              {t('common.sendInvite')}
            </Button>
          </Box>
        </Grid>
      </Grid>
    </form>
  );
};
