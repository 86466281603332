import axios, { AxiosError } from 'axios';
import { useAuth } from '../../providers/auth';
import { removeCompaniesFromLocalStorage } from '../../utils/LocalStorage';

export const useAxiosConfig = (authToken: string) => {
  const { setUser } = useAuth();

  axios.defaults.baseURL = process.env.REACT_APP_API_URL!;
  axios.defaults.headers.common['Authorization'] = `Bearer ${authToken}`;

  axios.interceptors.response.use(
    (response) => response,
    (error: AxiosError) => {
      if (error.response?.status === 401) {
        setUser(null);
        removeCompaniesFromLocalStorage();
      }

      return Promise.reject(error);
    },
  );
};
