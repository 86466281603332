import { AppendixPage } from './AppendixPage';
import { useContext, useEffect, useMemo } from 'react';
import { ReportDataContext } from '../../context/ReportDataContext';
import {
  ActionTypes,
  AppendixDataContext,
  Total,
} from '../../context/AppendixContext';
import { ExtractDataType, extractData } from '../../utils/dataExtractFunctions';

const AppendixPages = () => {
  const {
    state: { compare, compare2 },
  } = useContext(ReportDataContext);
  const {
    state: { period },
    appendixDispatch,
  } = useContext(AppendixDataContext);

  const extractedCompareData = useMemo(
    () => extractData(compare?.length ? compare : compare2, period.displayName),
    [compare, compare2, period.displayName],
  );

  const isTotalIndex =
    extractedCompareData &&
    extractedCompareData[0]?.heading === 'Total index' &&
    !(JSON.stringify(extractedCompareData[0].total) === '{}');

  useEffect(() => {
    if (isTotalIndex) {
      appendixDispatch({
        type: ActionTypes.SET_TOTAL,
        payload: extractedCompareData[0].total as Total,
      });
    }
    if (extractedCompareData) {
      appendixDispatch({
        type: ActionTypes.SET_EXTRACTED_COMPARE_DATA,
        payload: extractedCompareData,
      });
    }
  }, [isTotalIndex]); // eslint-disable-line react-hooks/exhaustive-deps

  return (
    <>
      {extractedCompareData &&
        extractedCompareData.map((page: ExtractDataType, index: number) => {
          if (!page.subheadings.length) {
            return null;
          }

          return <AppendixPage key={index} tableData={page} pageKey={index} />;
        })}
    </>
  );
};

export default AppendixPages;
