import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  FormControl,
  InputLabel,
  MenuItem,
  Select,
} from '@mui/material';
import { useState } from 'react';
import styled from 'styled-components';
import { getCompaniesFromLocalStorage } from '../../providers/auth';
import {
  getSelectedCompanyFromLocalStorage,
  setSelectedCompanyToLocalStorage,
} from '../../utils/LocalStorage';
import { t } from 'i18next';

const SelectCompanyDialog = () => {
  const companies = getCompaniesFromLocalStorage();
  const [selectedCompany, setSelectedCompany] = useState('');
  const [isOpen, setIsOpen] = useState(!getSelectedCompanyFromLocalStorage());

  const onClose = () => {
    setIsOpen(false);
  };

  const handleCompanySelect = () => {
    const currentCompany = companies.find(
      (company) => company.companyId === selectedCompany,
    );

    if (currentCompany) {
      setSelectedCompanyToLocalStorage(currentCompany);
    }

    onClose();

    window.location.reload();
  };

  return (
    <Dialog open={isOpen} fullWidth maxWidth="xs">
      <DialogTitle>{t('common.selectCompany')}</DialogTitle>
      <Content>
        <FormControl fullWidth>
          <InputLabel>{t('common.company')}</InputLabel>
          <Select
            value={selectedCompany}
            onChange={(e) => setSelectedCompany(e.target.value)}
            label="Company"
          >
            {companies.map((company) => (
              <MenuItem key={company.companyId} value={company.companyId}>
                {company.name}
              </MenuItem>
            ))}
          </Select>
        </FormControl>
      </Content>
      <DialogActions>
        <Button
          onClick={handleCompanySelect}
          color="primary"
          disabled={!selectedCompany}
        >
          {t('common.select')}
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default SelectCompanyDialog;

const Content = styled(DialogContent)`
  padding-top: 6px !important;
`;
