import {
  Button,
  FormControl,
  InputLabel,
  MenuItem,
  Select,
} from '@mui/material';
import { t } from 'i18next';
import { useState } from 'react';
import styled from 'styled-components';
import { getCompaniesFromLocalStorage, useLogout } from '../../providers/auth';
import {
  getDefaultLanguage,
  getSelectedCompanyFromLocalStorage,
  setLanguage,
  setSelectedCompanyToLocalStorage,
} from '../../utils/LocalStorage';
import { Logo } from '../Logo/Logo';
import { Menu } from '../Menu/Menu';

export const NavigationMenu: React.FC = () => {
  const onLogout = useLogout();
  const [lang, setLang] = useState(getDefaultLanguage());
  const companies = getCompaniesFromLocalStorage();
  const [selectedCompany, setSelectedCompany] = useState(
    getSelectedCompanyFromLocalStorage(),
  );

  return (
    <NavigationMenuWrapper>
      <LogoBar>
        <Logo />
      </LogoBar>

      <Menu />

      <BottomMenuWrapper>
        <FormControl fullWidth>
          <InputLabel id="select-company">
            {t('common.selectCompany')}
          </InputLabel>
          <Select
            labelId="select-company"
            id="select-company-select"
            value={selectedCompany?.companyId || ''}
            label={t('common.selectCompany')}
            onChange={(e) => {
              const currentCompany = companies.find(
                (company) => company.companyId === e.target.value,
              );
              if (currentCompany) {
                setSelectedCompanyToLocalStorage(currentCompany);
                setSelectedCompany(currentCompany);

                window.location.pathname = '/';
              }
            }}
          >
            {companies.map((company) => (
              <MenuItem key={company.companyId} value={company.companyId}>
                {company.name}
              </MenuItem>
            ))}
          </Select>
        </FormControl>

        <FormControl
          fullWidth
          style={{
            marginTop: 20,
          }}
        >
          <InputLabel>{t('common.selectLanguage')}</InputLabel>
          <Select
            value={lang}
            label={t('common.selectLanguage')}
            onChange={(e) => {
              setLang(e.target.value);
              setLanguage(e.target.value);
            }}
          >
            <MenuItem value={'en'}>English</MenuItem>
            <MenuItem value={'sv'}>Swedish</MenuItem>
          </Select>
        </FormControl>

        <LogoutButton onClick={onLogout}>{t('navigation.logOut')}</LogoutButton>
      </BottomMenuWrapper>
    </NavigationMenuWrapper>
  );
};

const LogoutButton = styled(Button)`
  width: 100%;
  padding: 20px 10px;
  border-radius: 8px;
  color: #ffffff;
  // TODO: use theme variable
  background-color: #1f4b58;
  margin-top: 20px;
`;

const LogoBar = styled.div`
  background: #1f4b58;
  border-radius: 12px;
  height: 90px;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
`;

const NavigationMenuWrapper = styled.div`
  display: flex;
  flex-direction: column;

  position: sticky;
  top: 0;

  min-width: 300px;
  max-width: 320px;
  height: calc(100vh - 40px);

  background: #1c2328;
  color: white;

  border-radius: 12px;
  padding: 30px;
  margin-right: 20px;
`;

const BottomMenuWrapper = styled.div`
  margin-top: auto;

  > div * {
    color: white;
  }

  fieldset {
    border-color: white;
  }
`;
