import { t } from 'i18next';
import { PrintPage } from '../PrintPage/PrintPage';
import { Text } from '../Text';
import { BarChart } from './components/BarChart';
import { Header } from './components/Header';
import styled from 'styled-components';
import { TextArea } from '../TextArea';
import { useContext, useEffect } from 'react';
import { PagePrintContext } from '../../context/PagePrintContext';
import { ReportDataContext } from '../../context/ReportDataContext';
import { AppendixDataContext } from '../../context/AppendixContext';
import {
  ExtractDataType,
  extractData,
  secondPeriodExtract,
} from '../../utils/dataExtractFunctions';

export const HistoricalComparisonPage = () => {
  const { skippedPages, setSkippedPages } = useContext(PagePrintContext);
  const {
    state: { compare, compare2 },
  } = useContext(ReportDataContext);

  const {
    state: {
      period: { displayName },
    },
  } = useContext(AppendixDataContext);

  const inputData = compare?.length ? compare : compare2;
  const isComparionData = !!compare || !!compare2;
  const secondPeriod = secondPeriodExtract(inputData, displayName);
  const chosenPeriodData = extractData(inputData, displayName);
  const secondPeriodData = extractData(inputData, secondPeriod);
  const hasAnotherPeriod = isComparionData && secondPeriod;
  const periods = [displayName, secondPeriod];

  useEffect(() => {
    if (!hasAnotherPeriod) {
      setSkippedPages((prevSkippedPages) => [
        ...prevSkippedPages,
        t('reports.historicalComparison'),
      ]);
    } else {
      setSkippedPages(
        skippedPages.filter(
          (page) => page !== t('reports.historicalComparison'),
        ),
      );
    }
    //eslint-disable-next-line react-hooks/exhaustive-deps
  }, [hasAnotherPeriod]);

  return (
    <>
      {hasAnotherPeriod ? (
        <PrintPage
          className={
            skippedPages.includes(t('reports.historicalComparison'))
              ? 'no-print'
              : ''
          }
        >
          <Header title={t('reports.historicalComparison')} resultGroup={5} />
          <ContentWrapper>
            <TextWrapper>
              <Text
                size="l"
                value={t('reports.historicalComparisonPageDescription')}
              />
              <TextArea backgroundColor={false} fontSize="l" />
            </TextWrapper>
            <BarChart
              periods={periods}
              firstPeriodData={chosenPeriodData as ExtractDataType[]}
              secondPeriodData={secondPeriodData as ExtractDataType[]}
            />
          </ContentWrapper>
        </PrintPage>
      ) : null}
    </>
  );
};

const TextWrapper = styled.div`
  width: 100%;
`;

const ContentWrapper = styled.div`
  padding: 50px;
  display: flex;
  flex-direction: column;
`;
