import { createContext, ReactNode, Reducer, useMemo, useReducer } from 'react';
import { CompareData } from '../api/reports/model/compare-report-component';
import {
  OpportunityLandscapeData,
  ScatterChartData,
} from '../api/reports/model/opportunity-landscape-component';
import { Top5Data } from '../api/reports/model/top5-report-component';
import { Top5 } from '../model/top5';
import { CommentsData } from '../api/reports/model/comments-response';

export interface PeriodData {
  name: string;
  data: Array<{
    label: string;
    howWell: number;
    howImportant: number;
    opportunityIndex: number;
  }>;
}

export interface ReportDataState {
  currentPeriodId: string;
  title: string;
  subTitle: string;
  top5: Top5Data[];
  opportunityLandscape: OpportunityLandscapeData | null;
  compare: CompareData[];
  compare2: CompareData[];
  comments: CommentsData[];
}

function getTop5FromState(
  top5Data: Top5Data[],
  opportunityLandscape: OpportunityLandscapeData | null,
): Top5[] {
  if (!top5Data?.length || !opportunityLandscape) {
    return [];
  }

  return top5Data
    .slice(1, 6)
    .sort((first, second) => second.OpportunityIndex - first.OpportunityIndex)
    .map((top5Item) => {
      const compareItem = opportunityLandscape.dataSource.dataset.find((item) =>
        top5Item.OpportunityLandscape.replace(/'/g, '').includes(
          item.seriesname,
        ),
      );

      return {
        statement: top5Item.OpportunityLandscape,
        performance: compareItem?.data.y || '',
        importance: compareItem?.data.x || '',
      };
    });
}

function getScatterChartData(
  opportunityLandscape: OpportunityLandscapeData | null,
): ScatterChartData | null {
  if (!opportunityLandscape) {
    return null;
  } else {
    opportunityLandscape.dataSource.chart.drawAnchors = true;
    return {
      ...opportunityLandscape.dataSource,
      // @ts-ignore
      dataset: opportunityLandscape.dataSource.dataset.map((item) => ({
        ...item,
        data: [item.data],
      })),
    };
  }
}

export enum ReportDataActionType {
  SET_TOP_5 = 'SET_TOP_5',
  SET_OPPORTUNITY_LANDSCAPE = 'SET_OPPORTUNITY_LANDSCAPE',
  SET_COMPARE = 'SET_COMPARE',
  SET_COMPARE_2 = 'SET_COMPARE_2',
  SET_TITLE_AND_SUBTITLE = 'SET_TITLE_AND_SUBTITLE',
  SET_PERIOD_ID = 'SET_PERIOD_ID',
  SET_COMMENTS_DATA = 'SET_COMMENTS_DATA',
}

interface SetTop5Action {
  type: ReportDataActionType.SET_TOP_5;
  payload: Top5Data[];
}
interface SetOpportunityLandscapeAction {
  type: ReportDataActionType.SET_OPPORTUNITY_LANDSCAPE;
  payload: OpportunityLandscapeData | null;
}
interface SetCompareAction {
  type: ReportDataActionType.SET_COMPARE;
  payload: CompareData[];
}

interface SetCompare2Action {
  type: ReportDataActionType.SET_COMPARE_2;
  payload: CompareData[];
}
interface SetTitleAndSubTitleAction {
  type: ReportDataActionType.SET_TITLE_AND_SUBTITLE;
  payload: {
    title: string;
    subTitle: string;
  };
}
interface SetPeriodIdAction {
  type: ReportDataActionType.SET_PERIOD_ID;
  payload: string;
}

interface SetCommentsAction {
  type: ReportDataActionType.SET_COMMENTS_DATA;
  payload: CommentsData[];
}

export type ReportDataAction =
  | SetTop5Action
  | SetOpportunityLandscapeAction
  | SetCompareAction
  | SetCompare2Action
  | SetTitleAndSubTitleAction
  | SetPeriodIdAction
  | SetCommentsAction;

const initialState: ReportDataState = {
  currentPeriodId: '',
  title: '',
  subTitle: '',
  top5: [],
  opportunityLandscape: null,
  compare: [],
  compare2: [],
  comments: [],
};

const reducer: Reducer<ReportDataState, ReportDataAction> = (
  state: ReportDataState,
  action: ReportDataAction,
) => {
  switch (action.type) {
    case ReportDataActionType.SET_TOP_5:
      return { ...state, top5: action.payload };
    case ReportDataActionType.SET_OPPORTUNITY_LANDSCAPE:
      return { ...state, opportunityLandscape: action.payload };
    case ReportDataActionType.SET_COMPARE:
      return {
        ...state,
        compare: action.payload,
      };
    case ReportDataActionType.SET_COMPARE_2:
      return {
        ...state,
        compare2: action.payload,
      };
    case ReportDataActionType.SET_TITLE_AND_SUBTITLE:
      return {
        ...state,
        title: action.payload.title,
        subTitle: action.payload.subTitle,
      };
    case ReportDataActionType.SET_PERIOD_ID:
      return {
        ...state,
        currentPeriodId: action.payload,
      };
    case ReportDataActionType.SET_COMMENTS_DATA:
      return { ...state, comments: action.payload };
    default:
      throw new Error(`Unsupported action type: ${(action as any).type}`);
  }
};

export const ReportDataContext = createContext<{
  state: ReportDataState;
  dispatch: React.Dispatch<ReportDataAction>;
  selectors: {
    top5: Top5[];
    scatterChartData: ScatterChartData | null;
  };
}>({
  state: initialState,
  dispatch: () => null,
  selectors: {
    top5: [],
    scatterChartData: null,
  },
});

export const ReportDataProvider: React.FC<{ children?: ReactNode }> = ({
  children,
}) => {
  const [state, dispatch] = useReducer(reducer, initialState);
  const selectors = {
    top5: useMemo(
      () => getTop5FromState(state.top5, state.opportunityLandscape),
      [state.top5, state.opportunityLandscape],
    ),
    scatterChartData: useMemo(
      () => getScatterChartData(state.opportunityLandscape),
      [state.opportunityLandscape],
    ),
  };

  return (
    <ReportDataContext.Provider value={{ state, dispatch, selectors }}>
      {children}
    </ReportDataContext.Provider>
  );
};
