import { t } from 'i18next';
import { useContext, useMemo } from 'react';
import styled from 'styled-components';
import { AppendixDataContext } from '../../context/AppendixContext';
import { PagePrintContext } from '../../context/PagePrintContext';
import { PrintPage } from '../PrintPage/PrintPage';
import { Text } from '../Text';
import { Header } from './components/Header';
import { ScatterChart } from './components/ScatterChart';
import { TextArea } from '../TextArea';

export const OpportunityLandscapePage = () => {
  const { skippedPages } = useContext(PagePrintContext);
  const { state } = useContext(AppendixDataContext);

  const scatterChartDataset = useMemo(
    () =>
      (state.extractedCompareData as any)
        .slice(1)
        // @ts-ignore
        .map((item, index) => ({
          seriesName: item.heading,
          data: [
            {
              x: item.total.howImportant,
              y: item.total.howWell,
              anchorImageUrl: `/assets/scatterChart/${index + 1}.png`,
            },
          ],
        })),
    [state.extractedCompareData],
  );

  return (
    <PrintPage
      className={
        skippedPages.includes(t('reports.opportunityLandscape'))
          ? 'no-print'
          : ''
      }
    >
      <Header title={t('reports.opportunityLandscape')} resultGroup={2} />

      <ContentWrapper>
        <LeftContent>
          <div>
            <Text
              size="l"
              value={t('reports.opportunityLandscapePageDescription')}
            />
            <Text
              size="l"
              value={t('reports.opportunityLandscapeLegendDescription')}
            />

            <TextArea />
          </div>
        </LeftContent>
        {scatterChartDataset && <ScatterChart dataset={scatterChartDataset} />}
      </ContentWrapper>
    </PrintPage>
  );
};

const ContentWrapper = styled.div`
  padding: 50px;
  display: flex;
`;

const LeftContent = styled.div`
  width: 400px;
`;
