import { createContext, PropsWithChildren, useContext, useState } from 'react';
import { User } from './model/user.interface';
import { ICompany } from './useLogin';

interface AuthContextType {
  user: User | null;
  setUser: (user: User | null) => void;
  loading: boolean;
  setLoading: (isLoading: boolean) => void;
  companies: ICompany[];
  setCompanies: (companies: ICompany[]) => void;
}

export const AuthContext = createContext<AuthContextType>({
  user: null,
  setUser: () => {},
  loading: false,
  companies: [],
  setCompanies: () => {},
  setLoading: () => {},
});

const getUserFromLocalStorage = () => {
  const userFromLocalStorage = localStorage.getItem('user');
  // TODO: check if token is expired
  return userFromLocalStorage ? JSON.parse(userFromLocalStorage) : null;
};

const setUserInLocalStorage = (user: User | null) => {
  if (user) {
    localStorage.setItem('user', JSON.stringify(user));
  } else {
    localStorage.removeItem('user');
  }
};

const setCompaniesInLocalStorage = (companies: ICompany[]) => {
  if (companies.length) {
    localStorage.setItem('companies', JSON.stringify(companies));
  } else {
    localStorage.removeItem('companies');
  }
};

export const getCompaniesFromLocalStorage = (): ICompany[] => {
  return JSON.parse(localStorage.getItem('companies') || '');
};

export const AuthProvider: React.FC = ({ children }: PropsWithChildren<{}>) => {
  const [user, setUser] = useState<User | null>(getUserFromLocalStorage());
  const [companies, setCompanies] = useState<ICompany[]>([]);

  const [loading, setLoading] = useState(false);

  return (
    <AuthContext.Provider
      value={{
        user,
        setUser: (user) => {
          setUserInLocalStorage(user);
          setUser(user);
        },
        loading,
        setLoading,
        companies,
        setCompanies: (companies) => {
          setCompaniesInLocalStorage(companies);
          setCompanies(companies);
        },
      }}
    >
      {children}
    </AuthContext.Provider>
  );
};

export const useAuth = () => useContext(AuthContext);
