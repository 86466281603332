import { createContext } from 'react';
import { CompanyStore } from './CompanyStore';
import { QuestionTemplateStore } from './QuestionTemplateStore';

const companyStore = new CompanyStore();
const questionTemplateStore = new QuestionTemplateStore();

export const storesContext = createContext({
  companyStore,
  questionTemplateStore,
});
