import AddIcon from '@mui/icons-material/Add';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import DeleteIcon from '@mui/icons-material/Delete';
import EditIcon from '@mui/icons-material/Edit';
import LanguageIcon from '@mui/icons-material/Language';
import DeleteOutlineIcon from '@mui/icons-material/DeleteOutline';

import {
  Button,
  Grid,
  IconButton,
  InputLabel,
  Table,
  TableBody,
  TableCell,
  TableRow,
} from '@mui/material';
import axios from 'axios';
import { useEffect, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import styled from 'styled-components';
import { EditPeriodForm } from './EditPeriodForm';
import { useAuth } from '../../providers/auth';
import { getCountryName, RenderFlag } from '../RenderFlag';
import SelectLanguageDialog from '../SelectLanguageDialog';
import { t } from 'i18next';

interface IQueryLanguage {
  code: string;
  language: string;
  mainintroduction: string;
  qualifyingintroduction: string;
  qualifyingquestions: string;
  questionintroduction: string;
  questions: string;
  resultintroduction: string;
  thanks: string;
  information: string;
  emailsubject: string;
  emailbody: string;
  smsbody: string;
  periodid: string;
  contactemailsubject: string;
  contactemailbody: string;
  remindemailsubject: string;
  remindemailbody: string;
  remindsmsbody: string;
  companyId: string;
  created: string;
  modified: string;
  createdBy: string;
  modifiedBy: string;
}

interface IPeriod {
  code: string | null;
  periodid: string | null;
  date1: string | null;
  date2: string | null;
  othercompanycode: string | null;
  reminderdates: string | null;
  companyId: string | null;
  created: string | null;
  modified: string | null;
  createdBy: string | null;
  modifiedBy: string | null;
  queryLanguages: IQueryLanguage[];
}

export const ManageSurveyPeriods = () => {
  const { surveyId } = useParams();
  const [createNewPeriod, setCreateNewPeriod] = useState(false);
  const navigate = useNavigate();
  const [editPeriodId, setEditPeriodId] = useState('');
  const { user } = useAuth();
  const [surveyData, setSurveyData] = useState<any>({});

  const [isLanguagePickerOpen, setIsLanguagePickerOpen] = useState<any>(false);
  const [usedLanguagesInPeriod, setUsedLanguagesInPeriod] = useState<any>([]);
  const [selectedPeriod, setSelectedPeriod] = useState('');

  const fetchSurveyData = async () => {
    const response = await axios.post(`/getSurvey`, {
      mode: 'open',
      object: 'query',
      id: surveyId,
    });
    setSurveyData(response.data.result);
  };

  useEffect(() => {
    if (surveyId) {
      fetchSurveyData();
    }
    //eslint-disable-next-line react-hooks/exhaustive-deps
  }, [surveyId]);

  const handleClosePeriod = () => {
    setCreateNewPeriod(false);
    setEditPeriodId('');
    fetchSurveyData();
  };

  return (
    <PageWrapper>
      <PeriodButton
        variant="outlined"
        startIcon={<ArrowBackIcon />}
        onClick={() => {
          if (editPeriodId) {
            handleClosePeriod();
          } else {
            navigate(`/manageSurvey/${surveyId}`);
          }
        }}
      >
        {t('common.back')}
      </PeriodButton>
      {!createNewPeriod && (
        <PeriodButton
          variant="contained"
          startIcon={<AddIcon />}
          onClick={() => {
            setCreateNewPeriod(true);
          }}
        >
          Add period
        </PeriodButton>
      )}

      {createNewPeriod && (
        <PeriodButton
          variant="outlined"
          onClick={() => {
            setCreateNewPeriod(false);
          }}
        >
          Cancel
        </PeriodButton>
      )}

      {createNewPeriod && (
        <EditPeriodForm handleClosePeriod={handleClosePeriod} />
      )}

      {!createNewPeriod && !!editPeriodId && (
        <EditPeriodForm
          initialData={surveyData.periods.find(
            (period: IPeriod) => period.periodid === editPeriodId,
          )}
          handleClosePeriod={handleClosePeriod}
        />
      )}

      {!createNewPeriod && !editPeriodId && (
        <Grid
          container
          spacing={2}
          style={{
            marginTop: 20,
          }}
        >
          <Grid item xs={12}>
            <InputLabel
              style={{
                fontWeight: 600,
              }}
            >
              {t('common.periods')}:
            </InputLabel>
          </Grid>
          <Grid item xs={12}>
            <Table>
              <TableBody>
                {Array.isArray(surveyData?.periods) &&
                  surveyData?.periods?.map((period: IPeriod, index: number) => {
                    const usedLanguages = period.queryLanguages?.map(
                      (lang) => lang.language,
                    );

                    return (
                      <>
                        <TableRow key={period.periodid}>
                          <TableCell
                            style={{
                              textTransform: 'capitalize',
                              fontWeight: 600,
                              fontSize: 18,
                            }}
                          >
                            {index + 1}. {period.periodid}
                          </TableCell>

                          <TableCell
                            style={{
                              display: 'flex',
                              justifyContent: 'flex-end',
                            }}
                          >
                            <IconButton
                              title={t('common.addNewLanguage') || ''}
                              aria-label="Add"
                              onClick={() => {
                                if (usedLanguages && usedLanguages.length) {
                                  setUsedLanguagesInPeriod(() => [
                                    ...usedLanguages,
                                  ]);
                                }

                                setSelectedPeriod(period.periodid || '');
                                setIsLanguagePickerOpen(true);
                              }}
                            >
                              <LanguageIcon />
                            </IconButton>

                            <IconButton
                              title={t('common.editPeriod') || ''}
                              aria-label="Edit"
                              onClick={() => {
                                setEditPeriodId(period.periodid || '');
                              }}
                            >
                              <EditIcon />
                            </IconButton>

                            <IconButton
                              title={t('common.deletePeriod') || ''}
                              aria-label="Delete"
                              onClick={async () => {
                                if (
                                  window.confirm(
                                    t('common.areYouSureDeletePeriod') ||
                                      'Are you sure you want to delete period?',
                                  )
                                ) {
                                  await axios.delete(`/deletePeriod`, {
                                    headers: {
                                      Authorization: `Bearer ${user?.token}`,
                                    },
                                    data: {
                                      mode: 'delete',
                                      object: 'query',
                                      id: `s_${surveyId}_${period.periodid}`,
                                    },
                                  });

                                  window.location.reload();
                                }
                              }}
                            >
                              <DeleteIcon />
                            </IconButton>
                          </TableCell>
                        </TableRow>

                        {period.queryLanguages?.map((lang) => {
                          return (
                            <TableRow key={`${lang.periodid}_${lang.language}`}>
                              <TableCell
                                style={{
                                  paddingLeft: 40,
                                }}
                              >
                                <div
                                  style={{
                                    display: 'flex',
                                    justifyContent: 'flex-start',
                                    alignItems: 'center',
                                  }}
                                >
                                  <RenderFlag flag={lang.language} />
                                  {getCountryName(lang.language)}{' '}
                                  {t('common.survey')}
                                </div>
                              </TableCell>

                              <TableCell
                                style={{
                                  display: 'flex',
                                  justifyContent: 'flex-end',
                                }}
                              >
                                <IconButton
                                  title={t('common.editLanguage') || ''}
                                  aria-label="Edit"
                                  onClick={() => {
                                    navigate(
                                      `/manageSurvey/${surveyId}/managePeriods/${period.periodid}/language/${lang.language}`,
                                    );
                                  }}
                                >
                                  <EditIcon />
                                </IconButton>

                                <IconButton
                                  title={t('common.deleteLanguage') || ''}
                                  aria-label="Delete"
                                  onClick={async () => {
                                    if (
                                      window.confirm(
                                        t('common.areYouSureDeleteLanguage') ||
                                          'Are you sure you want to delete language?',
                                      )
                                    ) {
                                      await axios.delete(
                                        `/deleteQueryLanguage`,
                                        {
                                          headers: {
                                            Authorization: `Bearer ${user?.token}`,
                                          },
                                          data: {
                                            mode: 'delete',
                                            object: 'query',
                                            id: `l_${surveyId}_${period.periodid}_${lang.language}`,
                                          },
                                        },
                                      );
                                      window.location.reload();
                                    }
                                  }}
                                >
                                  <DeleteOutlineIcon />
                                </IconButton>
                              </TableCell>
                            </TableRow>
                          );
                        })}
                      </>
                    );
                  })}
              </TableBody>
            </Table>
          </Grid>
        </Grid>
      )}

      {isLanguagePickerOpen && (
        <SelectLanguageDialog
          reservedLanguages={usedLanguagesInPeriod}
          isDialogOpen={isLanguagePickerOpen}
          setIsDialogOpen={setIsLanguagePickerOpen}
          selectedPeriod={selectedPeriod}
        />
      )}
    </PageWrapper>
  );
};

const PageWrapper = styled.div`
  width: 1130px;
`;

const PeriodButton = styled(Button)`
  margin-right: 20px;
`;
