import {
  Box,
  Button,
  Checkbox,
  FormControl,
  FormControlLabel,
  FormLabel,
  Grid,
  Radio,
  RadioGroup,
  TextField,
} from '@mui/material';
import axios from 'axios';
import { useFormik } from 'formik';
import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { toast } from 'react-toastify';
import { getSelectedCompanyFromLocalStorage } from '../../utils/LocalStorage';
import {
  containsOnlyNumbersAndLetters,
  containsOnlyNumbersLettersAndHyphens,
} from '../../utils/helpers';

interface InitialValues {
  copycode: string | null;
  code: string | null;
  ongoing: string | null;
  title: string | null;
  header: string | null;
  showcompareanswer: boolean;
  compareanswer: string | null;
  totalanswers: string | null;
  domains: string | null;
  product: string | null;
}

interface IEditSurvey {
  initialData?: {
    language?: string | null;
    product?: string | null;
    code?: string | null;
    title?: string | null;
    enddate?: string | null;
    adminpw?: string | null;
    compareanswer?: string | null;
    totalanswers: string | null;
    showcompareanswer?: string | null;
    header?: string | null;
    domains?: string | null;
    ongoing?: string | null;
    companyId?: string | null;
    created?: string | null;
    modified?: string | null;
    createdBy?: string | null;
    modifiedBy?: string | null;
    code_org?: string | null;
    periods?: any[];
  };
  callback?: (surveyId: string) => void;
}

export const EditSurvey = ({ initialData, callback }: IEditSurvey) => {
  const selectedCompany = getSelectedCompanyFromLocalStorage();
  const { t } = useTranslation();

  const [initialValues, setInitialValues] = useState({
    copycode: '',
    code: '',
    ongoing: 'M',
    title: '',
    header: '',
    showcompareanswer: false,
    compareanswer: '',
    totalanswers: '',
    domains: '',
    product: '',
  });

  const validate = (values: InitialValues) => {
    const errors: Partial<InitialValues> = {};
    if (values.code && !containsOnlyNumbersAndLetters(values.code)) {
      errors.code = t('validation.onlyNumbersAndLettersAllowed');
    }
    if (values.title && !containsOnlyNumbersLettersAndHyphens(values.title)) {
      errors.title = t('validation.onlyNumbersLettersAndHyphensAllowed');
    }
    return errors;
  };

  const formik = useFormik({
    initialValues: initialValues,
    onSubmit: async (values: any) => {
      try {
        if (initialData) {
          await axios.post('/createSurvey', {
            mode: 'save',
            new: '',
            object: 'query',
            companyId: selectedCompany?.companyId,
            id: '',
            ...values,
            code_org: values.code,
            copyCode: '',
          });
        } else {
          const response = await axios.post('/createSurvey', {
            mode: 'save',
            new: '1',
            object: 'query',
            companyId: selectedCompany?.companyId,
            id: '',
            code_org: '',
            ...values,
          });

          if (callback) {
            callback(response.data.result.id);
          }
        }

        toast.success(
          initialData
            ? t('common.surveyUpdatedSuccessfully')
            : t('common.surveyCreatedSuccessfully'),
        );
      } catch (error) {
        toast.error(t('common.somethingWentWrong'));
      }
    },
    enableReinitialize: true,
    validate,
    validateOnChange: true,
  });

  useEffect(() => {
    if (initialData) {
      setInitialValues({
        copycode: '',
        code: initialData.code || '',
        ongoing: initialData.ongoing || '',
        title: initialData.title || '',
        header: initialData.header || '',
        showcompareanswer: !!Number(initialData.showcompareanswer),
        compareanswer: initialData.compareanswer || '',
        totalanswers: initialData.totalanswers || '',
        domains: initialData.domains || '',
        product: initialData.product || '',
      });
    }
  }, [initialData]);

  return (
    <form onSubmit={formik.handleSubmit}>
      <Grid
        container
        spacing={2}
        style={{
          marginTop: 20,
        }}
      >
        <Grid item xs={12}>
          <TextField
            label={t('common.uniqueSurveyId')}
            variant="outlined"
            fullWidth
            {...formik.getFieldProps('code')}
            error={!!formik.errors.code}
          />
        </Grid>
        <Grid item xs={12}>
          <FormControl component="fieldset">
            <FormLabel component="legend">{t('common.status')}</FormLabel>
            <RadioGroup row {...formik.getFieldProps('ongoing')}>
              <FormControlLabel
                value="M"
                control={<Radio />}
                label={t('common.sendOnlyEmail')}
              />
              <FormControlLabel
                value=""
                control={<Radio />}
                label={t('common.inactive')}
              />
            </RadioGroup>
          </FormControl>
        </Grid>

        <Grid item xs={12}>
          <TextField
            label={t('common.surveyTitle')}
            variant="outlined"
            fullWidth
            {...formik.getFieldProps('title')}
            error={!!formik.errors.title}
          />
        </Grid>
        <Grid item xs={12}>
          <TextField
            label={t('common.surveyHeader')}
            variant="outlined"
            fullWidth
            {...formik.getFieldProps('header')}
          />
        </Grid>
        <Grid item xs={12}>
          <FormControlLabel
            control={
              <Checkbox
                name="showcompareanswer"
                color="primary"
                checked={formik.values.showcompareanswer}
                onChange={formik.handleChange}
              />
            }
            label={t('common.showCompareAnswer')}
          />
        </Grid>
        <Grid item xs={6}>
          <TextField
            label={t('common.minAnswers')}
            variant="outlined"
            fullWidth
            {...formik.getFieldProps('compareanswer')}
          />
        </Grid>

        <Grid item xs={12}>
          <Box display="flex" justifyContent="flex-end">
            <Button type="submit" variant="contained" color="primary">
              {t('common.save')}
            </Button>
          </Box>
        </Grid>
      </Grid>
    </form>
  );
};
