import FusionCharts from 'fusioncharts';
import { Link, Route, BrowserRouter as Router, Routes } from 'react-router-dom';
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import './App.css';
import { AuthenticatedLayoutRoute } from './components/AuthenticatedRoute/AuthenticatedLayoutRoute';
import { AddLanguageSection } from './components/Survey/AddLanguageSection';
import { ManageSurveyPeriods } from './components/Survey/ManageSurveyPeriods';
import { GenerateReport } from './pages/GenerateReport';
import { Login } from './pages/Login';
import ManageCompanies from './pages/ManageCompanies';
import { ManageSurvey } from './pages/ManageSurvey';
import QuestionTemplates from './pages/QuestionTemplates';
import { AuthProvider } from './providers/auth';

(FusionCharts.options as any).license({
  key: 'ThB4d1E-13sA1C4A1H3C8A7D6B4B2D2H5A2D3pckC5E3lji1I-7kC8A3bvgF2A2H2C10D7D5A4B2B3D3E2B2A4C8B4E6vikI4F2C8kB5D6D3C-8B-8tG2C9A4rveE2F2D1vnjD1B5D1A2A2A15A20B7B9B6C6megD3J2VA7isbE3G1H2sbA4ED3D2B-9xiF1C10B8C4A3C3E3H4I2J2C10D3C1B5q==',
  creditLabel: false,
});

export const App: React.FC = () => (
  <AuthProvider>
    <Router>
      <Routes>
        <Route path="login" element={<Login />} />

        <Route path="/" element={<AuthenticatedLayoutRoute />}>
          <Route index element={<GenerateReport />} />
          <Route path="/templates" element={<QuestionTemplates />} />
          <Route path="/manageSurvey" element={<ManageSurvey />} />
          <Route path="/manageSurvey/:surveyId" element={<ManageSurvey />} />
          <Route
            path="/manageSurvey/:surveyId/managePeriods"
            element={<ManageSurveyPeriods />}
          />
          <Route
            path="/manageSurvey/:surveyId/managePeriods/:periodId/language/:language"
            element={<AddLanguageSection />}
          />
          <Route path="/manageCompanies" element={<ManageCompanies />} />

          {/* Using path="*"" means "match anything", so this route
                acts like a catch-all for URLs that we don't have explicit
                routes for. */}
          <Route path="*" element={<NoMatch />} />
        </Route>
      </Routes>
    </Router>

    <ToastContainer
      autoClose={2000}
      position="top-right"
      draggable={false}
      closeOnClick={false}
      limit={3}
    />
  </AuthProvider>
);

const NoMatch: React.FC = () => {
  return (
    <div>
      <h2>Nothing to see here!</h2>
      <p>
        <Link to="/">Go to the home page</Link>
      </p>
    </div>
  );
};
