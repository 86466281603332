import { t } from 'i18next';
import { makeAutoObservable, runInAction } from 'mobx';
import { toast } from 'react-toastify';
import {
  addTemplatesToCompany,
  createNewTemplate,
  deleteTemplate,
  editQuestionTemplate,
  getAssignedQuestionTemplates,
  getTemplateById,
  getTemplates,
  getTemplatesByCompany,
} from '../api/QuestionTemplatesAPI';
import { getGroupAlphabet } from '../utils/helpers';

export interface IQuestion {
  type: string;
  name: string;
  text: string;
}

export interface QuestionGroup {
  group: string;
  questions: IQuestion[];
}

export interface ITemplate {
  templatesId: string;
  name: string;
  type: string;
  template: QuestionGroup[];
  created: string;
  modified: string;
  createdBy: string;
  modifiedBy: string;
  // TODO change it in API response
  templateId?: string;
}

export interface INewQuestionTemplate {
  name: string;
  type: string;
  template: QuestionGroup[];
  companyId?: string;
}

const EMPTY_QUESTION_GROUP: QuestionGroup = {
  group: 'Group name',
  questions: [
    {
      type: 'T',
      name: '',
      text: 'Group description',
    },
  ],
};

export class QuestionTemplateStore {
  public questionTemplate: ITemplate | INewQuestionTemplate = {
    templatesId: '',
    name: '',
    type: '',
    template: [],
    created: '',
    modified: '',
    createdBy: '',
    modifiedBy: '',
  };
  public isNewTemplate: boolean = false;
  public currentTemplateId: string = '';
  public templates: ITemplate[] = [];
  public totalNumberOfGroups: number = 0;
  public totalNumberOfQuestions: number = 0;
  public assignedQuestionTemplates: ITemplate[] = [];
  public allQuestionTemplates: ITemplate[] = [];
  public isSaveAsDialogOpen: boolean = false;

  constructor() {
    makeAutoObservable(this);
  }

  public getTemplates = async () => {
    const allQuestionTemplates = await getTemplates();
    const templates = await getTemplatesByCompany();

    runInAction(() => {
      this.allQuestionTemplates = allQuestionTemplates;
      this.templates = templates;
    });
  };

  public setQuestionTemplate = (template: ITemplate) => {
    this.questionTemplate = template;
  };

  public getQuestionsAndGroupsCount = () => {
    // Get the total number of groups
    this.totalNumberOfGroups = this.questionTemplate.template.length;

    // Get the total number of questions
    this.totalNumberOfQuestions = this.questionTemplate.template.reduce(
      (acc, group) => {
        if (group.group === '') return acc;

        return acc + Math.min(group.questions.length - 1);
      },
      0,
    );
  };

  public clearQuestionTemplate = () => {
    this.questionTemplate = {
      templatesId: '',
      name: '',
      type: '',
      template: [],
      created: '',
      modified: '',
      createdBy: '',
      modifiedBy: '',
    };
  };

  public setIsNewTemplate = (value: boolean) => {
    // Set the isNewTemplate to true or false
    this.isNewTemplate = value;
  };

  public addNewTemplate = (template: INewQuestionTemplate) => {
    this.questionTemplate = template;
  };

  public setCurrentTemplateId = (value: string) => {
    this.currentTemplateId = value;
  };

  public saveNewTemplate = async (
    templateName: string,
    isMasterTemplate: boolean,
  ) => {
    try {
      const template: INewQuestionTemplate = {
        name: templateName,
        type: isMasterTemplate ? 'master' : 'company',
        template: [],
      };

      await createNewTemplate(template);

      this.clearQuestionTemplate();
      await this.getTemplates();

      toast.success(t('common.questionTemplateCreatedSuccessfully'));
    } catch (error) {
      console.error(error);
    }
  };

  public saveAsNewTemplate = async (
    templateName: string,
    isMasterTemplate: boolean,
    template: QuestionGroup[],
  ) => {
    try {
      const newTemplate: INewQuestionTemplate = {
        name: templateName,
        type: isMasterTemplate ? 'master' : 'company',
        template,
      };

      await createNewTemplate(newTemplate);

      this.clearQuestionTemplate();
      await this.getTemplates();

      toast.success(t('common.questionTemplateCreatedSuccessfully'));
    } catch (error) {
      toast.error(t('common.templateAlreadyExists'));
      console.error(error);
    }
  };

  public addQuestionGroup = async () => {
    // check limit of group per template, max 15 questions
    if (this.questionTemplate.template.length >= 16) {
      toast.error(t('common.maxGroupsPerTemplateReached'));
      return;
    }

    if (this.questionTemplate.template.length > 1) {
      this.questionTemplate.template.pop();
    }

    this.questionTemplate.template.push(EMPTY_QUESTION_GROUP);
    this.questionTemplate.template.push(this.generateGroupMapping());

    this.getQuestionsAndGroupsCount();
  };

  public updateQuestionTemplate = async () => {
    // TODO cover edge case where we delete all groups
    if (this.questionTemplate.template.length > 1) {
      this.questionTemplate.template.pop();
      this.questionTemplate.template.push(this.generateGroupMapping());
    }

    await editQuestionTemplate(this.questionTemplate as ITemplate);

    toast.success(t('common.questionTemplateUpdatedSuccessfully'));

    this.getSelectedTemplate();
  };

  public getSelectedTemplate = async () => {
    // clear out previous template
    this.setQuestionTemplate({
      templatesId: '',
      name: '',
      type: '',
      template: [],
      created: '',
      modified: '',
      createdBy: '',
      modifiedBy: '',
    });

    const updateTemplate = await getTemplateById(this.currentTemplateId);

    this.setQuestionTemplate(updateTemplate);

    this.getQuestionsAndGroupsCount();
  };

  public deleteQuestionGroup = async (groupIndex: number) => {
    this.questionTemplate.template.splice(groupIndex, 1);

    // TODO cover edge case where we delete all groups
    if (this.questionTemplate.template.length > 1) {
      this.questionTemplate.template.pop();
      this.questionTemplate.template.push(this.generateGroupMapping());
    }

    this.getQuestionsAndGroupsCount();
  };

  public addQuestionToGroup = async (groupIndex: number) => {
    // check limit of questions per group, max 10 questions
    if (this.questionTemplate.template[groupIndex].questions.length >= 11) {
      toast.error(t('common.maxQuestionsPerGroupReached'));
      return;
    }

    this.questionTemplate.template[groupIndex].questions.push({
      type: 'F55',
      name: `${getGroupAlphabet(groupIndex)}${
        this.questionTemplate.template[groupIndex].questions.length
      }`,
      text: 'New question text',
    });

    // TODO cover edge case where we delete all groups
    if (this.questionTemplate.template.length > 1) {
      this.questionTemplate.template.pop();
      this.questionTemplate.template.push(this.generateGroupMapping());
    }

    this.getQuestionsAndGroupsCount();
  };

  public deleteQuestionFromGroup = async (
    groupIndex: number,
    questionIndex: number,
  ) => {
    this.questionTemplate.template[groupIndex].questions.splice(
      questionIndex,
      1,
    );

    this.reflowQuestionNames(groupIndex);

    // TODO cover edge case where we delete all groups
    if (this.questionTemplate.template.length > 1) {
      this.questionTemplate.template.pop();
      this.questionTemplate.template.push(this.generateGroupMapping());
    }

    this.getQuestionsAndGroupsCount();
  };

  public editQuestionInGroup = (
    groupIndex: number,
    questionIndex: number,
    text: string,
  ) => {
    this.questionTemplate.template[groupIndex].questions[questionIndex].text =
      text;
  };

  public editQuestionGroupHeader = (groupIndex: number, text: string) => {
    this.questionTemplate.template[groupIndex].group = text;
  };

  // Reflow the question names after deleting a question but skip question type T
  public reflowQuestionNames = (groupIndex: number) => {
    this.questionTemplate.template[groupIndex].questions.forEach(
      (question, index) => {
        if (question.type !== 'T') {
          question.name = `${getGroupAlphabet(groupIndex)}${index}`;
        }
      },
    );
  };

  public generateGroupMapping = (): QuestionGroup => {
    // Filter groups based on type 'F55' and create mapping
    const groupMapping: IQuestion[] = this.questionTemplate.template
      .map((group) => {
        // Extract only the questions with type 'F55', since these are the only once included in the diagrams.
        const f55Questions = group.questions.filter((q) => q.type === 'F55');

        // Only return a mapping if there are 'F55' questions in the group
        if (f55Questions.length > 0) {
          return {
            type: 'P',
            name: '',
            text: `${group.group.trim()} = ${extractNames(f55Questions)}`,
          };
        }

        return null; // If no 'F55' questions, return null to filter out later
      })
      .filter((mapping): mapping is IQuestion => mapping !== null); // Filter out any null values and ensure correct typing

    return {
      group: '',
      questions: groupMapping,
    };
  };

  public deleteTemplate = async () => {
    if (
      window.confirm(
        t('reports.areYouSureDeleteTemplate') ||
          'Are you sure you want to delete question template?',
      )
    ) {
      await deleteTemplate(this.currentTemplateId);

      toast.success(t('common.questionTemplateDeletedSuccessfully'));

      this.clearQuestionTemplate();
      await this.getTemplates();
      this.setCurrentTemplateId('');
    }
  };

  public setQuestionTemplateName = (event: any) => {
    this.questionTemplate.name = event.target.value;
  };

  public setAssignedQuestionTemplates = async (
    companyId: string,
    templates: string[],
  ) => {
    try {
      await addTemplatesToCompany(companyId, templates);

      toast.success(t('company.questionTemplatesUpdatedSuccessfully'));
    } catch (error) {
      toast.error(t('company.questionTemplatesUpdatedError'));
    }
  };

  public getAssignedQuestionTemplates = async (
    companyId: string,
    templates: ITemplate[],
  ) => {
    try {
      const result = await getAssignedQuestionTemplates(companyId);

      const templateIds: string[] = result.map(
        (template) => template.templateId || '',
      );

      const response = templateIds
        .map((templateId) => {
          const filteredTemplate = templates.find(
            (t) => t.templatesId === templateId,
          );

          if (!filteredTemplate) {
            return undefined;
          }

          return filteredTemplate;
        })
        .filter((template) => template !== undefined) as ITemplate[];

      runInAction(() => {
        this.assignedQuestionTemplates = response;
      });
    } catch (error) {
      console.error(error);
    }
  };

  public clearAssignedTemplates = () => {
    this.assignedQuestionTemplates = [];
  };

  public setIsSaveAsDialogOpen = (value: boolean) => {
    runInAction(() => {
      this.isSaveAsDialogOpen = value;
    });
  };
}

const extractNames = (questions: IQuestion[]): string => {
  const names: string[] = [];
  for (const question of questions) {
    if (question.name !== '') {
      names.push(question.name);
    }
  }

  if (names.length === 0) {
    return '';
  }

  return names.join(',');
};
