import { PeriodData } from '../context/ReportDataContext';

type Subheading = {
  [key: string]: string;
  howWell: string;
  howImportant: string;
};
type Total = {
  howWell: string;
  howImportant: string;
};
export interface ExtractDataType {
  heading: string;
  subheadings: Subheading[];
  total: Total;
}

export const extractData = (
  inputDataArray: any[],
  selectedPeriod: string,
): ExtractDataType[] => {
  const extractedData: ExtractDataType[] = [];

  for (let i = 0; i < inputDataArray.length; i++) {
    const item = inputDataArray[i];
    const { text } = item;
    let selectedPeriodKey;
    const period1 = inputDataArray[0].period1;
    const period2 = inputDataArray[0].period2;

    if (period1 === period2) {
      selectedPeriodKey = 'period2';
    } else if (selectedPeriod === period1) {
      selectedPeriodKey = 'period1';
    } else {
      selectedPeriodKey = 'period2';
    }

    if (typeof text === 'string' && text.startsWith('!')) {
      const heading = text.substring(1);
      const subheadings: Subheading[] = [];
      let total: Total = {
        howWell: '',
        howImportant: '',
      };

      if (selectedPeriodKey && typeof item[selectedPeriodKey] === 'object') {
        const wValue = item[selectedPeriodKey].w;
        const iValue = item[selectedPeriodKey].i;

        total.howWell =
          typeof wValue === 'string' && wValue.startsWith('!')
            ? wValue.substring(1)
            : String(wValue);

        total.howImportant =
          typeof iValue === 'string' && iValue.startsWith('!')
            ? iValue.substring(1)
            : String(iValue);
      }

      extractedData.push({ heading, subheadings, total });
    } else if (text) {
      const match = text.match(/^(\d+\.\d+)\s+(.*)/);
      if (match) {
        const subheadingNumber = match[1];
        const subheadingText = match[2];
        let howWell = '';
        let howImportant = '';

        if (selectedPeriodKey) {
          const wValue = item[selectedPeriodKey].w;
          howWell =
            typeof wValue === 'string' && wValue.startsWith('!')
              ? wValue.substring(1)
              : String(wValue);

          const iValue = item[selectedPeriodKey].i;
          howImportant =
            typeof iValue === 'string' && iValue.startsWith('!')
              ? iValue.substring(1)
              : String(iValue);
        }

        const index = extractedData.length - 1;
        extractedData[index].subheadings.push({
          [subheadingNumber]: subheadingText,
          howWell,
          howImportant,
        });
      }
    }
  }

  return extractedData;
};

export const extractPeriodNumber = (
  period: string[],
  position: number,
): number => {
  if (!period) {
    return 0;
  }
  let count = 0;

  for (let i = 0; i < period.length; i++) {
    const value = period[i];
    if (value !== '') {
      let number = parseInt(value, 10);
      if (isNaN(number)) {
        number = parseInt(value.substring(1), 10);
      }
      if (!isNaN(number) && number !== 0) {
        count++;
        if (count === position) {
          return number;
        }
      }
    }
  }
  return 0;
};

export const getPerfvsImportanceData = (
  extractedData: ExtractDataType[],
  selectedPeriod: string,
): PeriodData => {
  return {
    name: selectedPeriod,
    data: extractedData.slice(1).map((item: ExtractDataType) => {
      const howWell = Number(item.total.howWell);
      const howImportant = Number(item.total.howImportant);
      return {
        label: item.heading,
        howWell,
        howImportant,
        opportunityIndex: 2 * howImportant - howWell,
      };
    }),
  };
};

export const secondPeriodExtract = (
  compareData: any,
  selectedPeriod: string,
): string => {
  if (compareData.length === 0) {
    return '';
  }

  const period1 = compareData[0].period1;
  const period2 = compareData[0].period2;

  let secondPeriodKey;

  if (period1 === period2) {
    secondPeriodKey = period1;
  } else if (selectedPeriod === period1) {
    secondPeriodKey = period2;
  } else {
    secondPeriodKey = period1;
  }

  if (secondPeriodKey === 'previous') {
    secondPeriodKey = '';
  } else if (secondPeriodKey === selectedPeriod) {
    secondPeriodKey = '';
  }
  return secondPeriodKey;
};
