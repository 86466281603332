import {
  Button,
  FormControl,
  Grid,
  InputLabel,
  MenuItem,
  Select,
  SelectChangeEvent,
  TextField,
} from '@mui/material';
import Box from '@mui/material/Box';
import Tab from '@mui/material/Tab';
import Tabs from '@mui/material/Tabs';
import Typography from '@mui/material/Typography';
import axios from 'axios';
import { useFormik } from 'formik';
import { t } from 'i18next';
import { observer } from 'mobx-react-lite';
import { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import { toast } from 'react-toastify';
import { Template } from '../../model';
import { useStores } from '../../stores/hooks/useStores';
import { getSelectedCompanyFromLocalStorage } from '../../utils/LocalStorage';
import './CustomStyle.css';
import { Respondents } from './Respondents';

interface TabPanelProps {
  children?: React.ReactNode;
  index: number;
  value: number;
}

function CustomTabPanel(props: TabPanelProps) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box sx={{ p: 3 }}>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
}

function a11yProps(index: number) {
  return {
    id: `simple-tab-${index}`,
    'aria-controls': `simple-tabpanel-${index}`,
  };
}

interface IQueryLanguage {
  code: string | null;
  language: string | null;
  mainintroduction: string | null;
  qualifyingintroduction: string | null;
  qualifyingquestions: string | null;
  questionintroduction: string | null;
  questions: any;
  resultintroduction: string | null;
  thanks: string | null;
  information: string | null;
  emailsubject: string | null;
  emailbody: string | null;
  smsbody: string | null;
  periodid: string | null;
  contactemailsubject: string | null;
  contactemailbody: string | null;
  remindemailsubject: string | null;
  remindemailbody: string | null;
  remindsmsbody: string | null;
  companyId: string | null;
  created: string | null;
  modified: string | null;
  createdBy: string | null;
  modifiedBy: string | null;
}

const CreateNewLanguageSection = () => {
  const [value, setValue] = useState(0);
  const { surveyId, periodId, language } = useParams();
  const selectedCompany = getSelectedCompanyFromLocalStorage();
  const [questionTemplate, setQuestionTemplate] = useState<any>({
    template: [],
  });

  const [queryLanguage, setQueryLanguage] = useState<IQueryLanguage>(
    {} as IQueryLanguage,
  );

  const [currentTemplateName, setCurrentTemplateName] = useState('');
  const [selectedTemplate, setSelectedTemplate] = useState<Template | null>(
    null,
  );
  const {
    questionTemplateStore: { templates, getTemplates },
  } = useStores();

  useEffect(() => {
    getTemplates();
    // eslint-disable-next-line
  }, []);

  useEffect(() => {
    if (selectedTemplate?.templatesId) {
      axios
        .get(`/template/getTemplate/${selectedTemplate?.templatesId}`)
        .then((response) => {
          const result = response.data.result;

          if (!Array.isArray(result.template)) {
            result.template = Object.values(result.template);
          }

          setQuestionTemplate(result);
        });
    }
  }, [selectedTemplate]);

  useEffect(() => {
    if (
      queryLanguage &&
      queryLanguage.questions &&
      queryLanguage.questions.name
    ) {
      setCurrentTemplateName(queryLanguage.questions.name);
    }
  }, [queryLanguage]);

  const handleTemplateChange = (event: SelectChangeEvent) => {
    setCurrentTemplateName(event.target.value as string);
  };

  useEffect(() => {
    const fetchLanguage = async () => {
      const response = await axios.post(`/getQueryLanguage`, {
        mode: 'open',
        object: 'query',
        id: `l_${surveyId}_${periodId}_${language}`,
      });
      setQueryLanguage(response.data.result);
    };

    if (surveyId) {
      fetchLanguage();
    }
    //eslint-disable-next-line react-hooks/exhaustive-deps
  }, [surveyId]);

  const [initialValues, setInitialValues] = useState({
    mainintroduction: '',
    qualifyingintroduction: '',
    qualifyingquestions: '',
    questionintroduction: '',
    questions: { template: [] },
    resultintroduction: '',
    thanks: '',
    information: '',
    emailsubject: '',
    emailbody: '',
    smsbody: '',
    contactemailsubject: '',
    contactemailbody: '',
    remindemailsubject: '',
    remindemailbody: '',
    remindsmsbody: '',
  });

  useEffect(() => {
    if (queryLanguage) {
      setInitialValues({
        mainintroduction: queryLanguage.mainintroduction || '',
        qualifyingintroduction: queryLanguage.qualifyingintroduction || '',
        qualifyingquestions: queryLanguage.qualifyingquestions || '',
        questionintroduction: queryLanguage.questionintroduction || '',
        questions: queryLanguage.questions || { template: [] },
        resultintroduction: queryLanguage.resultintroduction || '',
        thanks: queryLanguage.thanks || '',
        information: queryLanguage.information || '',
        emailsubject: queryLanguage.emailsubject || 'Welcome to Board@Work',
        emailbody:
          queryLanguage.emailbody ||
          `
            Dear board collegue,
            <br />
            As a member of the board at ${selectedCompany?.name}, you are invited to our annual Board Evaluation.
            <br />
            You can access the evaluation by following the link below. The evaluation takes approximately 10 minutes and needs to be completed by deadline.
            <br />
            Here is the link to the Board Evaluation: {url}
            <br />
            If you have any questions or notice any issues you are welcome to contact Jonathan Kalma: jonathan.kalma@kunskapspartner.se from Kunskapspartner, who is administrating the evaluation.
            <br />
            Thank you for your participation.
        `,
        smsbody: queryLanguage.smsbody || '',
        contactemailsubject: queryLanguage.contactemailsubject || '',
        contactemailbody: queryLanguage.contactemailbody || '',
        remindemailsubject: queryLanguage.remindemailsubject || '',
        remindemailbody: queryLanguage.remindemailbody || '',
        remindsmsbody: queryLanguage.remindsmsbody || '',
      });
    }
    //eslint-disable-next-line react-hooks/exhaustive-deps
  }, [queryLanguage]);

  const formik = useFormik({
    initialValues: initialValues,
    onSubmit: async (values: any) => {
      try {
        if (queryLanguage) {
          const createQueryLanguageObject = {
            mode: 'save',
            code: surveyId,
            periodid: periodId,
            language: language?.toLowerCase(),
            object: 'querylanguage',
            id: '',
            new: '',
            companyId: selectedCompany?.companyId,
            ...values,
            questions: {
              name: 'Second template',
              type: 'master',
              template: values.questions.template,
            },
          };

          await axios.post('/createQueryLanguage', createQueryLanguageObject);
        } else {
          const createQueryLanguageObject = {
            mode: 'save',
            code: surveyId,
            periodid: periodId,
            language: language?.toLowerCase(),
            object: 'querylanguage',
            id: '',
            new: '1',
            companyId: selectedCompany?.companyId,
            ...values,
            questions: {
              name: 'Second template',
              type: 'master',
              template: values.questions.template,
            },
          };

          await axios.post('/createQueryLanguage', createQueryLanguageObject);

          window.location.reload();
        }
        toast.success(t('common.savedSuccessfully'));
      } catch (error) {
        toast.error(t('common.somethingWentWrong'));
      }
    },
    enableReinitialize: true,
  });

  const handleChange = (event: React.SyntheticEvent, newValue: number) => {
    setValue(newValue);
  };

  useEffect(() => {
    if (questionTemplate) {
      formik.setFieldValue('questions', {
        template: [...(questionTemplate.template as never[])],
      });
    }
    //eslint-disable-next-line react-hooks/exhaustive-deps
  }, [questionTemplate]);

  return (
    <>
      <Box sx={{ width: '100%' }}>
        <Box
          sx={{ borderBottom: 1, borderColor: 'divider', marginTop: '10px' }}
        >
          <Tabs value={value} onChange={handleChange} aria-label="survey tabs">
            <Tab label={t('common.questions')} {...a11yProps(0)} />
            <Tab label={t('common.invitationMessages')} {...a11yProps(1)} />
            <Tab label={t('common.respondents')} {...a11yProps(2)} />
          </Tabs>
        </Box>

        <form onSubmit={formik.handleSubmit}>
          {value !== 2 && (
            <Grid item xs={12}>
              <Box display="flex" justifyContent="flex-end">
                <Button
                  type="submit"
                  variant="contained"
                  color="primary"
                  style={{
                    margin: '20px 20px 0 20px',
                  }}
                >
                  {t('common.save')}
                </Button>
              </Box>
            </Grid>
          )}
          <CustomTabPanel value={value} index={0}>
            <Grid container spacing={2}>
              <Grid item xs={6}>
                <TextField
                  label={t('common.surveyId')}
                  variant="outlined"
                  fullWidth
                  disabled
                  value={surveyId}
                />
              </Grid>
              <Grid item xs={6}>
                <TextField
                  label={t('common.periodId')}
                  variant="outlined"
                  fullWidth
                  disabled
                  value={periodId}
                />
              </Grid>

              <Grid item xs={12}>
                <Typography variant="body1">
                  {t('common.introduction')}
                </Typography>
              </Grid>

              <Grid item xs={12}>
                <TextField
                  label={t('common.mainIntroduction')}
                  multiline
                  variant="outlined"
                  fullWidth
                  minRows={4}
                  maxRows={10}
                  {...formik.getFieldProps('mainintroduction')}
                />
              </Grid>

              <Grid item xs={12}>
                <Typography variant="body1">{t('common.questions')}</Typography>
              </Grid>

              <Grid item xs={12}>
                <TextField
                  id="outlined-multiline-static"
                  label={t('common.questionintroduction')}
                  multiline
                  rows={2}
                  variant="outlined"
                  fullWidth
                  {...formik.getFieldProps('questionintroduction')}
                />
              </Grid>

              <Grid
                item
                xs={12}
                className={queryLanguage ? 'hide-delete-questions' : ''}
              >
                <FormControl fullWidth>
                  <InputLabel>{t('common.questionTemplates')}</InputLabel>
                  <Select
                    value={currentTemplateName}
                    label={t('common.questionTemplates')}
                    onChange={handleTemplateChange}
                  >
                    {templates &&
                      templates.map((template: Template, index: number) => {
                        return (
                          <MenuItem
                            key={index}
                            value={template.name}
                            onClick={() => setSelectedTemplate(template)}
                          >
                            {template.name}
                          </MenuItem>
                        );
                      })}
                  </Select>
                </FormControl>
              </Grid>

              <Grid item xs={12}>
                <Typography variant="body1">
                  {t('common.thankYouAndCompareYourself')}
                </Typography>
              </Grid>

              <Grid item xs={12}>
                <TextField
                  label={t('common.textDisplaysAsThankYouText')}
                  multiline
                  rows={2}
                  variant="outlined"
                  fullWidth
                  {...formik.getFieldProps('thanks')}
                />
              </Grid>
              <Grid item xs={12}>
                <TextField
                  label={t('common.resultIntroduction')}
                  multiline
                  rows={2}
                  variant="outlined"
                  fullWidth
                  {...formik.getFieldProps('resultintroduction')}
                />
              </Grid>
            </Grid>
          </CustomTabPanel>
          <CustomTabPanel value={value} index={1}>
            <Grid container spacing={2}>
              <Grid item xs={12}>
                <Typography variant="body1">
                  {t('common.invitationEmail')}
                </Typography>
              </Grid>

              <Grid item xs={12}>
                <TextField
                  label={t('common.emailSubject')}
                  variant="outlined"
                  fullWidth
                  {...formik.getFieldProps('emailsubject')}
                />
              </Grid>

              <Grid item xs={12} className="">
                <TextField
                  label={t('common.emailBody')}
                  multiline
                  rows={7}
                  variant="outlined"
                  fullWidth
                  {...formik.getFieldProps('emailbody')}
                />
              </Grid>

              <Grid item xs={12}>
                <Typography variant="body1">
                  {t('common.emailTemplateText')}
                </Typography>
              </Grid>

              <Grid item xs={12}>
                <TextField
                  label={t('common.emailSubject')}
                  variant="outlined"
                  fullWidth
                  {...formik.getFieldProps('remindemailsubject')}
                />
              </Grid>
              <Grid item xs={12}>
                <TextField
                  label={t('common.emailBody')}
                  multiline
                  rows={7}
                  variant="outlined"
                  fullWidth
                  {...formik.getFieldProps('remindemailbody')}
                />
              </Grid>

              <Grid item xs={12}>
                <Typography variant="body1">
                  {t('common.contactEmailText')}
                </Typography>
              </Grid>

              <Grid item xs={12}>
                <TextField
                  label={t('common.emailSubject')}
                  variant="outlined"
                  fullWidth
                  {...formik.getFieldProps('contactemailsubject')}
                />
              </Grid>
              <Grid item xs={12}>
                <TextField
                  label={t('common.emailBody')}
                  multiline
                  rows={7}
                  variant="outlined"
                  fullWidth
                  {...formik.getFieldProps('contactemailbody')}
                />
              </Grid>

              <Grid item xs={12}>
                <Typography variant="body1">
                  {t('common.contactInfoText')}
                </Typography>
              </Grid>

              <Grid item xs={12}>
                <TextField
                  label={t('common.contactInformation')}
                  multiline
                  rows={2}
                  variant="outlined"
                  fullWidth
                  {...formik.getFieldProps('information')}
                />
              </Grid>
            </Grid>
          </CustomTabPanel>
        </form>

        <CustomTabPanel value={value} index={2}>
          <Respondents />
        </CustomTabPanel>
      </Box>
    </>
  );
};

export default observer(CreateNewLanguageSection);
