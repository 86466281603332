import { Box, Button, Grid, TextField, Typography } from '@mui/material';
import axios from 'axios';
import { useFormik } from 'formik';
import { useParams } from 'react-router-dom';
import { toast } from 'react-toastify';
import { getSelectedCompanyFromLocalStorage } from '../../utils/LocalStorage';
import { t } from 'i18next';

export const InviteWithoutEmail = () => {
  const { surveyId, periodId } = useParams();
  const selectedCompany = getSelectedCompanyFromLocalStorage();
  const formik = useFormik({
    initialValues: {
      numberOfPasswords: '',
    },
    onSubmit: async (values: any) => {
      try {
        await axios.post('/createRespondent ', {
          mode: 'save',
          respondent_type: '0',
          subject: '',
          body: '',
          smsbody: '',
          respondents_filetype: '',
          respondents: '',
          respondent_name: '',
          respondent_email: '',
          respondent_mobile: '',
          respondent_contactname: '',
          respondent_contactemail: '',
          respondent_extraemail: '',
          respondent_anonymous: '',
          respondent_project: '',
          respondent_answers: '',
          number: values.numberOfPasswords,
          code: `${surveyId}_${periodId}`, //code_periodId
          language: 'en',
          object: 'respondents',
          id: '',
          companyId: selectedCompany?.companyId,
          invite: false,
          new: '',
        });

        toast.success(t('common.passwordsCreated'));
      } catch (error) {
        toast.error(t('common.somethingWentWrong'));
      } finally {
        formik.resetForm();
      }
    },
  });

  return (
    <form onSubmit={formik.handleSubmit}>
      <Grid container spacing={2}>
        <Grid item xs={12}>
          <Typography variant="body1">
            {t('common.addNumberOfPasswords')}
          </Typography>
        </Grid>
        <Grid item xs={6}>
          <TextField
            label={t('common.numberOfPasswords')}
            variant="outlined"
            fullWidth
            {...formik.getFieldProps('numberOfPasswords')}
          />
        </Grid>

        <Grid item xs={12}>
          <Box display="flex" justifyContent="flex-end">
            <Button type="submit" variant="contained" color="primary">
              {t('common.save')}
            </Button>
          </Box>
        </Grid>
      </Grid>
    </form>
  );
};
