import { t } from 'i18next';
import styled from 'styled-components';
import { useContext } from 'react';
import { PagePrintContext } from '../../context/PagePrintContext';
import { CircleNumber } from '../CircleNumber';
import { PrintPage } from '../PrintPage/PrintPage';
import { Text } from '../Text';
import { Content } from './components/Content';
import { GreyBackgroundWrapper } from './components/GreyBackgroundWrapper';
import { Header } from './components/Header';
import { CommentsData } from '../../api/reports/model/comments-response';
import { TextArea } from '../TextArea';

interface BoardReflectionsPageTypes {
  comments: CommentsData;
  pageKey: number;
}

export const BoardReflectionsPage = ({
  pageKey,
  comments,
}: BoardReflectionsPageTypes) => {
  const { skippedPages } = useContext(PagePrintContext);

  const CommentIcon = '/assets/images/CommentIcon.svg';

  return (
    <PrintPage
      className={
        skippedPages.includes(`${t('reports.reflections')} ${pageKey}`)
          ? 'no-print'
          : ''
      }
    >
      <Header
        title={t('reports.reflections')}
        groupPageNum={pageKey}
        resultGroup={6}
      />

      <Content>
        <GreyBackgroundWrapper>
          <SummaryItemWrapper>
            <CircleNumber value={pageKey} color="orange" size="l" />
            <SummaryTextWrapper>
              <Text size="l" isBold value={comments.header} />
            </SummaryTextWrapper>
          </SummaryItemWrapper>
          {comments.list.map((comment: string, index: number) => {
            const removePrefix = (comment: string) => {
              return comment.replace(/&#128172;|<br>/g, '');
            };
            return (
              <CommentWrapper key={index}>
                <ImageWrapper>
                  <img src={CommentIcon} alt="Comment icon" />
                </ImageWrapper>

                <Text size="l" value={removePrefix(comment)} />
              </CommentWrapper>
            );
          })}
        </GreyBackgroundWrapper>
        <TextArea />
      </Content>
    </PrintPage>
  );
};

const SummaryItemWrapper = styled.div`
  display: flex;
  justify-content: flex-start;
  align-items: center;
  gap: 15px;
  margin-bottom: 15px;

  &:last-of-type {
    margin-bottom: 0;
  }
`;

const SummaryTextWrapper = styled.div`
  div {
    margin-bottom: 0;
  }
`;

const CommentWrapper = styled.div`
  display: flex;
  margin-top: 14px;
  div {
    margin-bottom: 0;
  }
`;

const ImageWrapper = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  margin-right: 10px;

  img {
    height: 20px;
    width: 20px;
  }
`;
