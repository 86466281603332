import { Autocomplete, TextField } from '@mui/material';
import {
  Dispatch,
  SetStateAction,
  useContext,
  useEffect,
  useState,
} from 'react';
import { useSurveys } from '../api';
import { ReportType, useDates } from '../api/reports';
import { usePeriods } from '../api/reports/usePeriods';
import { getComponentData } from '../api/reports/useReportComponentsData';
import { getCommentData } from '../api/reports/useReportCommentsData';
import { fetchReportData } from '../api/reports/useReportData';
import { useReports } from '../api/reports/useReports';
import {
  ReportDataAction,
  ReportDataActionType,
  ReportDataContext,
} from '../context/ReportDataContext';
import { ActionTypes, AppendixDataContext } from '../context/AppendixContext';
import { Period, Report, Survey } from '../model';
import styled from 'styled-components';
import { t } from 'i18next';

interface ReportDataPickerProps {
  handlePrint: any;

  surveyValue: Survey | null;
  setSurveyValue: Dispatch<SetStateAction<Survey | null>>;
  periodValue: Period | null;
  setPeriodValue: Dispatch<SetStateAction<Period | null>>;
  reportValue: Report | null;
  setReportValue: Dispatch<SetStateAction<Report | null>>;
}

export const ReportDataPicker: React.FC<ReportDataPickerProps> = ({
  handlePrint,
  periodValue,
  reportValue,
  setPeriodValue,
  setReportValue,
  setSurveyValue,
  surveyValue,
}) => {
  const [startDate, endDate] = useDates(
    surveyValue?.id || null,
    periodValue?.id || null,
  );
  const { surveys } = useSurveys();
  const periods = usePeriods(surveyValue?.id || null);
  const reports = useReports(surveyValue?.id || null, periodValue?.id || null);

  const { dispatch } = useContext(ReportDataContext);
  const { appendixDispatch } = useContext(AppendixDataContext);

  const [reportDataComponents, setReportDataComponents] = useState<string[]>(
    [],
  );

  // Clear report data when period changes
  useEffect(() => {
    if (periodValue !== null) {
      setReportValue(null);
    }

    //eslint-disable-next-line react-hooks/exhaustive-deps
  }, [periodValue]);

  // fetch report data and store components for further fetching
  useEffect(() => {
    const abortController = new AbortController();
    if (surveyValue?.id && reportValue?.id) {
      fetchReportData(surveyValue.id, reportValue.id, abortController).then(
        (reportData) => {
          setReportDataComponents(reportData.components);
          dispatch({
            type: ReportDataActionType.SET_TITLE_AND_SUBTITLE,
            payload: { title: reportData.title, subTitle: reportData.subTitle },
          });
        },
      );
    }

    return () => abortController.abort();
    //eslint-disable-next-line react-hooks/exhaustive-deps
  }, [surveyValue, reportValue]);

  // fetch report data and dispatch actions
  useEffect(() => {
    const abortController = new AbortController();
    getReportComponents(
      surveyValue,
      periodValue,
      reportDataComponents,
      startDate,
      endDate,
      abortController,
      dispatch,
    );
    getReportComments(surveyValue, periodValue, startDate, endDate, dispatch);
    return () => abortController.abort();
    //eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    surveyValue,
    periodValue,
    reportDataComponents,
    startDate,
    endDate,
    dispatch,
  ]);

  return (
    <>
      <Autocomplete
        sx={{ width: 300, display: 'inline-flex' }}
        options={surveys}
        onChange={(event, newValue) => {
          setPeriodValue(null);
          setSurveyValue(newValue);
          setReportValue(null);
        }}
        renderInput={(params) => (
          <TextField {...params} label={t('common.survey')} />
        )}
        groupBy={(options) => options.groupName}
        getOptionLabel={(option) => option.name}
      />
      <Autocomplete
        sx={{ width: 300, display: 'inline-flex', marginLeft: '20px' }}
        options={periods}
        defaultValue={null}
        isOptionEqualToValue={(option, value) => option.id === value.id}
        value={periodValue}
        onChange={(event, newValue) => {
          setPeriodValue(newValue);
          newValue &&
            appendixDispatch({
              type: ActionTypes.SET_PERIOD,
              payload: newValue,
            });
        }}
        renderInput={(params) => (
          <TextField {...params} label={t('common.period')} />
        )}
        getOptionLabel={(option) => option?.displayName || ''}
      />
      <Autocomplete
        sx={{ width: 300, display: 'inline-flex', marginLeft: '20px' }}
        options={reports}
        defaultValue={null}
        isOptionEqualToValue={(option, value) => option.id === value.id}
        value={reportValue}
        onChange={(event, newValue) => setReportValue(newValue)}
        renderInput={(params) => (
          <TextField {...params} label={t('common.report')} />
        )}
        getOptionLabel={(option) => option?.displayName || ''}
      />

      <PrintButton
        disabled={!surveyValue || !periodValue || !reportValue}
        onClick={handlePrint}
      >
        {t('reports.savePdf')}
      </PrintButton>
    </>
  );
};

function getReportComponents(
  surveyValue: Survey | null,
  periodValue: Period | null,
  reportDataComponents: string[],
  startDate: Date | null,
  endDate: Date | null,
  abortController: AbortController,
  dispatch: Dispatch<ReportDataAction>,
) {
  if (
    surveyValue?.id &&
    periodValue?.id &&
    reportDataComponents.length > 0 &&
    startDate &&
    endDate
  ) {
    dispatch({
      type: ReportDataActionType.SET_PERIOD_ID,
      payload: periodValue.id,
    });

    reportDataComponents.forEach((reportComponent) => {
      getComponentData({
        clientId: surveyValue.id,
        clientPeriodId: periodValue.id,
        language: 'en',
        startDate,
        endDate,
        reportComponent,
        abortController,
      }).then((componentData) => {
        if (componentData.type === ReportType.top5) {
          dispatch({
            type: ReportDataActionType.SET_TOP_5,
            payload: componentData.data,
          });
        }
        if (componentData.type === ReportType.xyplot) {
          dispatch({
            type: ReportDataActionType.SET_OPPORTUNITY_LANDSCAPE,
            payload: componentData.data,
          });
        }
        if (componentData.type === ReportType.compare) {
          dispatch({
            type: ReportDataActionType.SET_COMPARE_2,
            payload: [],
          });
          dispatch({
            type: ReportDataActionType.SET_COMPARE,
            payload: componentData.data,
          });
        }
        if (componentData.type === ReportType.compare2) {
          dispatch({
            type: ReportDataActionType.SET_COMPARE,
            payload: [],
          });
          dispatch({
            type: ReportDataActionType.SET_COMPARE_2,
            payload: componentData.data,
          });
        }
      });
    });
  }
}

function getReportComments(
  surveyValue: Survey | null,
  periodValue: Period | null,
  startDate: Date | null,
  endDate: Date | null,
  dispatch: Dispatch<ReportDataAction>,
) {
  if (surveyValue?.id && periodValue?.id && startDate && endDate) {
    getCommentData({
      clientId: surveyValue.id,
      clientPeriodId: periodValue.id,
      language: 'en',
      startDate,
      endDate,
    })
      .then((componentData) =>
        dispatch({
          type: ReportDataActionType.SET_COMMENTS_DATA,
          payload: componentData,
        }),
      )
      .catch((error) => console.error(error));
  }
}

const PrintButton = styled.button`
  width: 200px;
  padding: 20px 10px;
  border-radius: 8px;
  color: #ffffff;
  border: 0;
  background-color: #f47b30;
  margin-top: 20px;
  cursor: pointer;

  &:disabled {
    opacity: 50%;
  }
`;
