import axios from 'axios';
import { useEffect, useState } from 'react';
import { Period } from '../../model';
import { PeriodsApiResponse } from './model/periods-api-response';

/**
 * Gets periods for a clientId. Example:
 * {
 *  clientPeriod: clientPeriod,
 *  clientPeriod2020: clientPeriod2020,
 * }
 */
export const usePeriods = (clientCode: string | null) => {
  const [periods, setPeriods] = useState<Period[]>([]);

  useEffect(() => {
    const abortController = new AbortController();
    if (!clientCode) {
      setPeriods([]);
    } else {
      axios
        .get<PeriodsApiResponse>(`/reportdata/periods/${clientCode}`, {
          signal: abortController.signal,
        })
        .then((response) => response.data.result)
        .then((periodsResponse: Record<string, string>): Period[] =>
          Object.keys(periodsResponse).map((id) => ({
            id,
            displayName: periodsResponse[id],
          })),
        )
        .then((periods) => setPeriods(periods));
    }

    return () => abortController.abort();
  }, [clientCode]);

  return periods;
};
