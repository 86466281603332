import { Grid } from '@mui/material';
import { observer } from 'mobx-react';
import { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import styled from 'styled-components';
import { useStores } from '../../stores/hooks/useStores';
import { UploadLogo } from './UploadLogo';
import AssignQuestionTemplates from './AssignQuestionTemplates';

const Company = () => {
  const { t } = useTranslation();
  const {
    companyStore: { selectedCompany, getTemplatesByCompany },
  } = useStores();

  const renderLogos = () => {
    const logos = selectedCompany?.logos;
    if (!logos || !logos.length) return null;

    return <LogoImg src={logos[logos.length - 1]} />;
  };

  useEffect(() => {
    if (selectedCompany) {
      getTemplatesByCompany();
    }

    // eslint-disable-next-line
  }, [selectedCompany]);

  if (!selectedCompany) return null;

  return (
    <CompanyWrapper>
      <Grid container spacing={2}>
        <Grid item xs={12}>
          <Text>{t('company.assignQuestionTemplates')}:</Text>

          <AssignQuestionTemplates />
        </Grid>

        <Grid item xs={12}>
          <Text>{t('company.companyLogos')}:</Text>
          <LogoSectionWrapper>{renderLogos()}</LogoSectionWrapper>

          <UploadLogo />
        </Grid>
      </Grid>
    </CompanyWrapper>
  );
};

export default observer(Company);

const CompanyWrapper = styled.div`
  margin: 20px;
`;

const LogoSectionWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
`;

const LogoImg = styled.img`
  height: 100px;
  width: auto;
  margin-bottom: 20px;
  padding: 20px;
  background-color: #1f4b58;
  border-radius: 10px;
`;

const Text = styled.div`
  font-size: 18px;
  font-weight: 600;
  color: #1f4b58;
  margin-bottom: 20px;
`;
