import styled from 'styled-components';

export const LogoSizes = ['large', 'medium', 'regular'] as const;
export type LogoSizesType = 'medium' | 'large' | 'regular';

export const LogoNames = [
  'KP-logo.png',
  'logo-inverted.png',
  'logo.png',
] as const;
export type LogosType = 'KP-logo.png' | 'logo-inverted.png' | 'logo.png';

interface ILogoProps {
  size?: LogoSizesType;
  src?: LogosType;
}
const getLogoSize = (size: LogoSizesType) => {
  switch (size) {
    case 'large':
      return '150px';
    case 'medium':
      return '90px';
    case 'regular':
      return '60px';
    default:
      return '60px';
  }
};
export const Logo: React.FC<ILogoProps> = ({
  size = 'regular',
  src = 'logo.png',
}) => <LogoWrapper size={size} src={`/assets/images/${src}`} />;

// TODO: merge Logo with ClientLogo
export const ClientLogo: React.FC<{ size: LogoSizesType; src: string }> = ({
  size = 'large',
  src = 'logo.png',
}) => <LogoWrapper size={size} src={src} />;

const LogoWrapper = styled.img<{ size: LogoSizesType }>`
  height: ${({ size }) => getLogoSize(size)};
  width: fit-content;
`;
