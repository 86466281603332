import { Navigate } from 'react-router-dom';
import { useAxiosConfig } from '../../api/shared/useAxiosConfig';
import { useAuth } from '../../providers/auth';
import { Layout } from '../Layout/Layout';

export const AuthenticatedLayoutRoute: React.FC = () => {
  const { user } = useAuth();
  useAxiosConfig(user?.token || '');

  if (!user) {
    return <Navigate to="login" />;
  } else {
    return <Layout />;
  }
};
