import axios from 'axios';
import { BaseApiResponse } from '../shared/base-api-response';
import { ReportType } from './model/report-type';
import { CommentsData } from './model/comments-response';

interface ReportCommentApiResponse extends BaseApiResponse {
  result: {
    report: ReportType;
  };
}

interface ReportCommentParameters {
  clientId: string;
  clientPeriodId: string;
  startDate: Date;
  endDate: Date;
  language: 'en' | 'se';
}

export function getCommentData({
  clientId,
  clientPeriodId,
  startDate,
  endDate,
  language,
}: ReportCommentParameters) {
  return axios
    .post<ReportCommentApiResponse>(`admin_report`)
    .then((response) => response.data.result)
    .then(() =>
      axios.post<
        BaseApiResponse & {
          result: CommentsData[];
        }
      >(`admin_report`, {
        report: 'comments',
        width: 360,
        code: clientId,
        period: clientPeriodId,
        language,
        date1: startDate.toLocaleDateString('fr-CA'),
        date2: endDate.toLocaleDateString('fr-CA'),
      }),
    )
    .then((response) => response.data.result);
}
