import AddTaskIcon from '@mui/icons-material/AddTask';
import ContentCopyIcon from '@mui/icons-material/ContentCopy';
import DeleteIcon from '@mui/icons-material/Delete';
import { IconButton, TextField } from '@mui/material';
import { t } from 'i18next';
import { observer } from 'mobx-react-lite';
import { useState } from 'react';
import styled from 'styled-components';
import { IQuestion } from '../stores/QuestionTemplateStore';
import { useStores } from '../stores/hooks/useStores';
interface TableProps {
  template: any;
  ordinalNumber: number;
  totalNumberOfGroups: number;
}

const QuestionsTable: React.FC<TableProps> = ({
  template,
  ordinalNumber,
  totalNumberOfGroups,
}) => {
  const {
    questionTemplateStore: {
      deleteQuestionGroup,
      addQuestionToGroup,
      deleteQuestionFromGroup,
      editQuestionInGroup,
      editQuestionGroupHeader,
    },
  } = useStores();
  const [questions] = useState(template?.questions || []);

  const isPreviewAllQuestionGroup = totalNumberOfGroups === ordinalNumber;

  return (
    <>
      <TableDescriptionWrapper>
        <div
          style={{
            width: 'calc(100% - 100px)',
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'space-between',
            marginBottom: 10,
          }}
        >
          <div style={{ width: 50, textAlign: 'center' }}>
            {ordinalNumber + '.'}
          </div>
          <TextField
            style={{
              width: 'calc(100% - 50px)',
            }}
            variant="outlined"
            value={template.group}
            onChange={(e) => {
              editQuestionGroupHeader(ordinalNumber - 1, e.target.value);
            }}
            disabled={isPreviewAllQuestionGroup}
          />
        </div>
        <ButtonWrapper>
          {!isPreviewAllQuestionGroup && (
            <>
              <IconButton
                title={t('common.addQuestion') || ''}
                onClick={() => {
                  addQuestionToGroup(ordinalNumber - 1);
                }}
              >
                <AddTaskIcon />
              </IconButton>

              <IconButton
                title={t('common.deleteQuestionGroup') || ''}
                aria-label="Delete"
                onClick={() => {
                  if (
                    window.confirm(
                      t('common.areYouSureDeleteQuestionGroup') ||
                        'Are you sure you want to delete question group?',
                    )
                  ) {
                    deleteQuestionGroup(ordinalNumber - 1);
                  }
                }}
              >
                <DeleteIcon />
              </IconButton>
            </>
          )}
        </ButtonWrapper>
      </TableDescriptionWrapper>
      <TableWrapper>
        <tbody>
          {questions.map((question: IQuestion, index: number) => (
            <TableRow key={index}>
              <td>
                <QuestionWrapper>
                  <QuestionType>{question.name}</QuestionType>
                  <TextField
                    style={{
                      width: 'calc(100% - 50px)',
                    }}
                    variant="outlined"
                    value={question.text}
                    onChange={(e) => {
                      editQuestionInGroup(
                        ordinalNumber - 1,
                        index,
                        e.target.value,
                      );
                    }}
                    disabled={isPreviewAllQuestionGroup}
                  />
                </QuestionWrapper>
              </td>
              <td
                style={{
                  width: '100px',
                }}
              >
                <ActionsWrapper>
                  <IconButton
                    title={t('common.copyToClipboard') || ''}
                    onClick={() => {
                      navigator.clipboard.writeText(question.text);
                    }}
                    style={{
                      marginRight:
                        question.type !== 'T' && !isPreviewAllQuestionGroup
                          ? '0'
                          : '40px',
                    }}
                  >
                    <ContentCopyIcon />
                  </IconButton>

                  {/* dont render for T question type */}
                  {question.type !== 'T' && !isPreviewAllQuestionGroup && (
                    <IconButton
                      className="delete-action"
                      onClick={() =>
                        deleteQuestionFromGroup(ordinalNumber - 1, index)
                      }
                    >
                      <DeleteIcon />
                    </IconButton>
                  )}
                </ActionsWrapper>
              </td>
            </TableRow>
          ))}
        </tbody>
      </TableWrapper>
    </>
  );
};

export default observer(QuestionsTable);

const QuestionWrapper = styled.div`
  display: flex;
  align-items: center;
`;

const QuestionType = styled.div`
  min-width: 50px;
  text-align: center;
`;

const TableDescriptionWrapper = styled.div`
  width: 100%;
  display: flex;
  justify-content: space-between;
  border-bottom: 2px solid #00b6f1;
  line-height: 40px;
  margin-top: 35px;
  margin-bottom: 20px;
`;

const TableWrapper = styled.table`
  border-collapse: collapse;
  width: 100%;
  thead {
    th {
      padding: 10px 0;
    }
  }

  tbody {
    td {
      font-size: 15px;
      padding: 0px 5px 5px 5px;
    }
  }
`;

const TableRow = styled.tr`
  border-bottom: 1px solid #eee;
`;

const ButtonWrapper = styled.div`
  display: flex;
  align-items: center;
  margin-bottom: 5px;
`;

const ActionsWrapper = styled.div`
  display: flex;
  justify-content: space-evenly;
  align-items: center;
`;
