import styled from 'styled-components';
import { Client } from '../../pages/GenerateReport';
import { ClientLogo, Logo } from '../Logo/Logo';
import { PrintPage } from '../PrintPage/PrintPage';
import { useContext, useEffect, useState } from 'react';
import { ReportDataContext } from '../../context/ReportDataContext';
import { getSelectedCompanyFromLocalStorage } from '../../utils/LocalStorage';
import { ICompany } from '../../providers/auth';

export interface InitialPageProps {
  client: Client;
}

const renderClientLogo = (client: ICompany | null) => {
  if (!client) return '';

  const lastLogo = client?.logos.length > 0 ? client?.logos.length - 1 : 0;

  return client.logos[lastLogo];
};

export const InitialPage: React.FC<InitialPageProps> = ({ client }) => {
  const { state } = useContext(ReportDataContext);

  const clientCompany = getSelectedCompanyFromLocalStorage();
  const [title, setTitle] = useState(state.title || 'Report name');

  useEffect(() => {
    if (state.title) {
      setTitle(state.title);
    }
  }, [state.title]);

  return (
    <PrintPage>
      <InitialPageWrapper>
        <TextWrapper>
          <Title
            value={title}
            onChange={(e) => {
              setTitle(e.target.value);
            }}
          />
        </TextWrapper>

        <LogoWrapper>
          <ClientLogo size="regular" src={renderClientLogo(clientCompany)} />
        </LogoWrapper>
        <LogoSection>
          <ClientLogo size="medium" src={client.logoUrl} />
          <KPLogo size="medium" src="KP-logo.png" />
        </LogoSection>
      </InitialPageWrapper>
    </PrintPage>
  );
};

export const InitialPageWrapper = styled.div`
  background: #1f4b58;
  width: 100%;
  height: 790px;
  position: relative;
`;

const Title = styled.input`
  color: white;
  font-size: 50px;
  font-weight: 600;
  background: transparent;
  border: none;
  padding-left: 0;
  width: 100%;
`;

const LogoSection = styled.div`
  background: white;
  width: 100%;
  height: 200px;
  position: absolute;
  bottom: 0;
  padding-left: 100px;
  display: flex;
  justify-content: space-between;
  align-items: center;
`;

const TextWrapper = styled.div`
  padding: 100px;
  padding-top: 100px;
`;

const KPLogo = styled(Logo)``;

const LogoWrapper = styled.div`
  padding-left: 100px;
`;
