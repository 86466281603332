import { t } from 'i18next';
import styled from 'styled-components';
import { CircleNumber } from './CircleNumber';
import { Text } from './Text';
import { Total } from '../context/AppendixContext';
import { ExtractDataType } from '../utils/dataExtractFunctions';
interface TableProps {
  tableData: ExtractDataType;
}

export const AppendixTable: React.FC<TableProps> = ({ tableData }) => {
  return (
    <>
      <TableDescriptionWrapper>
        <Text value={t(tableData.heading)} />
        {!!tableData.subheadings.length && (
          <PerfomanceIndexWrapper>
            <Text value={t('reports.performanceIndex')} />
            <CircleNumber
              value={(tableData.total as Total)?.howWell}
              color="orange"
              size="s"
            />
          </PerfomanceIndexWrapper>
        )}
      </TableDescriptionWrapper>
      <TableWrapper>
        <thead>
          <tr>
            <TextColumnHeader>
              <Text noMarginBottom value={t('reports.statements')} />
            </TextColumnHeader>
            <NumberColumnHeader>
              <Text noMarginBottom value={t('reports.howWell')} />
            </NumberColumnHeader>
            <NumberColumnHeader>
              <Text noMarginBottom value={t('reports.howImportant')} />
            </NumberColumnHeader>
          </tr>
        </thead>
        <tbody>
          {tableData.subheadings.map((row, index) => (
            <tr key={index}>
              <td>
                {Object.keys(row)[0]} {Object.values(row)[0]}
              </td>
              <NumberColumnData>{row.howWell}</NumberColumnData>
              <NumberColumnData>{row.howImportant}</NumberColumnData>
            </tr>
          ))}
        </tbody>
      </TableWrapper>
    </>
  );
};

const TableDescriptionWrapper = styled.div`
  width: 100%;
  display: flex;
  justify-content: space-between;
  border-bottom: 1px solid #00b6f1;
`;

const PerfomanceIndexWrapper = styled.div`
  display: flex;
  gap: 10px;
`;

const TableWrapper = styled.table`
  width: 100%;
  border-collapse: collapse;
  thead {
    th {
      padding: 10px 0;
    }
  }

  tbody {
    tr {
      background: #eeeeee;
      border-bottom: 10px solid white;
    }

    td {
      font-size: 15px;
      padding: 5px;
    }
  }
`;

const TextColumnHeader = styled.th`
  text-align: left;
`;

const NumberColumnHeader = styled.th`
  width: 110px;
  text-align: center;
`;

const NumberColumnData = styled.td`
  text-align: center;
`;
