import styled from 'styled-components';
import { Button } from '@mui/material';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import { useNavigate } from 'react-router-dom';
import CreateNewLanguageSection from './CreateNewLanguageSection';
import { t } from 'i18next';

export const AddLanguageSection = () => {
  const navigate = useNavigate();

  return (
    <PageWrapper>
      <Button
        variant="outlined"
        startIcon={<ArrowBackIcon />}
        onClick={() => {
          navigate(-1);
        }}
      >
        {t('common.back')}
      </Button>

      <CreateNewLanguageSection />
    </PageWrapper>
  );
};

const PageWrapper = styled.div`
  width: 1130px;
`;
