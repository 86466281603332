import CheckBoxIcon from '@mui/icons-material/CheckBox';
import CheckBoxOutlineBlankIcon from '@mui/icons-material/CheckBoxOutlineBlank';
import { Button } from '@mui/material';
import Autocomplete from '@mui/material/Autocomplete';
import Checkbox from '@mui/material/Checkbox';
import TextField from '@mui/material/TextField';
import { observer } from 'mobx-react';
import { useEffect, useState } from 'react';
import { ITemplate } from '../../stores/QuestionTemplateStore';
import { useStores } from '../../stores/hooks/useStores';

const icon = <CheckBoxOutlineBlankIcon fontSize="small" />;
const checkedIcon = <CheckBoxIcon fontSize="small" />;

const _AssignQuestionTemplates = () => {
  const {
    questionTemplateStore: {
      allQuestionTemplates: templates,
      getTemplates,
      setAssignedQuestionTemplates,
      assignedQuestionTemplates,
      getAssignedQuestionTemplates,
    },
    companyStore: { selectedCompany },
  } = useStores();

  const [value, setValue] = useState<ITemplate[]>(assignedQuestionTemplates);

  useEffect(() => {
    if (!templates.length) {
      getTemplates();
    }

    getAssignedQuestionTemplates(selectedCompany?.companyId || '', templates);
    // eslint-disable-next-line
  }, [selectedCompany?.companyId]);

  const handleValueChange = (_: any, newValue: any) => {
    setValue(newValue);
  };

  useEffect(() => {
    if (assignedQuestionTemplates) {
      setValue(assignedQuestionTemplates);
    }
  }, [assignedQuestionTemplates]);

  return (
    <>
      <Autocomplete
        limitTags={2}
        multiple
        options={templates || []}
        disableCloseOnSelect
        getOptionLabel={(option) => option.name}
        renderOption={(props, option, { selected }) => {
          return (
            <li key={option.templatesId} {...props}>
              <Checkbox
                icon={icon}
                checkedIcon={checkedIcon}
                style={{ marginRight: 8 }}
                checked={selected}
              />
              {option.name}
            </li>
          );
        }}
        style={{ width: 500 }}
        value={value}
        onChange={handleValueChange}
        renderInput={(params) => (
          <TextField
            {...params}
            label="Assigned question templates"
            placeholder="Type to search question templates"
          />
        )}
      />

      <Button
        style={{
          marginTop: 20,
          marginBottom: 50,
        }}
        type="submit"
        variant="contained"
        color="primary"
        onClick={() =>
          setAssignedQuestionTemplates(
            selectedCompany?.companyId || '',
            value.map((v) => v.templatesId),
          )
        }
      >
        Save question templates
      </Button>
    </>
  );
};

export default observer(_AssignQuestionTemplates);
