import { t } from 'i18next';
import { useContext } from 'react';
import styled from 'styled-components';
import { PagePrintContext } from '../../context/PagePrintContext';
import { PrintPage } from '../PrintPage/PrintPage';
import { Header } from './components/Header';

const TerminologyArray: ITermProps[] = [
  {
    heading: 'reports.perspectives',
    description: 'reports.perspectivesDescription',
    imageName: 'PerspectivesIcon',
  },
  {
    heading: 'reports.statements',
    description: 'reports.statementsDescription',
    imageName: 'StatementsIcon',
  },
  {
    heading: 'reports.index',
    description: 'reports.indexDescription',
    imageName: 'IndexIcon',
  },
  {
    heading: 'reports.priorities',
    description: 'reports.prioritiesDescription',
    imageName: 'PrioritiesIcon',
  },
];

interface ITermProps {
  heading: string;
  description: string;
  imageName:
    | 'PerspectivesIcon'
    | 'PrioritiesIcon'
    | 'StatementsIcon'
    | 'IndexIcon';
}

const Term: React.FC<ITermProps> = ({ description, heading, imageName }) => {
  return (
    <TermWrapper>
      <ImageWrapper>
        <img src={`/assets/images/${imageName}.svg`} alt={imageName} />
      </ImageWrapper>

      <TextWrapper>
        <Heading>{t(heading)}</Heading>
        <Description>{t(description)}</Description>
      </TextWrapper>
    </TermWrapper>
  );
};

export const TerminologyPage = () => {
  const { skippedPages } = useContext(PagePrintContext);

  return (
    <PrintPage
      className={
        skippedPages.includes(t('reports.terminology')) ? 'no-print' : ''
      }
    >
      <Header title={t('reports.terminology')} />

      <GridContent>
        {TerminologyArray.map((term, key) => (
          <Term key={key} {...term} />
        ))}
      </GridContent>
    </PrintPage>
  );
};

const ImageWrapper = styled.div`
  background: #e0e0e0;
  height: 140px;
  min-width: 140px;
  border-radius: 50%;
  display: flex;
  justify-content: center;
  align-items: center;

  img {
    height: 50px;
    width: 50px;
  }
`;

const TextWrapper = styled.div`
  display: flex;
  justify-content: flex-start;
  align-items: flex-start;
  flex-direction: column;
  margin-left: 20px;
`;

const TermWrapper = styled.div`
  display: flex;
  width: 50%;
  padding-bottom: 150px;
  padding-right: 50px;
  :nth-child(3),
  :nth-child(4) {
    padding-bottom: 0;
  }
`;

const Heading = styled.div`
  color: #1c2328;
  font-size: 20px;
  font-weight: 600;
  margin-bottom: 6px;
`;

const Description = styled.div`
  color: #1f4b58;
  font-size: 18px;
`;

const GridContent = styled.div`
  padding: 50px;
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  align-items: center;
`;
