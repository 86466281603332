import { ICompany } from '../providers/auth';

const ITEM_NAME = 'lang';
const SELECTED_COMPANY = 'selectedCompany';
const COMPANIES = 'companies';

const isValidLanguage = (lang?: string): boolean => {
  return lang === 'en' || lang === 'sv';
};

export const setLanguage = (lang: string) => {
  if (isValidLanguage(lang)) {
    localStorage.setItem(ITEM_NAME, lang);

    window.location.reload();
  }
};

export function getDefaultLanguage(): string {
  const localStorageLang = localStorage.getItem(ITEM_NAME);

  if (localStorageLang) {
    if (isValidLanguage(localStorageLang)) {
      return localStorageLang;
    }

    localStorage.removeItem(ITEM_NAME);
  }

  return 'en';
}

export const setSelectedCompanyToLocalStorage = (company: ICompany) => {
  localStorage.setItem(SELECTED_COMPANY, JSON.stringify(company));
};

export function getSelectedCompanyFromLocalStorage(): ICompany | null {
  const localStorageSelectedCompany = localStorage.getItem(SELECTED_COMPANY);

  if (localStorageSelectedCompany) {
    return JSON.parse(localStorageSelectedCompany);
  }

  return null;
}

export const removeCompaniesFromLocalStorage = () => {
  localStorage.removeItem(SELECTED_COMPANY);
  localStorage.removeItem(COMPANIES);
};
