import { useTranslation } from 'react-i18next';
import { NavLink } from 'react-router-dom';
import styled from 'styled-components';

type NavigationItemType = {
  label: string;
  value: string;
};
const navigationsItems: NavigationItemType[] = [
  {
    label: 'navigation.manageCompanies',
    value: '/manageCompanies',
  },
  {
    label: 'navigation.templates',
    value: '/templates',
  },
  {
    label: 'navigation.manageSurveys',
    value: '/manageSurvey',
  },
  {
    label: 'navigation.generateReports',
    value: '/',
  },
];

export const Menu: React.FC = () => {
  const { t } = useTranslation();

  return (
    <MenuWrapper>
      {navigationsItems.map(({ label, value }) => (
        <li key={value}>
          <NavigationItem
            to={value}
            className={({ isActive }) => (isActive ? 'active' : '')}
          >
            {t(label)}
          </NavigationItem>
        </li>
      ))}
    </MenuWrapper>
  );
};

const MenuWrapper = styled.ul`
  list-style: none;
  padding: 0;
  margin: 0;
  margin-top: 35px;

  li {
    margin: 5px;
  }
`;

const NavigationItem = styled(NavLink)`
  text-decoration: none;
  color: white;
  height: 50px;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 40px;
  font-size: 20px;
  font-weight: 400;

  &.active {
    background: #00b6f1;
    color: #1c2328;
  }
`;
