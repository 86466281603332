import { Autocomplete, Button, Grid, TextField } from '@mui/material';
import { observer } from 'mobx-react-lite';
import { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import styled from 'styled-components';
import Company from '../../components/Company';
import NewCompanyForm from '../../components/Company/NewCompanyForm';
import { useStores } from '../../stores/hooks/useStores';

const ManageCompanies = () => {
  const { t } = useTranslation();
  const {
    companyStore: {
      companies,
      getCompanies,
      setSelectedCompany,
      selectedCompany,
      setNewCompanyForm,
    },
    questionTemplateStore: { clearAssignedTemplates },
  } = useStores();

  useEffect(() => {
    if (companies.length === 0) {
      getCompanies();
    }
    //eslint-disable-next-line react-hooks/exhaustive-deps
  }, [companies]);

  return (
    <PageWrapper>
      <Grid container spacing={2}>
        <Grid item xs={6}>
          <Autocomplete
            sx={{ width: '100%' }}
            options={companies}
            onChange={(event, newValue) => {
              if (newValue) {
                setNewCompanyForm(false);
                clearAssignedTemplates();
                setSelectedCompany(newValue);
              }
            }}
            renderInput={(params) => (
              <TextField {...params} label={t('company.selectCompany')} />
            )}
            getOptionLabel={(option) => option.name}
            value={selectedCompany}
          />
        </Grid>
        <Grid
          item
          xs={6}
          style={{
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'flex-start',
          }}
        >
          <StyledButton
            variant="contained"
            onClick={() => {
              setSelectedCompany(null);
              setNewCompanyForm(true);
            }}
          >
            {t('company.addCompany')}
          </StyledButton>
        </Grid>
        <Company />
      </Grid>
      <NewCompanyForm />
    </PageWrapper>
  );
};

export default observer(ManageCompanies);

const PageWrapper = styled.div`
  width: 1130px;
`;

const StyledButton = styled(Button)`
  margin-right: 20px;
`;
