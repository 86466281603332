import { Autocomplete, Button, TextField } from '@mui/material';
import { t } from 'i18next';
import { useEffect, useRef, useState } from 'react';
import { useReactToPrint } from 'react-to-print';
import styled from 'styled-components';
import AppendixPages from '../../components/Report/AppendixPages';
import { BoardReflectionsPages } from '../../components/Report/BoardReflectionsPages';
import { ContentPage } from '../../components/Report/ContentPage';
import { HistoricalComparisonPage } from '../../components/Report/HistoricalComparisonPage';
import { InitialPage } from '../../components/Report/InitialPage';
import { OpportunityLandscapePage } from '../../components/Report/OpportunityLandscapePage';
import { PerformanceSummaryPage } from '../../components/Report/PerformanceSummaryPage';
import { PerformanceVSImportancePage } from '../../components/Report/PerformanceVSImportancePage';
import { TerminologyPage } from '../../components/Report/TerminologyPage';
import { TopFivePrioritiesPage } from '../../components/Report/TopFivePrioritiesPage';
import { ReportDataPicker } from '../../components/ReportDataPicker';
import { AppendixDataProvider } from '../../context/AppendixContext';
import { PagePrintContext } from '../../context/PagePrintContext';
import { ReportDataProvider } from '../../context/ReportDataContext';
import { Period, Report, Survey } from '../../model';

const mockRows = [
  { name: 'terminology', pages: '3' },
  { name: 'performanceSummary', pages: '4' },
  { name: 'opportunityLandscape', pages: '5' },
  { name: 'performanceVSImportance', pages: '6' },
  { name: 'topFivePriorities', pages: '7' },
  { name: 'historicalComparison', pages: '8' },
  { name: 'reflections', pages: '9' },
  { name: 'appendix', pages: '14' },
];

export interface Client {
  name: string;
  logoUrl: string;
}

export interface ClientLogosProps {
  onClientSelection: (client: Client) => void;
}

const DEFAULT_LOGO_PATH = '/assets/images/logo-inverted.png';
const DEFAULT_CLIENT: Client = {
  name: 'Governance',
  logoUrl: DEFAULT_LOGO_PATH,
};
const CLIENTS: Client[] = [
  DEFAULT_CLIENT,
  {
    name: 'Blingdale',
    logoUrl: '/assets/images/logos/Blingdale.png',
  },
  {
    name: 'Quiddly',
    logoUrl: '/assets/images/logos/Quiddly.png',
  },
  {
    name: 'Catena',
    logoUrl: '/assets/images/logos/Catena.svg',
  },
  {
    name: 'Ronald McDonald Hus Lund',
    logoUrl: '/assets/images/logos/Ronald_McDonald_Hus.png',
  },
  {
    name: 'Saveggy',
    logoUrl: '/assets/images/logos/Saveggy.png',
  },
];

export const ClientLogos: React.FC<ClientLogosProps> = ({
  onClientSelection,
}) => {
  const [clients, setClients] = useState<Client[]>(CLIENTS);
  const clientNameRef = useRef<HTMLInputElement | null>(null);
  const clientLogoRef = useRef<HTMLInputElement | null>(null);

  const addClientLogo = () => {
    const clientName = clientNameRef.current?.value || '';
    const clientLogoFiles = clientLogoRef.current?.files;

    const logoUrl = clientLogoFiles
      ? URL.createObjectURL(clientLogoFiles[0])
      : DEFAULT_LOGO_PATH;

    const newClient = {
      name: clientName,
      logoUrl,
    };

    setClients([...clients, newClient]);
  };

  return (
    <>
      <div className="add-clients">
        <TextField inputRef={clientNameRef} label="Client Name" />
        <Button variant="contained" component="label" sx={{ margin: '10px' }}>
          {t('common.uploadLogo')}
          <input
            ref={clientLogoRef}
            hidden
            accept="image/*"
            multiple
            type="file"
          />
        </Button>
        <Button onClick={addClientLogo}>Add</Button>
      </div>
      <Autocomplete
        onChange={(event, value) => onClientSelection(value!)}
        sx={{ width: 300 }}
        options={clients}
        renderInput={(params) => <TextField {...params} label="Client" />}
        getOptionLabel={(option) => option.name}
      />
    </>
  );
};

export const GenerateReport: React.FC = () => {
  const componentRef = useRef(null);
  const [client] = useState<Client>(DEFAULT_CLIENT);

  const handlePrint = useReactToPrint({
    content: () => componentRef.current,
  });

  const [skippedPages, setSkippedPages] = useState<string[]>([]);
  const [numberOfSkippedPages, setNumberOfSkippedPages] = useState<number>(0);

  const [surveyValue, setSurveyValue] = useState<Survey | null>(null);
  const [periodValue, setPeriodValue] = useState<Period | null>(null);
  const [reportValue, setReportValue] = useState<Report | null>(null);

  const [isReportSelected, setIsReportSelected] = useState(false);

  useEffect(() => {
    if (surveyValue && periodValue && reportValue) {
      setIsReportSelected(true);
    } else {
      setIsReportSelected(false);
    }
  }, [surveyValue, periodValue, reportValue]);

  return (
    <SurveyWrapper>
      <ReportDataProvider>
        <AppendixDataProvider>
          <div>
            <ReportDataPicker
              handlePrint={handlePrint}
              surveyValue={surveyValue}
              setSurveyValue={setSurveyValue}
              periodValue={periodValue}
              setPeriodValue={setPeriodValue}
              reportValue={reportValue}
              setReportValue={setReportValue}
            />
          </div>
          <div ref={componentRef}>
            {isReportSelected && (
              <>
                <InitialPage client={client} />
                <PagePrintContext.Provider
                  value={{
                    skippedPages,
                    setSkippedPages,
                    numberOfSkippedPages,
                    setNumberOfSkippedPages,
                  }}
                >
                  <ContentPage rows={mockRows} />
                  <TerminologyPage />
                  <PerformanceSummaryPage />
                  <OpportunityLandscapePage />
                  <PerformanceVSImportancePage />
                  <TopFivePrioritiesPage />
                  <HistoricalComparisonPage />
                  <BoardReflectionsPages />
                  <AppendixPages />
                </PagePrintContext.Provider>
              </>
            )}
          </div>
        </AppendixDataProvider>
      </ReportDataProvider>
    </SurveyWrapper>
  );
};

const SurveyWrapper = styled.div`
  width: 1130px;

  > div {
    margin-bottom: 20px;
  }
`;
