import { t } from 'i18next';
import { useContext } from 'react';
import styled from 'styled-components';
import { PagePrintContext } from '../../context/PagePrintContext';
import { CircleNumber } from '../CircleNumber';
import { PrintPage } from '../PrintPage/PrintPage';
import { SortedList } from '../SortedList';
import { Text } from '../Text';
import { TwoPartLayout } from '../TwoPartLayout';
import { Content } from './components/Content';
import { Header } from './components/Header';
import { AppendixDataContext } from '../../context/AppendixContext';
import { TextArea } from '../TextArea';

export const PerformanceSummaryPage = () => {
  const { skippedPages } = useContext(PagePrintContext);

  const {
    state: { total },
  } = useContext(AppendixDataContext);

  const {
    state: { extractedCompareData },
  } = useContext(AppendixDataContext);

  const sortedArray =
    extractedCompareData &&
    extractedCompareData.slice(1).sort((a, b) => {
      const aHowWell =
        a.subheadings.reduce((sum, item) => sum + parseInt(item.howWell), 0) /
        a.subheadings.length;
      const bHowWell =
        b.subheadings.reduce((sum, item) => sum + parseInt(item.howWell), 0) /
        b.subheadings.length;

      return bHowWell - aHowWell;
    });

  const topPerspectives = sortedArray.slice(0, 3).map((item) => {
    const heading = item.heading.replace(/^\d+\.\s*/, ''); // remove numeration from heading
    return heading;
  });

  const topStatements = () => {
    const subheadings: { [key: string]: number } = {};
    sortedArray.forEach((heading) => {
      heading.subheadings.forEach((subheading) => {
        const { howWell } = subheading;
        subheadings[subheading[Object.keys(subheading)[0]]] = Number(howWell);
      });
    });
    return Object.keys(subheadings)
      .sort((a, b) => subheadings[b] - subheadings[a])
      .slice(0, 3);
  };

  const FirstPart = (
    <IndexWrapper>
      <CircleWrapper>
        <CircleNumber value={total.howWell || 0} size="xxl" />
        <CircleText>{t('reports.totalPerformanceIndex')}</CircleText>
      </CircleWrapper>

      <LeftColumnText>
        <Text
          size="l"
          value={t('reports.totalPerformanceIndexDescriptionFirstPart')}
        />
        <Text
          size="l"
          value={t('reports.totalPerformanceIndexDescriptionSecondPart')}
        />
      </LeftColumnText>
    </IndexWrapper>
  );

  const SecondPart = (
    <>
      <SortedList
        heading={t('reports.perspectiveWithHighestPerformance')}
        listItems={topPerspectives}
      />

      <Separator />

      <SortedList
        heading={t('reports.statementsWithHighestPerformance')}
        listItems={topStatements()}
      />
      <Separator />

      <Heading>{t('reports.summaryOfResults')} </Heading>
      <TextArea backgroundColor={false} fontSize="l" />
    </>
  );

  return (
    <PrintPage
      className={
        skippedPages.includes(t('reports.performanceSummary')) ? 'no-print' : ''
      }
    >
      <Header title={t('reports.performanceSummary')} resultGroup={1} />

      <Content>
        <TwoPartLayout
          FirstPart={FirstPart}
          SecondPart={SecondPart}
          showSeparator
        ></TwoPartLayout>
      </Content>
    </PrintPage>
  );
};

const CircleWrapper = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
`;

const CircleText = styled.div`
  color: #1c2328;
  font-size: 20px;
  font-weight: 600;
  margin-top: 10px;
`;

const LeftColumnText = styled.div`
  margin-top: 60px;
`;
const Separator = styled.div`
  margin-bottom: 30px;
`;

const IndexWrapper = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  height: 100%;
`;

const Heading = styled.div`
  color: #1f4b58;
  font-weight: 600;
  font-size: 20px;
  margin-bottom: 20px;
`;
