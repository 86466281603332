import axios from 'axios';
import { BaseApiResponse } from '../shared/base-api-response';
import {
  Compare2Component,
  CompareComponent,
  CompareData,
} from './model/compare-report-component';
import {
  OpportunityLandscapeComponent,
  OpportunityLandscapeData,
} from './model/opportunity-landscape-component';
import { ReportType } from './model/report-type';
import { Top5Data, Top5ReportComponent } from './model/top5-report-component';

interface ReportComponentApiResponse extends BaseApiResponse {
  result: {
    report: ReportType;
    period2?: string;
    origo?: string;
  };
}

interface ReportComponentParameters {
  clientId: string;
  reportComponent: string;
  abortController: AbortController;
  clientPeriodId: string;
  startDate: Date;
  endDate: Date;
  language: 'en' | 'se';
}

export function getComponentData({
  clientId,
  reportComponent,
  abortController,
  clientPeriodId,
  startDate,
  endDate,
  language,
}: ReportComponentParameters): Promise<
  | OpportunityLandscapeComponent
  | Top5ReportComponent
  | CompareComponent
  | Compare2Component
> {
  return axios
    .get<ReportComponentApiResponse>(
      `reportdata/getfile/${clientId}/${reportComponent}/component`,
      {
        signal: abortController.signal,
      },
    )
    .then((response) => response.data.result)
    .then(({ report, origo, period2, ...rest }) =>
      axios
        .post<
          BaseApiResponse & {
            result: OpportunityLandscapeData | Top5Data[] | CompareData;
          }
        >(`admin_report`, {
          report,
          period2,
          origo,
          language,
          code: clientId,
          period: clientPeriodId,
          // make date format yyyy-mm-dd
          date1: startDate.toLocaleDateString('fr-CA'),
          date2: endDate.toLocaleDateString('fr-CA'),
          width: 360,
          ...rest,
        })
        .then((response) => response.data.result)
        .then(
          (data) =>
            ({ data: data, type: report } as
              | OpportunityLandscapeComponent
              | Top5ReportComponent
              | CompareComponent
              | Compare2Component),
        ),
    );
}
