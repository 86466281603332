import styled from 'styled-components';

interface ITwoPartLayoutProps {
  FirstPart: React.ReactNode;
  SecondPart: React.ReactNode;
  showSeparator?: boolean;
  padding?: string;
}

export const TwoPartLayout: React.FC<ITwoPartLayoutProps> = ({
  FirstPart,
  SecondPart,
  showSeparator = false,
  padding = '60px',
}) => {
  return (
    <TwoPartLayoutWrapper showSeparator={showSeparator} padding={padding}>
      <Section>{FirstPart}</Section> <Section>{SecondPart}</Section>
    </TwoPartLayoutWrapper>
  );
};

const Section = styled.div`
  width: 50%;
`;

const TwoPartLayoutWrapper = styled.div<
  Pick<ITwoPartLayoutProps, 'showSeparator' | 'padding'>
>`
  display: flex;

  ${Section} {
    &:first-child {
      padding-right: ${({ padding }) => padding};
    }

    &:last-child {
      border-left: ${({ showSeparator }) =>
        showSeparator ? '1px solid #E0E0E0' : '0'};
      padding-left: ${({ padding }) => padding};
    }
  }
`;
