import { t } from 'i18next';
import { PrintPage } from '../PrintPage/PrintPage';
import { Content } from './components/Content';
import { Header } from './components/Header';
import { useContext } from 'react';
import { PagePrintContext } from '../../context/PagePrintContext';
import { AppendixTable } from '../AppendixTable';
import { ExtractDataType } from '../../utils/dataExtractFunctions';
import { TextArea } from '../TextArea';

interface AppendixPageProps {
  tableData: ExtractDataType;
  pageKey?: number;
}
export const AppendixPage = ({ tableData, pageKey }: AppendixPageProps) => {
  const { skippedPages } = useContext(PagePrintContext);

  return (
    <PrintPage
      className={
        skippedPages.includes(`${t('reports.appendix')} ${pageKey ?? ''}`)
          ? 'no-print'
          : ''
      }
    >
      <Header title={t('reports.appendix')} groupPageNum={pageKey} />
      <Content>
        {tableData && <AppendixTable tableData={tableData} />}
        <TextArea />
      </Content>
    </PrintPage>
  );
};
