import { makeAutoObservable } from 'mobx';
import { toast } from 'react-toastify';
import {
  getCompanies,
  getTemplatesByCompany,
  updateLogo,
} from '../api/CompanyAPI';
import { ICompany } from '../providers/auth';
import { t } from 'i18next';

export class CompanyStore {
  public companies: ICompany[] = [];
  public selectedCompany: ICompany | null = null;
  public newCompanyForm: boolean = false;

  constructor() {
    makeAutoObservable(this);
  }

  private setCompanies = (companies: ICompany[]) => {
    this.companies = companies;
  };

  public setSelectedCompany = (company: ICompany | null) => {
    this.selectedCompany = company;
  };

  public setNewCompanyForm = (value: boolean) => {
    this.newCompanyForm = value;
  };

  public getTemplatesByCompany = async () => {
    if (!this.selectedCompany) {
      return null;
    }

    try {
      await getTemplatesByCompany(this.selectedCompany.companyId);
    } catch (error) {
      console.log(error);
    }
  };

  public getCompanies = async () => {
    try {
      const companies = await getCompanies();

      this.setCompanies(companies);
    } catch (error) {
      toast.error(t('common.somethingWentWrong'));
    }
  };

  public updateLogo = async (logo: File) => {
    if (!this.selectedCompany) return null;

    try {
      await updateLogo(this.selectedCompany?.companyId, logo);

      const currentCompany = this.selectedCompany;

      await this.getCompanies();

      const updatedCompany = this.companies.find(
        (company) => company.companyId === currentCompany.companyId,
      );

      if (updatedCompany) {
        this.setSelectedCompany(updatedCompany);
      }

      toast.success(t('common.logoUpdated'));
    } catch (error: any) {
      if (error.response.data.message.includes('already exists')) {
        toast.error(t('common.logoAlreadyExists'));
      } else {
        toast.error(t('common.somethingWentWrong'));
      }
    }
  };
}
