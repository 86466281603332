import styled from 'styled-components';

export const GreyBackgroundWrapper: React.FC = ({ children }) => {
  return <ContentWrapper>{children}</ContentWrapper>;
};

const ContentWrapper = styled.div`
  width: 100%;
  position: relative;
  display: flex;
  align-items: baseline;
  flex-direction: column;
  background: #eeeeee;
  border-radius: 4px;
  padding: 20px;
  margin-bottom: 10px;
`;
