import axios from 'axios';
import { useEffect, useState } from 'react';
import { DatesApiResponse } from './model/dates-api-response';

interface DateRange {
  startDate: Date;
  endDate: Date;
}

export const useDates = (
  clientId: string | null,
  periodId: string | null,
): [Date | null, Date | null] => {
  const [dates, setDates] = useState<DateRange | null>(null);

  useEffect(() => {
    const abortController = new AbortController();

    if (clientId && periodId) {
      axios
        .get<DatesApiResponse>(`/reportdata/dates/${clientId}/${periodId}`, {
          signal: abortController.signal,
        })
        .then((response) => response.data.result)
        .then((dates) =>
          setDates({
            startDate: new Date(dates[0]),
            endDate: new Date(dates[1]),
          }),
        );
    }

    return () => abortController.abort();
  }, [clientId, periodId]);

  return [dates?.startDate || null, dates?.endDate || null];
};
