import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  FormControl,
  InputLabel,
  MenuItem,
  Select,
} from '@mui/material';
import { FC, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import styled from 'styled-components';
import { COUNTRIES } from './RenderFlag';
import { t } from 'i18next';

interface ISelectLanguageDialog {
  isDialogOpen: boolean;
  reservedLanguages: string[];
  setIsDialogOpen: (isOpen: boolean) => void;
  selectedPeriod: string;
}

const SelectLanguageDialog: FC<ISelectLanguageDialog> = ({
  reservedLanguages,
  isDialogOpen = false,
  selectedPeriod,
  setIsDialogOpen,
}) => {
  const navigate = useNavigate();
  const { surveyId } = useParams();

  const countries = COUNTRIES.filter(
    (country) => !reservedLanguages?.includes(country.value.toLowerCase()),
  );

  const [language, setLanguage] = useState('');

  const handleLanguageSelect = () => {
    navigate(
      `/manageSurvey/${surveyId}/managePeriods/${selectedPeriod}/language/${language}`,
    );
  };

  return (
    <Dialog open={isDialogOpen} fullWidth maxWidth="xs">
      <DialogTitle>{t('common.selectLanguage')}</DialogTitle>
      <Content>
        <FormControl fullWidth>
          <InputLabel>{t('common.language')}</InputLabel>
          <Select
            value={language}
            onChange={(e) => setLanguage(e.target.value)}
            label="Language"
          >
            {countries.map((country) => (
              <MenuItem key={country.value} value={country.value}>
                {country.label}
              </MenuItem>
            ))}
          </Select>
        </FormControl>
      </Content>
      <DialogActions>
        <Button onClick={handleLanguageSelect} color="primary">
          {t('common.select')}
        </Button>

        <Button onClick={() => setIsDialogOpen(false)} color="secondary">
          {t('common.cancel')}
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default SelectLanguageDialog;

const Content = styled(DialogContent)`
  padding-top: 6px !important;
`;
