import i18next from 'i18next';
import { initReactI18next } from 'react-i18next';
import en from './translations/en.json';
import sv from './translations/sv.json';
import { getDefaultLanguage } from './utils/LocalStorage';

const resources = {
  en: {
    translation: en,
  },
  sv: {
    translation: sv,
  },
};

const language = getDefaultLanguage();

i18next.use(initReactI18next).init({
  resources,
  lng: language,
  interpolation: {
    escapeValue: false,
  },
});

export default i18next;
