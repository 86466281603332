import axios from 'axios';

export const useUnauthAxios = () => {
  const unauthenticatedAxiosInstance = axios.create({
    baseURL: process.env.REACT_APP_API_URL!,
    headers: {
      Authorization: null,
    },
  });

  return unauthenticatedAxiosInstance;
};
