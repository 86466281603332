import { Button, Grid, TextField } from '@mui/material';
import { ChangeEvent } from 'react';
import { useStores } from '../../stores/hooks/useStores';
import { t } from 'i18next';

export const UploadLogo = () => {
  const {
    companyStore: { updateLogo },
  } = useStores();

  const handleFileChange = (event: ChangeEvent<HTMLInputElement>) => {
    const file = event.target.files && event.target.files[0];

    if (
      file &&
      (file.type === 'image/png' ||
        file.type === 'image/jpeg' ||
        file.type === 'image/jpg')
    ) {
      updateLogo(file);
    } else {
      alert('Please select a valid PNG, JPG, or JPEG image.');
    }
  };

  return (
    <Grid item xs={6}>
      <Button
        onClick={() => {
          const filesElem = document.getElementById('files');
          if (filesElem) {
            filesElem.click();
          }
        }}
        variant="contained"
        color="primary"
      >
        {t('common.uploadLogo')}
      </Button>
      <TextField
        id="files"
        type="file"
        variant="outlined"
        style={{
          display: 'none',
        }}
        inputProps={{ accept: 'image/png, image/jpeg, image/jpg' }}
        onChange={handleFileChange}
      />
    </Grid>
  );
};
