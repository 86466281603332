import { createContext, Dispatch, SetStateAction } from 'react';

type ContextProps = {
  skippedPages: string[];
  setSkippedPages: Dispatch<SetStateAction<string[]>>;
  numberOfSkippedPages: number;
  setNumberOfSkippedPages: Dispatch<SetStateAction<number>>;
};

const defaultValue: ContextProps = {
  skippedPages: [''],
  setSkippedPages: () => {},
  numberOfSkippedPages: 0,
  setNumberOfSkippedPages: () => {},
};

export const PagePrintContext = createContext<ContextProps>(defaultValue);
