import {
  Box,
  Button,
  Checkbox,
  FormControlLabel,
  Grid,
  TextField,
  Typography,
} from '@mui/material';
import axios from 'axios';
import { useFormik } from 'formik';
import { useParams } from 'react-router-dom';
import { toast } from 'react-toastify';
import { getSelectedCompanyFromLocalStorage } from '../../utils/LocalStorage';
import { t } from 'i18next';

export const InviteSingle = () => {
  const { surveyId, periodId } = useParams();
  const selectedCompany = getSelectedCompanyFromLocalStorage();

  const formik = useFormik({
    initialValues: {
      subject: '',
      body: `
      Dear board collegue,
      <br />
      As a member of the board at ${selectedCompany?.name}, you are invited to our annual Board Evaluation.
      <br />
      You can access the evaluation by following the link below. The evaluation takes approximately 10 minutes and needs to be completed by deadline.
      <br />
      Here is the link to the Board Evaluation: {url}
      <br />
      If you have any questions or notice any issues you are welcome to contact Jonathan Kalma: jonathan.kalma@kunskapspartner.se from Kunskapspartner, who is administrating the evaluation.
      <br />
      Thank you for your participation.
  `,
      smsbody: '',
      csvFiles: '',
      respondent_name: '',
      respondent_email: '',
      respondent_mobile: '',
      respondent_contactname: '',
      respondent_contactemail: '',
      respondent_extraemail: '',
      respondent_anonymous: false,
      respondent_project: '',
      respondent_answers: '',
    },
    onSubmit: async (values: any) => {
      try {
        await axios.post('/createRespondent ', {
          mode: 'save',
          respondent_type: '1',
          respondents_filetype: 'ISO-8859-1',
          number: '',
          code: `${surveyId}_${periodId}`, //code_periodId
          language: 'en',
          object: 'respondents',
          id: '',
          companyId: selectedCompany?.companyId,
          invite: 'true',
          new: '',
          ...values,
        });

        toast.success(t('common.invitationSent'));
      } catch (error) {
        toast.error(t('common.somethingWentWrong'));
      } finally {
        formik.resetForm();
      }
    },
  });

  return (
    <form onSubmit={formik.handleSubmit}>
      <Grid container spacing={2}>
        <Grid item xs={12}>
          <TextField
            label={t('common.emailSubject')}
            variant="outlined"
            fullWidth
            required
            {...formik.getFieldProps('subject')}
          />
        </Grid>
        <Grid item xs={12}>
          <TextField
            label={t('common.emailBody')}
            multiline
            rows={7}
            variant="outlined"
            fullWidth
            required
            {...formik.getFieldProps('body')}
          />
        </Grid>

        <Grid item xs={12}>
          <Typography variant="body1">
            Invite the following respondent with e-mail
          </Typography>
        </Grid>
        <Grid item xs={6}>
          <TextField
            label="Name"
            variant="outlined"
            fullWidth
            required
            {...formik.getFieldProps('respondent_name')}
          />
        </Grid>
        <Grid item xs={6}>
          <TextField
            label="Email address"
            variant="outlined"
            fullWidth
            required
            {...formik.getFieldProps('respondent_email')}
          />
        </Grid>
        <Grid item xs={6}>
          <TextField
            label="SMS number"
            variant="outlined"
            fullWidth
            {...formik.getFieldProps('respondent_mobile')}
          />
        </Grid>
        <Grid item xs={6}>
          <TextField
            label="Contact name"
            variant="outlined"
            fullWidth
            {...formik.getFieldProps('respondent_contactname')}
          />
        </Grid>
        <Grid item xs={6}>
          <TextField
            label="Contact email"
            variant="outlined"
            fullWidth
            {...formik.getFieldProps('respondent_contactemail')}
          />
        </Grid>
        <Grid item xs={6}>
          <TextField
            label="CC-addresses for information mail separated with comma"
            variant="outlined"
            fullWidth
            {...formik.getFieldProps('respondent_extraemail')}
          />
        </Grid>
        <Grid item xs={12}>
          <FormControlLabel
            control={
              <Checkbox
                name="respondent_anonymous"
                color="primary"
                checked={formik.values.respondent_anonymous}
                onChange={formik.handleChange}
              />
            }
            label="Anonymous answer is allowed"
          />
        </Grid>
        <Grid item xs={6}>
          <TextField
            label="Project"
            variant="outlined"
            fullWidth
            {...formik.getFieldProps('respondent_project')}
          />
        </Grid>
        <Grid item xs={6}>
          <TextField
            label="Answer to qualification separated with comma"
            variant="outlined"
            fullWidth
            {...formik.getFieldProps('respondent_answers')}
          />
        </Grid>
        <Grid item xs={12}>
          <Box display="flex" justifyContent="flex-end">
            <Button type="submit" variant="contained" color="primary">
              {t('common.sendInvite')}
            </Button>
          </Box>
        </Grid>
      </Grid>
    </form>
  );
};
