export const COMPANY_ENDPOINTS = (companyId?: string) => {
  return {
    CREATE_COMPANY: 'company/createcomapany',
    GET_COMPANY: `company/getcompany/${companyId}`,
    GET_COMPANIES: `company/getcompanies`,
    ADD_LOGO: 'company/addlogo',
    ADD_USER_TO_COMPANY: 'company/usertocompany',
    GET_TEMPLATES_BY_COMPANY: (companyId: string) =>
      `template/getTempaltesByCompany/${companyId}`,
  };
};
