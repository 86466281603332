import TextField, { TextFieldProps } from '@mui/material/TextField';
import { Button, CircularProgress, Link } from '@mui/material';
import styled from 'styled-components';
import React, { BaseSyntheticEvent, PropsWithChildren, useState } from 'react';
import { Logo } from '../../components/Logo/Logo';
import { useTranslation } from 'react-i18next';
import { useLogin } from '../../providers/auth';
import { useAuth } from '../../providers/auth';

export const Login: React.FC = () => {
  const [t] = useTranslation();
  const [hasError, setHasError] = useState(false);
  const { loading } = useAuth();
  const login = useLogin();

  const onLogin = (username: string, password: string) => {
    login(username, password).catch(() => {
      setHasError(true);
    });
  };

  const onSubmit = (event: BaseSyntheticEvent) => {
    event.preventDefault();
    const username = event.target.elements.username.value;
    const password = event.target.elements.password.value;

    if (!username || !password) {
      setHasError(true);
    } else {
      setHasError(false);
      onLogin(username, password);
    }
  };

  return (
    <LoginLayout>
      <LoginFormContainer>
        <LoginFormAndLogoSpacer>
          <Logo size="medium" />
          <LoginForm onSubmit={onSubmit}>
            <h2>{t('login.title')}</h2>

            <LoginFormField
              name="username"
              label={t('login.companyCode.placeholder')}
              variant="outlined"
              error={!!hasError}
            >
              {t('login.companyCode.label')}
            </LoginFormField>

            <LoginFormField
              name="password"
              label={t('login.password.placeholder')}
              variant="outlined"
              error={!!hasError}
              type="password"
            >
              {t('login.password.label')}
            </LoginFormField>

            <ErrorText>{hasError ? t('login.badCredentials') : ''}</ErrorText>

            <LoginFormButton
              type="submit"
              variant="contained"
              disabled={loading}
            >
              {loading ? <CircularProgress /> : t('login.buttonText')}
            </LoginFormButton>

            <ForgotPasswordLink href="#" color="inherit">
              {t('login.forgotPasswordLink')}
            </ForgotPasswordLink>
          </LoginForm>
        </LoginFormAndLogoSpacer>
      </LoginFormContainer>
      <LoginImage />
    </LoginLayout>
  );
};

const LoginLayout = styled.div`
  display: flex;
  flex-flow: row nowrap;
  min-height: 100vh;

  > * {
    width: 50%;
  }

  @media (max-width: 1075px) {
    > *:nth-child(1) {
      width: 100%;
    }
    > *:nth-child(2) {
      display: none;
    }
  }
`;

const LoginFormContainer = styled.div`
  display: inline-flex;
  flex-flow: column nowrap;
  align-items: center;
  justify-content: center;

  // TODO: move to theme variable as primary
  background-color: #1f4b58;
`;

const LoginFormAndLogoSpacer = styled.div`
  display: grid;
  row-gap: 40px;
`;

const LoginImage = styled.div`
  background-image: url('/assets/images/login-image.png');
`;

const FormField: React.FC<PropsWithChildren<TextFieldProps>> = ({
  children,
  className,
  ...props
}) => {
  return (
    <label className={className}>
      <div className="label-text">{children}</div>
      <TextField sx={{ backgroundColor: '#fff' }} {...props} />
    </label>
  );
};

const LoginFormField = styled(FormField)`
  display: flex;
  flex-flow: column nowrap;

  .label-text {
    margin-bottom: 20px;
    font-size: 18px;
  }
`;

const LoginFormButton = styled(Button)`
  padding: 20px 10px;
  // TODO: use theme variable
  background-color: #1f4b58;
`;

const ForgotPasswordLink = styled(Link)`
  display: flex;
  justify-content: center;

  font-size: 18px;
  line-height: 21px;
`;

const LoginForm = styled.form`
  display: flex;
  flex-flow: column nowrap;

  border-radius: 12px;
  width: 480px;
  padding: 40px;

  background-color: #eeeeee;

  h2 {
    margin: 0 0 20px;

    font-family: Roboto, sans-serif;
    font-size: 28px;
    font-weight: 600;
    color: #1c2328;
  }

  ${LoginFormField} {
    margin-bottom: 20px;
  }

  ${LoginFormButton} {
    margin-top: 30px;
  }

  ${ForgotPasswordLink} {
    margin-top: 20px;
  }
`;

const ErrorText = styled.div`
  // TODO: use theme variable
  color: red;
  font-size: 18px;
  height: 18px;
`;
