import axios from 'axios';
import { BaseApiResponse } from '../shared/base-api-response';
import { ReportDataSegments } from './model/report-data-segments';

interface ReportPagesApiResponse {
  pageNumber: number;
  page: ReportPage[];
}

interface ReportPage {
  type: string;
  value: string;
}

function parseReportData(
  reportDataResponse: ReportPagesApiResponse[],
): ReportDataSegments {
  const allPages = reportDataResponse.reduce(
    (allPages, currentPage) => [...allPages, ...currentPage.page],
    [] as ReportPage[],
  );

  const parsedResponse = {
    title: allPages.find((page) => page.type === 'h1')?.value || '',
    subTitle: allPages.filter((page) => page.type === 'h1')[1].value || '',
    components: allPages
      .filter((reportSegment) => reportSegment.type === 'component')
      .map((segment) => segment.value),
  };

  return parsedResponse;
}

export function fetchReportData(
  clientId: string,
  reportName: string,
  abortController: AbortController,
): Promise<ReportDataSegments> {
  return axios
    .get<BaseApiResponse & { result: ReportPagesApiResponse[] }>(
      `reportdata/getfile/${clientId}/${reportName.replace(
        'report#',
        '',
      )}/report`,
      {
        signal: abortController.signal,
      },
    )
    .then((reportDataResponse) =>
      parseReportData(reportDataResponse.data.result),
    );
}
