import FusionCharts from 'fusioncharts';
import charts from 'fusioncharts/fusioncharts.charts';
import { FC } from 'react';
import ReactFusioncharts from 'react-fusioncharts';
import { useTranslation } from 'react-i18next';
import styled from 'styled-components';
import { IDataSet } from '../../../api/reports';
import { CircleNumber } from '../../CircleNumber';
import { Text } from '../../Text';
import {
  findDataSetWithSmallestValue,
  getBGImagePerAxisValue,
  getChartAxisValue,
  lowerTennerValue,
} from './ChartUtils';

// Resolves charts dependancy
charts(FusionCharts);

const scatterChartData = {
  chart: {
    xaxisname: 'Importance index',
    yaxisname: 'Performance index',

    theme: 'fusion',
    yAxisLineColor: '#1c2328',
    xAxisLineColor: '#1c2328',
    showXAxisLine: '1',
    showYAxisLine: '1',
    lineThickness: '1',
    showlegend: '0',

    xaxisminvalue: '0',
    xaxismaxvalue: '100',
    yaxisminvalue: '0',
    yaxismaxvalue: '100',

    anchorSides: '0',
    anchorRadius: '12',
    anchorBgColor: '#1F4B58',
    anchorBorderThickness: '0',
    anchorImageScale: '20',

    chartTopMargin: '10',
    chartRightMargin: '12',
    bgImage: '/assets/scatterChart/scatterChartBg.png',
    bgImageVAlign: 'top',
    bgImageHAlign: 'right',

    divLineAlpha: '0',
  },
};

export const ScatterChart: FC<{ dataset: IDataSet[] }> = ({ dataset }) => {
  const { t } = useTranslation();
  const smallestDataSet = findDataSetWithSmallestValue(dataset);
  const axisValues = getChartAxisValue(
    lowerTennerValue(Number(smallestDataSet)),
  );

  const chartData = {
    ...scatterChartData,
    chart: {
      ...scatterChartData.chart,
      xaxisname: t('reports.importanceIndex'),
      yaxisname: t('reports.performanceIndex'),
      xaxisminvalue: String(axisValues),
      yaxisminvalue: String(axisValues),
      bgImage: getBGImagePerAxisValue(axisValues),
    },
    dataset,
  };

  return (
    <Chart>
      <ReactFusioncharts
        type="scatter"
        width="100%"
        height="80%"
        dataFormat="JSON"
        dataSource={chartData}
        key={`scatter-chart${Math.random()}`}
      />

      <LegendWrapper>
        <LegendInfoWrapper>
          <CircleNumber value="" color="green" size="l" />
          <Text value={t('reports.noIssues')} isBold />
        </LegendInfoWrapper>
        <LegendInfoWrapper>
          <CircleNumber value="" color="yellow" size="l" />
          <Text value={t('reports.someRoomForImprovement')} isBold />
        </LegendInfoWrapper>
        <LegendInfoWrapper>
          <CircleNumber value="" color="red" size="l" />
          <Text value={t('reports.largeRoomForImprovement')} isBold />
        </LegendInfoWrapper>
      </LegendWrapper>
      <List>
        {dataset.map((dataSetItem, index) => (
          <li key={index}>{dataSetItem.seriesName}</li>
        ))}
      </List>
    </Chart>
  );
};

const Chart = styled.div`
  position: relative;
  display: flex;
  flex-direction: column;
  height: 500px;
  width: 600px;
`;

const List = styled.ol`
  column-count: 3;
  font-size: 14px;
  list-style: none;
  margin-left: auto;
  margin-right: auto;
`;

const LegendInfoWrapper = styled.div`
  display: flex;
  flex-direction: column;
  margin-bottom: 20px;
  align-items: center;
  gap: 20px;

  div {
    margin-bottom: 0;
  }
`;

const LegendWrapper = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: right;
  gap: 30px;
`;
