import axios from 'axios';
import { ICompany } from '../providers/auth';
import { COMPANY_ENDPOINTS } from './constants';

export const getCompanies = async () => {
  try {
    const { data } = await axios.get(COMPANY_ENDPOINTS().GET_COMPANIES);

    return data.result as ICompany[];
  } catch (e) {
    throw e;
  }
};

export const getTemplatesByCompany = async (companyId: string) => {
  try {
    const { data } = await axios.get(
      COMPANY_ENDPOINTS().GET_TEMPLATES_BY_COMPANY(companyId),
    );

    return data.result;
  } catch (e) {
    throw e;
  }
};

export const updateLogo = async (companyId: string, logo: File) => {
  try {
    const formData = new FormData();
    formData.append('companyId', companyId);
    formData.append('logo', logo);

    return await axios.post(COMPANY_ENDPOINTS().ADD_LOGO, formData, {
      headers: {
        'Content-Type': 'multipart/form-data',
      },
    });
  } catch (error) {
    throw error;
  }
};

interface ICreateCompany {
  name: string;
  email: string;
  type: string;
  industry: string;
  fromInvestmentCompany: string;
  userId: string;
}

export const createCompany = async (company: ICreateCompany) => {
  try {
    const {
      data: {
        result: { companyId },
      },
    } = await axios.post(COMPANY_ENDPOINTS().CREATE_COMPANY, company);

    // Check if userId is already in the hardcoded list; if not, add it

    // Prepare the list of user IDs to add to the new company, avoiding duplicates.
    const hardcodedUserIds = [
      '5c13aa906dcb6', // Olof Robertsson (OR Konsult)
      '5caef021ef4d1', // Carl-henric Nilsson
      '628e00300da98', // Tim (Northprim)
      '632c198a0d146', // Zdenko (Northprim)
      '6344075a8f0a9', // Nikola (Northprim)
      '634407735ea5d', // Predrag (Northprim)
      '662a32d4d9dbb', // Givi Kokaia
      '65b3b0c5d8841', // Jonathan Kalma
    ];
    const usersToAdd = hardcodedUserIds.includes(company.userId)
      ? hardcodedUserIds
      : [...hardcodedUserIds, company.userId];
    await axios.post(COMPANY_ENDPOINTS().ADD_USER_TO_COMPANY, {
      companyId,
      users: usersToAdd,
    });
  } catch (e) {
    throw e;
  }
};
