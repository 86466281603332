import { useContext } from 'react';
import { ReportDataContext } from '../../context/ReportDataContext';
import { BoardReflectionsPage } from './BoardReflectionsPage';
import { CommentsData } from '../../api/reports/model/comments-response';

export const BoardReflectionsPages = () => {
  const {
    state: { comments },
  } = useContext(ReportDataContext);

  return (
    <>
      {comments.map((comments: CommentsData, index: number) => {
        return (
          <BoardReflectionsPage
            key={index}
            comments={comments}
            pageKey={index + 1}
          />
        );
      })}
    </>
  );
};
