import styled from 'styled-components';

interface ICircleNumberProps {
  value: number | string;
  pushRight?: boolean;
  color?: 'blue' | 'orange' | 'green' | 'yellow' | 'red';
  size?: 's' | 'm' | 'l' | 'xl' | 'xxl';
}

export const CircleNumber: React.FC<ICircleNumberProps> = ({
  value,
  pushRight,
  color = 'orange',
  size = 'm',
}) => {
  return (
    <Circle pushRight={pushRight} color={color} size={size}>
      {value}
    </Circle>
  );
};

const Circle = styled.span<{
  pushRight: boolean | undefined;
  color: 'blue' | 'orange' | 'green' | 'yellow' | 'red';
  size: 's' | 'm' | 'l' | 'xl' | 'xxl';
}>`
  border-radius: 100%;
  display: inline-flex;
  justify-content: center;
  align-items: center;
  color: white;
  font-size: 29px;
  font-weight: 500;
  margin-right: ${({ pushRight }) => (pushRight ? '18px' : '0')};
  background: ${({ color }) =>
    color === 'blue'
      ? '#00b6f1'
      : color === 'orange'
      ? '#F47B30'
      : color === 'green'
      ? '#6aa84fff'
      : color === 'red'
      ? '#cc4125ff'
      : '#f1c232ff'};

  height: ${({ size }) =>
    size === 's'
      ? '18px'
      : size === 'm'
      ? '30px '
      : size === 'l'
      ? '42px'
      : size === 'xl'
      ? '95px'
      : '130px'};
  width: ${({ size }) =>
    size === 's'
      ? '18px'
      : size === 'm'
      ? '30px '
      : size === 'l'
      ? '42px'
      : size === 'xl'
      ? '95px'
      : '130px'};
  font-size: ${({ size }) =>
    size === 's'
      ? '10px'
      : size === 'm'
      ? '19px '
      : size === 'l'
      ? '23px'
      : '43px'};
  min-height: ${({ size }) =>
    size === 's'
      ? '18px'
      : size === 'm'
      ? '30px '
      : size === 'l'
      ? '42px'
      : size === 'xl'
      ? '95px'
      : '130px'};
  min-width: ${({ size }) =>
    size === 's'
      ? '18px'
      : size === 'm'
      ? '30px '
      : size === 'l'
      ? '42px'
      : size === 'xl'
      ? '95px'
      : '130px'};
`;
