import axios from 'axios';
import { useEffect, useState } from 'react';
import { Survey } from '../../model';
import { getSelectedCompanyFromLocalStorage } from '../../utils/LocalStorage';
import { SurveysApiResponse } from './model/surveys-api-response';

export const useSurveys = (): {
  surveys: Survey[];
  refetchSurveys: () => void;
} => {
  const [surveys, setSurveys] = useState<Survey[]>([]);
  const selectedCompany = getSelectedCompanyFromLocalStorage();

  const getSurveys = () =>
    axios
      .get<SurveysApiResponse>(
        `/reportdata/codes/page/${selectedCompany?.companyId}`,
      )
      .then((response) => response.data.result)
      .then((categories: Record<string, Record<string, string>>): Survey[] =>
        Object.keys(categories).reduce((result, groupName) => {
          const surveyCategory = categories[groupName];
          const survey = Object.keys(surveyCategory).reduce(
            (result, surveyId) => [
              ...result,
              { groupName, id: surveyId, name: surveyCategory[surveyId] },
            ],
            [] as Survey[],
          );

          return [...result, ...survey];
        }, [] as Survey[]),
      )
      .then((surveys) => setSurveys(surveys));

  useEffect(() => {
    getSurveys();
    //eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return { surveys, refetchSurveys: getSurveys };
};
