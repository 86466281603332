import { t } from 'i18next';
import React from 'react';
import styled from 'styled-components';
import { Top5 } from '../model/top5';
import { CircleNumber } from './CircleNumber';
import { TextArea } from './TextArea';

const TableRow = ({ index, item }: { index: number; item: Top5 }) => {
  return (
    <tr>
      <TableData>
        <CircleNumber size="l" value={index} />
      </TableData>
      <TableData>
        <Statement>{item.statement}</Statement>
      </TableData>
      <TableData>{item.performance}</TableData>
      <TableData>{item.importance}</TableData>
      <TableData>
        <img
          src="/assets/images/OrangeArrow.png"
          height="30px"
          alt="OrangeArrow"
        />
      </TableData>
      <TableData>
        <TextArea />
      </TableData>
    </tr>
  );
};

export const PrioritiesTable: React.FC<{ top5: Top5[] }> = ({ top5 }) => {
  return (
    <Table>
      <thead>
        <tr>
          <TableHeader></TableHeader>
          <TableHeader>{t('priorities.topFive')}</TableHeader>
          <TableHeader>{t('priorities.performance')}</TableHeader>
          <TableHeader>{t('priorities.importance')}</TableHeader>
          <TableHeader></TableHeader>
          <TableHeader>{t('priorities.recommended')}</TableHeader>
        </tr>
      </thead>
      <tbody>
        {top5.map((statement, index) => (
          <TableRow item={statement} index={index + 1} key={index} />
        ))}
      </tbody>
    </Table>
  );
};

const Table = styled.table`
  margin-top: 80px;
  margin-left: auto;
  margin-right: auto;
  border-collapse: collapse;
`;

const TableHeader = styled.th`
  padding: 8px;
  text-align: left;
  font-weight: normal;
  :nth-child(2),
  :nth-child(3) {
    text-align: center;
  }
`;

export const TableData = styled.td`
  padding-left: 8px;
  background-color: #eeeeee;
  border-bottom: 10px solid white;

  :first-child {
    width: 5%;
  }
  :nth-child(2) {
    width: 45%;
  }
  :nth-child(3) {
    text-align: center;
    width: 5%;
  }
  :nth-child(4) {
    width: 5%;
  }
  :nth-child(5) {
    text-align: center;
    background-color: #ffffff;
    width: 5%;
  }
  :nth-child(6) {
    width: 35%;
  }
`;

const Statement = styled.span`
  font-size: '12px';
  width: '300px';
`;
