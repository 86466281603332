import { Grid, MenuItem, TextField } from '@mui/material';
import { useState } from 'react';
import { InviteMultiple } from './InviteMultiple';
import { InviteSingle } from './InviteSingle';
import { InviteWithoutEmail } from './InviteWithoutEmail';
import { t } from 'i18next';

export const InviteRespondents = () => {
  const inviteOptions = [
    {
      value: 'multiple',
      label: t('common.inviteMultiple'),
    },
    {
      value: 'single',
      label: t('common.inviteSingle'),
    },
    {
      value: 'withoutEmail',
      label: t('common.inviteWithoutEmail'),
    },
  ];

  const [inviteOption, setInviteOption] = useState(inviteOptions[0]);

  return (
    <Grid
      container
      spacing={2}
      style={{
        marginTop: 20,
      }}
    >
      <Grid item xs={12}>
        <TextField
          select
          label={t('common.typeOfInvitation')}
          fullWidth
          onChange={(e) => {
            const option = inviteOptions.find(
              (option) => option.value === e.target.value,
            );

            if (option) {
              setInviteOption(option);
            }
          }}
          value={inviteOption.value}
        >
          {inviteOptions.map((option) => (
            <MenuItem key={option.value} value={option.value}>
              {option.label}
            </MenuItem>
          ))}
        </TextField>
      </Grid>

      {inviteOption.value === 'multiple' && (
        <Grid item xs={12}>
          <InviteMultiple />
        </Grid>
      )}
      {inviteOption.value === 'single' && (
        <Grid item xs={12}>
          <InviteSingle />
        </Grid>
      )}
      {inviteOption.value === 'withoutEmail' && (
        <Grid item xs={12}>
          <InviteWithoutEmail />
        </Grid>
      )}
    </Grid>
  );
};
