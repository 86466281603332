import { TFunction, t } from 'i18next';
import { useContext } from 'react';
import styled from 'styled-components';
import { AppendixDataContext } from '../../context/AppendixContext';
import { PagePrintContext } from '../../context/PagePrintContext';
import { PeriodData } from '../../context/ReportDataContext';
import { getPerfvsImportanceData } from '../../utils/dataExtractFunctions';
import { PrintPage } from '../PrintPage/PrintPage';
import { Text } from '../Text';
import { Header } from './components/Header';
import { SpiderChart } from './components/SpiderChart';

interface CategoryObject {
  category: Array<{ label: string; toolText?: string }>;
}

interface DatasetObject {
  seriesname: string;
  data: Array<{ value: number }>;
}

export interface SpiderChartData {
  categories: [CategoryObject];
  dataset: [DatasetObject, DatasetObject];
}

function getSpiderChartData(
  storeData: PeriodData,
  t: TFunction<'translation', undefined, 'translation'>,
): SpiderChartData | null {
  if (!storeData) {
    return null;
  }

  const categoriesObject: CategoryObject = {
    category: [],
  };

  const howWellSeries: DatasetObject = {
    seriesname: t('priorities.performance'),
    data: [],
  };
  const howImportantSeries: DatasetObject = {
    seriesname: t('priorities.importance'),
    data: [],
  };

  storeData.data.forEach((item: any, index: number) => {
    categoriesObject.category.push({
      label: item.label.replace(/^\d+\. /, ''),
      toolText: item.label.replace(/^\d+\. /, ''),
    });

    howWellSeries.data.push({ value: item.howWell });
    howImportantSeries.data.push({ value: item.howImportant });
  });

  return {
    categories: [categoriesObject],
    dataset: [howWellSeries, howImportantSeries],
  };
}

export const PerformanceVSImportancePage = () => {
  const { skippedPages } = useContext(PagePrintContext);

  const {
    state: { extractedCompareData, period },
  } = useContext(AppendixDataContext);

  return (
    <PrintPage
      className={
        skippedPages.includes(t('reports.performanceVSImportance'))
          ? 'no-print'
          : ''
      }
    >
      <Header title={t('reports.performanceVSImportance')} resultGroup={3} />

      <ContentWrapper>
        <TextWrapper>
          <Text
            size="l"
            value={`${t('reports.radarDiagramDescriptionFirstPart')} ${t(
              'reports.radarDiagramDescriptionSecondPart',
            )}`}
          />
        </TextWrapper>
        <ChartWrapper>
          <SpiderChart
            chartData={getSpiderChartData(
              getPerfvsImportanceData(extractedCompareData, period.displayName),
              t,
            )}
          />
        </ChartWrapper>
      </ContentWrapper>
    </PrintPage>
  );
};

const TextWrapper = styled.div`
  width: 100%;
  padding: 20px;
  > div {
    margin-bottom: 10px;
  }
`;

const ContentWrapper = styled.div`
  display: flex;
  flex-direction: column;
`;

const ChartWrapper = styled.div``;
