import { IDataSet } from '../../../api/reports';

export const findDataSetWithSmallestValue = (dataSets: IDataSet[]): number => {
  if (dataSets.length === 0) {
    return 0;
  }

  let smallestValue = Infinity;
  let smallestDataSet = null;

  for (const dataSet of dataSets) {
    const currentSmallestX = Math.min(
      ...dataSet.data.map((point) => parseInt(point.x, 10)),
    );
    const currentSmallestY = Math.min(
      ...dataSet.data.map((point) => parseInt(point.y, 10)),
    );

    const minXY = Math.min(currentSmallestX, currentSmallestY);

    if (minXY < smallestValue) {
      smallestValue = minXY;
      smallestDataSet = dataSet;
    }
  }
  let smallestValueInChart = 0;

  if (smallestDataSet?.data.length) {
    if (Number(smallestDataSet.data[0].x) < Number(smallestDataSet.data[0].y)) {
      smallestValueInChart = Number(smallestDataSet.data[0].x);
    } else {
      smallestValueInChart = Number(smallestDataSet.data[0].y);
    }
  }

  return smallestValueInChart;
};

export const lowerTennerValue = (value: number) => {
  // Ensure the input value is between 0 and 100
  value = Math.max(0, Math.min(100, value));

  // Round down to the lower tenner value
  let tennerValue = Math.floor(value / 10) * 10;

  return tennerValue;
};

export const getChartAxisValue = (value: number) => {
  // Ensure the input value is between 0 and 100
  value = Math.max(0, Math.min(100, value));

  // Calculate the result based on floor division by 10 multiplied by 10
  return Math.floor(value / 10) * 10;
};

export const getBGImagePerAxisValue = (axisValue: number) => {
  return `/assets/scatterChart/chart${axisValue}.png`;
};
