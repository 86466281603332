import React, { createContext, ReactNode, useReducer } from 'react';
import { Period } from '../model';
import { ExtractDataType } from '../utils/dataExtractFunctions';

export enum ActionTypes {
  SET_PERIOD = 'SET_PERIOD',
  SET_TOTAL = 'SET_TOTAL',
  SET_EXTRACTED_COMPARE_DATA = 'SET_EXTRACTED_COMPARE_DATA',
}

export interface AppendixDataState {
  period: Period;
  total: {
    howWell: string;
    howImportant: string;
  };
  extractedCompareData: ExtractDataType[];
}

interface SetPeriodAction {
  type: ActionTypes.SET_PERIOD;
  payload: Period;
}

export interface Total {
  howWell: string;
  howImportant: string;
  opportunityIndex: number;
}
interface SetTotalAction {
  type: ActionTypes.SET_TOTAL;
  payload: Total;
}

interface SetExtractedCompareData {
  type: ActionTypes.SET_EXTRACTED_COMPARE_DATA;
  payload: ExtractDataType[];
}

export type AppendixDataAction =
  | SetPeriodAction
  | SetTotalAction
  | SetExtractedCompareData;

const initialState: AppendixDataState = {
  period: {
    id: '',
    displayName: '',
  },
  total: {
    howWell: '',
    howImportant: '',
  },
  extractedCompareData: [],
};

const reducer = (state: AppendixDataState, action: AppendixDataAction) => {
  switch (action.type) {
    case ActionTypes.SET_PERIOD:
      return { ...state, period: action.payload };
    case ActionTypes.SET_TOTAL:
      return {
        ...state,
        total: action.payload,
      };
    case ActionTypes.SET_EXTRACTED_COMPARE_DATA:
      return {
        ...state,
        extractedCompareData: action.payload,
      };
    default:
      throw new Error(`Unsupported action type: ${(action as any).type}`);
  }
};

export const AppendixDataContext = createContext<{
  state: AppendixDataState;
  appendixDispatch: React.Dispatch<AppendixDataAction>;
}>({
  state: initialState,
  appendixDispatch: () => null,
});

export const AppendixDataProvider: React.FC<{ children: ReactNode }> = ({
  children = null,
}) => {
  const [state, appendixDispatch] = useReducer(reducer, initialState);

  return (
    <AppendixDataContext.Provider value={{ state, appendixDispatch }}>
      {children}
    </AppendixDataContext.Provider>
  );
};
