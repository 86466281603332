import axios from 'axios';
import { t } from 'i18next';
import { toast } from 'react-toastify';
import {
  INewQuestionTemplate,
  ITemplate,
} from '../stores/QuestionTemplateStore';
import { getSelectedCompanyFromLocalStorage } from '../utils/LocalStorage';

export const createNewTemplate = async (template: INewQuestionTemplate) => {
  try {
    const company = getSelectedCompanyFromLocalStorage();
    const { data } = await axios.post('/template/createTemplate', {
      ...template,
      companyId: company?.companyId,
    });

    return data;
  } catch (e) {
    toast.error(t('common.templateAlreadyExists'));
    throw e;
  }
};

export const deleteTemplate = async (templateId: string) => {
  try {
    const { data } = await axios.delete(
      `/template/deleteTemplate/${templateId}`,
    );

    return data;
  } catch (e) {
    throw e;
  }
};

export const editQuestionTemplate = async (template: ITemplate) => {
  try {
    const { data } = await axios.put(
      `/template/editTemplate/${template.templatesId}`,
      template,
    );

    return data;
  } catch (e) {
    throw e;
  }
};

export const getTemplateById = async (id: string): Promise<ITemplate> => {
  try {
    const {
      data: { result },
    } = await axios.get(`/template/getTemplate/${id}`);

    if (!Array.isArray(result.template)) {
      result.template = Object.values(result.template);
    }

    return result;
  } catch (e) {
    throw e;
  }
};

export const getTemplates = async (): Promise<ITemplate[]> => {
  try {
    const { data } = await axios.get('/template/getTemplates');

    return data.result;
  } catch (e) {
    throw e;
  }
};

export const getTemplatesByCompany = async (): Promise<ITemplate[]> => {
  const company = getSelectedCompanyFromLocalStorage();

  try {
    const { data } = await axios.get('/template/getTemplates');

    const { data: questionTemplatesPerCompany } = await axios.get(
      `/template/getTempaltesByCompany/${company?.companyId}`,
    );

    const filteredTemplates = questionTemplatesPerCompany.result.map(
      (template: any) => {
        return data.result.find(
          (questionTemplate: ITemplate) =>
            questionTemplate.templatesId === template.templateId,
        );
      },
    );

    return filteredTemplates;
  } catch (e) {
    throw e;
  }
};

export const getAssignedQuestionTemplates = async (
  companyId: string,
): Promise<ITemplate[]> => {
  try {
    const { data } = await axios.get(
      `template/getTempaltesByCompany/${companyId}`,
    );

    return data.result;
  } catch (e) {
    throw e;
  }
};

export const addTemplatesToCompany = async (
  companyId: string,
  templates: string[],
) => {
  try {
    const { data } = await axios.post(`/template/addTemplatestoCompany`, {
      companyId,
      templatesId: templates,
    });

    return data.result;
  } catch (e) {
    throw e;
  }
};
