import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import dayjs, { Dayjs } from 'dayjs';
import * as React from 'react';

interface ICustomDatePicker {
  value: string | null;
  setValue: React.Dispatch<React.SetStateAction<Dayjs | string | null>>;
  label: string;
}

export const CustomDatePicker = ({
  setValue,
  label,
  value = '',
}: ICustomDatePicker) => {
  let parsedDate = value ? new Date(value) : null;

  return (
    <LocalizationProvider dateAdapter={AdapterDayjs}>
      <DatePicker
        label={label}
        value={dayjs(parsedDate)}
        onChange={(newValue) => {
          if (newValue) {
            setValue(dayjs(newValue).format('YYYY-MM-DD'));
          }
        }}
      />
    </LocalizationProvider>
  );
};
