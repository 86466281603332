import DeleteIcon from '@mui/icons-material/Delete';
import EditIcon from '@mui/icons-material/Edit';
import { Autocomplete, Button, Grid, TextField } from '@mui/material';
import axios from 'axios';
import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate, useParams } from 'react-router-dom';
import styled from 'styled-components';
import { useSurveys } from '../../api';
import { EditSurvey } from '../../components/Survey/EditSurvey';
import { Survey } from '../../model';
import { useAuth } from '../../providers/auth';

export const ManageSurvey = () => {
  const { surveys, refetchSurveys } = useSurveys();
  const [selectedSurvey, setSelectedSurvey] = useState<Survey | null>(null);
  const [createNewSurvey, setCreateNewSurvey] = useState(false);
  const [surveyData, setSurveyData] = useState<any>({});
  const navigate = useNavigate();
  const { user } = useAuth();
  const { surveyId } = useParams();
  const { t } = useTranslation();

  useEffect(() => {
    if (surveyId) {
      const newSurvey = surveys.find((survey) => survey.id === surveyId);

      if (newSurvey) {
        setSelectedSurvey(newSurvey);
      }
    }
  }, [surveyId, surveys]);

  useEffect(() => {
    const fetchSurveyData = async () => {
      const response = await axios.post(`/getSurvey`, {
        mode: 'open',
        object: 'query',
        id: selectedSurvey?.id,
      });
      setSurveyData(response.data.result);
    };

    if (selectedSurvey) {
      fetchSurveyData();
    }
  }, [selectedSurvey]);

  return (
    <PageWrapper>
      <Grid container spacing={2}>
        <Grid item xs={6}>
          <Autocomplete
            sx={{ width: '100%' }}
            options={surveys}
            onChange={(event, newValue) => {
              setSelectedSurvey(newValue);

              if (newValue === null && surveyId) {
                navigate('/manageSurvey');
              }
            }}
            renderInput={(params) => (
              <TextField {...params} label={t('surveys.selectSurveyType')} />
            )}
            groupBy={(options) => options.groupName}
            getOptionLabel={(option) => option.name}
            value={selectedSurvey}
          />
        </Grid>

        <Grid
          item
          xs={6}
          style={{
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'flex-start',
          }}
        >
          <SurveyButtons
            variant="contained"
            onClick={() => {
              setSelectedSurvey(null);
              setCreateNewSurvey(true);
            }}
          >
            {t('common.addNew')}
          </SurveyButtons>

          {createNewSurvey && !selectedSurvey && (
            <SurveyButtons
              variant="outlined"
              onClick={() => {
                setCreateNewSurvey(false);
                setSelectedSurvey(null);
              }}
            >
              {t('common.cancel')}
            </SurveyButtons>
          )}

          {selectedSurvey && (
            <>
              <SurveyButtons
                variant="contained"
                endIcon={<EditIcon />}
                onClick={() => {
                  setCreateNewSurvey(false);

                  navigate(`/manageSurvey/${selectedSurvey.id}/managePeriods`);
                }}
              >
                {t('common.viewPeriods')}
              </SurveyButtons>

              <SurveyButtons
                variant="outlined"
                endIcon={<DeleteIcon />}
                onClick={async () => {
                  if (
                    window.confirm(
                      t('common.deleteSurveyConfirmMessage') ||
                        'Are you sure you want to delete this survey?',
                    )
                  ) {
                    setCreateNewSurvey(false);
                    setSelectedSurvey(null);

                    await axios.delete(`/deleteSurvey`, {
                      headers: {
                        Authorization: `Bearer ${user?.token}`,
                      },

                      data: {
                        mode: 'delete',
                        object: 'query',
                        id: selectedSurvey?.id,
                      },
                    });

                    navigate('/manageSurvey');
                  }
                }}
              >
                {t('common.deleteSurvey')}
              </SurveyButtons>
            </>
          )}
        </Grid>
      </Grid>

      {createNewSurvey && !selectedSurvey && (
        <EditSurvey
          callback={(surveyId) => {
            refetchSurveys();
            navigate(`/manageSurvey/${surveyId}`);
          }}
        />
      )}
      {selectedSurvey && <EditSurvey initialData={surveyData} />}
    </PageWrapper>
  );
};

const PageWrapper = styled.div`
  width: 1130px;
`;

const SurveyButtons = styled(Button)`
  margin-right: 20px;
`;
