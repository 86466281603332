import { Box, Button, Grid, TextField } from '@mui/material';
import { useFormik } from 'formik';
import { useEffect, useState } from 'react';
import { CustomDatePicker } from '../DatePicker/DatePicker';
import axios from 'axios';
import { getSelectedCompanyFromLocalStorage } from '../../utils/LocalStorage';
import { useParams } from 'react-router-dom';
import { toast } from 'react-toastify';
import { useTranslation } from 'react-i18next';
import { containsOnlyNumbersAndLetters } from '../../utils/helpers';

interface IPeriodDetails {
  code: string | null;
  periodid: string | null;
  date1: string | null;
  date2: string | null;
  othercompanycode: string | null;
  reminderdates: string | null;
  companyId: string | null;
  created: string | null;
  modified: string | null;
  createdBy: string | null;
  modifiedBy: string | null;
}

export const EditPeriodForm = ({
  initialData,
  handleClosePeriod,
}: {
  initialData?: IPeriodDetails;
  handleClosePeriod: () => void;
}) => {
  const selectedCompany = getSelectedCompanyFromLocalStorage();
  const { surveyId } = useParams();
  const { t } = useTranslation();

  const [initialValues, setInitialValues] = useState({
    code: '',
    periodid: '',
    date1: '',
    date2: '',
    othercompanycode: '',
    reminderdates: '',
    companyId: '',
    created: '',
    modified: '',
    createdBy: '',
    modifiedBy: '',
  });

  const validate = (values: IPeriodDetails) => {
    const errors: Partial<IPeriodDetails> = {};
    if (values.periodid && !containsOnlyNumbersAndLetters(values.periodid)) {
      errors.periodid = t('validation.onlyNumbersAndLettersAllowed');
    }
    return errors;
  };

  const formik = useFormik({
    initialValues: initialValues,
    onSubmit: async (values: any) => {
      try {
        if (initialData) {
          await axios.post('/createPeriod', {
            mode: 'save',
            code: surveyId,
            periodid: values.periodid,
            date1: values.date1,
            date2: values.date2,
            reminderdates: values.reminderdates,
            othercompanycode: values.othercompanycode,
            periodid_org: values.periodid,
            object: 'queryperiod',
            new: '',
            companyId: selectedCompany?.companyId,
            id: `s_${values.code}`,
            copycode: '',
          });

          toast.success(t('common.periodUpdatedSuccessfully'));
        } else {
          await axios.post('/createPeriod', {
            mode: 'save',
            code: surveyId,
            periodid: values.periodid,
            date1: values.date1,
            date2: values.date2,
            reminderdates: values.reminderdates,
            othercompanycode: values.othercompanycode,
            periodid_org: '',
            object: 'queryperiod',
            id: '',
            new: '1',
            companyId: selectedCompany?.companyId,
          });

          toast.success(t('common.periodCreatedSuccessfully'));

          handleClosePeriod();
        }
      } catch (error) {
        toast.error(t('common.somethingWentWrong'));
      }
    },
    enableReinitialize: true,
    validate,
    validateOnChange: true,
  });

  useEffect(() => {
    if (initialData) {
      setInitialValues({
        code: initialData.code || '',
        periodid: initialData.periodid || '',
        date1: initialData.date1 || '',
        date2: initialData.date2 || '',
        othercompanycode: initialData.othercompanycode || '',
        reminderdates: initialData.reminderdates || '',
        companyId: initialData.companyId || '',
        created: initialData.created || '',
        modified: initialData.modified || '',
        createdBy: initialData.createdBy || '',
        modifiedBy: initialData.modifiedBy || '',
      });
    }
  }, [initialData]);

  return (
    <form onSubmit={formik.handleSubmit}>
      <Grid
        container
        spacing={2}
        style={{
          marginTop: 20,
        }}
      >
        <Grid item xs={12}>
          <TextField
            label="Survey ID"
            variant="outlined"
            fullWidth
            disabled
            value={surveyId}
          />
        </Grid>
        <Grid item xs={12}>
          <TextField
            label="Unique period ID (can only be changed if there are no respondents)"
            variant="outlined"
            fullWidth
            {...formik.getFieldProps('periodid')}
            error={!!formik.errors.periodid}
          ></TextField>
        </Grid>
        <Grid item xs={6}>
          <CustomDatePicker
            label="First date of the period"
            setValue={(value) => formik.setFieldValue('date1', value)}
            {...formik.getFieldProps('date1')}
          />
        </Grid>

        <Grid item xs={6}>
          <CustomDatePicker
            label="Last date of the period"
            setValue={(value) => formik.setFieldValue('date2', value)}
            {...formik.getFieldProps('date2')}
          />
        </Grid>

        <Grid item xs={12}>
          <TextField
            label="Reminder dates (format yyyy-mm-dd, separate several dates with blanks)"
            variant="outlined"
            fullWidth
            {...formik.getFieldProps('reminderdates')}
          />
        </Grid>

        <Grid item xs={12}>
          <TextField
            label="Survey ID used by respondents in version 2, will be used to add respondents from version 2 in reports and manage respondents"
            variant="outlined"
            fullWidth
            {...formik.getFieldProps('othercompanycode')}
          />
        </Grid>

        <Grid item xs={12}>
          <Box display="flex" justifyContent="flex-end">
            <Button type="submit" variant="contained" color="primary">
              {t('common.save')}
            </Button>
          </Box>
        </Grid>
      </Grid>
    </form>
  );
};
