import { removeCompaniesFromLocalStorage } from '../../utils/LocalStorage';
import { useAuth } from './AuthProvider';

export const useLogout = () => {
  const { setUser } = useAuth();

  return () => {
    setUser(null);
    removeCompaniesFromLocalStorage();
  };
};
