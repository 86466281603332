import {
  CN,
  CZ,
  DK,
  NL,
  GB,
  FI,
  FR,
  DE,
  GR,
  HU,
  IT,
  JP,
  KR,
  PL,
  PT,
  RU,
  SK,
  ES,
  SE,
  TH,
  TR,
} from 'country-flag-icons/react/3x2';

export const COUNTRIES = [
  { value: 'CN', label: 'Simple Chinese' },
  { value: 'CZ', label: 'Czech' },
  { value: 'DK', label: 'Danish' },
  { value: 'NL', label: 'Dutch' },
  { value: 'EN', label: 'English' },
  { value: 'FI', label: 'Finnish' },
  { value: 'FR', label: 'French' },
  { value: 'DE', label: 'German' },
  { value: 'GR', label: 'Greek' },
  { value: 'HU', label: 'Hungarian' },
  { value: 'IT', label: 'Italian' },
  { value: 'JP', label: 'Japanese' },
  { value: 'KO', label: 'Korean' },
  { value: 'PL', label: 'Polish' },
  { value: 'PT', label: 'Portuguese' },
  { value: 'RU', label: 'Russian' },
  { value: 'SK', label: 'Slovak' },
  { value: 'ES', label: 'Spanish' },
  { value: 'SE', label: 'Swedish' },
  { value: 'TH', label: 'Thai' },
  { value: 'TR', label: 'Turkish' },
];

interface IRenderFlag {
  flag: string;
}

export const getCountryName = (flagName: string) =>
  COUNTRIES.find((country) => country.value === flagName.toUpperCase())?.label;

export const RenderFlag = ({ flag }: IRenderFlag) => {
  const getFlag = () => {
    switch (flag.toUpperCase()) {
      case 'CN':
        return <CN />;
      case 'CZ':
        return <CZ />;
      case 'DK':
        return <DK />;
      case 'NL':
        return <NL />;
      case 'EN':
        return <GB />;
      case 'FI':
        return <FI />;
      case 'FR':
        return <FR />;
      case 'DE':
        return <DE />;
      case 'GR':
        return <GR />;
      case 'HU':
        return <HU />;
      case 'IT':
        return <IT />;
      case 'JP':
        return <JP />;
      case 'KO':
        return <KR />;
      case 'PL':
        return <PL />;
      case 'PT':
        return <PT />;
      case 'RU':
        return <RU />;
      case 'SK':
        return <SK />;
      case 'ES':
        return <ES />;
      case 'SE':
        return <SE />;
      case 'TH':
        return <TH />;
      case 'TR':
        return <TR />;
      default:
        return <GB />;
    }
  };

  return (
    <div
      style={{
        height: '20px',
        width: '30px',
        margin: '0 10px',
      }}
    >
      {getFlag()}
    </div>
  );
};
