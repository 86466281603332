import styled from 'styled-components';

export type TextSizeType = 's' | 'm' | 'l' | 'xl' | 'xxl';
interface ITextProps {
  value: string;
  isBold?: boolean;
  noMarginBottom?: boolean;
  size?: TextSizeType;
}

export const getTextSize = (size: TextSizeType) => {
  switch (size) {
    case 's':
      return '12px';
    case 'm':
      return '15px';
    case 'l':
      return '18px';
    case 'xl':
      return '20px';
    case 'xxl':
      return '28px';

    default:
      return '15px';
  }
};
export const Text: React.FC<ITextProps> = ({
  value,
  isBold = false,
  noMarginBottom = false,
  size = 'm',
}) => {
  return (
    <Description noMarginBottom={noMarginBottom} isBold={isBold} size={size}>
      {value}
    </Description>
  );
};

const Description = styled.div<{
  isBold: boolean;
  noMarginBottom: boolean;
  size: TextSizeType;
}>`
  font-size: ${({ size }) => getTextSize(size)};
  font-weight: 400;
  color: #1f4b58;
  margin-bottom: ${({ noMarginBottom }) => (noMarginBottom ? '0' : '20px')};
  font-weight: ${({ isBold }) => (isBold ? '600' : '400')};
`;
