import React from 'react';
import FusionCharts from 'fusioncharts';
import charts from 'fusioncharts/fusioncharts.charts';
import ReactFusioncharts from 'react-fusioncharts';
import styled from 'styled-components';
import { ExtractDataType } from '../../../utils/dataExtractFunctions';

// Resolves charts dependancy
charts(FusionCharts);
interface BarChartProps {
  firstPeriodData: ExtractDataType[];
  secondPeriodData: ExtractDataType[];
  periods: (string | undefined)[];
}
interface Total {
  howWell: number;
}
export class BarChart extends React.Component<BarChartProps> {
  render() {
    const { firstPeriodData, secondPeriodData, periods } = this.props;

    const getPerforamnceIndexValues = (inputArray: ExtractDataType[]) => {
      const outputArray = [];

      for (const obj of inputArray) {
        if (!obj.subheadings.length) {
          continue;
        }
        if ((obj.total as unknown as Total)?.howWell) {
          outputArray.push({ value: (obj.total as unknown as Total).howWell });
        } else {
          for (const subheading of obj.subheadings) {
            if (subheading.howWell) {
              outputArray.push({ value: subheading.howWell });
            }
          }
        }
      }

      return outputArray;
    };

    const numberOfcategories = () => {
      const category = [];

      for (let i = 1; i < firstPeriodData.length; i++) {
        category.push({ label: String(i) });
      }
      return category;
    };

    const dataSource = {
      chart: {
        plotSpacePercent: 50,
        plottooltext: '<b>$dataValue</b>  <b>$seriesName</b> ',
        theme: 'fusion',
        palettecolors: '#00b6f1,#f47b30',
        showYAxisLine: '1',
        yAxisLineColor: '#1c2328',
        yAxisLineThickness: '1',
        showXAxisLine: '1',
        xAxisLineColor: '#1c2328',
        xAxisLineThickness: '1',
        labelWidth: '30',
        labelHeight: '30',
        labelFontColor: '#ffffff',
        labelBgColor: '#1f4b58',
        labelBorderPadding: '5',
        labelBorderRadius: '5',
        labelBorderThickness: '5',
        legendBorderAlpha: '0',
        legendPosition: 'right-top',
        legendBgAlpha: '0',
        legendItemFont: 'Roboto',
        legendItemFontSize: '18',
        legendItemFontBold: '1',
        interactiveLegend: '1',
        drawCustomLegendIcon: '1',
        legendIconSides: '60',
        numDivLines: '4',
        showLegend: '0',
        yaxismaxvalue: '100',
      },
      categories: [
        {
          category: numberOfcategories(),
        },
      ],
      dataset: [
        {
          seriesname: periods[0],
          drawAnchors: '0',
          data: getPerforamnceIndexValues(firstPeriodData),
        },
        {
          seriesname: periods[1],
          drawAnchors: '0',
          data: getPerforamnceIndexValues(secondPeriodData),
        },
      ],
    };

    return (
      <Chart>
        <ReactFusioncharts
          type="mscolumn2d"
          width="100%"
          height="100%"
          dataFormat="JSON"
          dataSource={dataSource}
        />
        <List>
          <Legend>{periods[0]}</Legend>
          <Legend>{periods[1]}</Legend>
          {firstPeriodData
            .slice(1)
            .map((period: ExtractDataType, index: number) => {
              const headingWithoutNumber = period.heading.replace(
                /^\d+\.\s/,
                '',
              ); //  regex to remove numbering from heading
              return <ListItem key={index}>{headingWithoutNumber}</ListItem>;
            })}
        </List>
      </Chart>
    );
  }
}

const Chart = styled.div`
  position: relative;
  display: flex;
  height: 450px;
  width: 700px;
`;

const List = styled.ol`
  list-style: none;
  counter-reset: item;
  font-size: 12px;
  flex-grow: 1;
  white-space: nowrap;
`;

const ListItem = styled.li`
  counter-increment: item;

  &::before {
    content: counter(item);
    margin-right: 10px;
    margin-bottom: 5px;
    padding: 5px;
    display: inline-flex;
    width: 18px;
    height: 18px;
    justify-content: center;
    align-items: center;
    border-radius: 5px;
    background: #1f4b58;
    color: #ffffff;
  }
`;

const Legend = styled.div`
  font-size: 18px;
  font-weight: bold;

  &:nth-child(1) {
    &::before {
      background: #00b6f1;
      color: #00b6f1;
    }
  }

  &:nth-child(2) {
    &::before {
      background: #f47b30;
      color: #f47b30;
      margin-bottom: 20px;
    }
  }

  &::before {
    content: '•';
    margin-right: 10px;
    margin-bottom: 5px;
    padding: 5px;
    display: inline-flex;
    width: 10px;
    height: 10px;
    justify-content: center;
    align-items: center;
    border-radius: 50%;
  }
`;
