import FusionCharts from 'fusioncharts';
import React, { FC } from 'react';
import ReactFusioncharts from 'react-fusioncharts';
import PowerCharts from 'fusioncharts/fusioncharts.powercharts';
import FusionTheme from 'fusioncharts/themes/fusioncharts.theme.fusion';
import ReactFC from 'react-fusioncharts';
import styled from 'styled-components';
import { SpiderChartData } from '../PerformanceVSImportancePage';
import { TextArea } from '../../TextArea';

ReactFC.fcRoot(FusionCharts, PowerCharts, FusionTheme);

const dataSource = {
  chart: {
    theme: 'gammel',
    palettecolors: '#00b6f1,#f47b30',
    showlegend: '1',
    showdivlinevalues: '0',
    showlimits: '0',
    showvalues: '0',
    showBorder: '0',
    showPlotBorder: '1',
    //this is set to 1 so that legend is visible, this is why legend is displayed as hollow cirtcle
    plotfillalpha: '1',
    // plottooltext: "Harry's <b>$label</b> skill is rated as <b>$value</b>",
    plotBorderAlpha: '100',
    bgColor: '#ffffff',
    radarBorderThickness: '1',
    radarBorderAlpha: '20',
    plotBorderThickness: '2',
    radarSpikeAlpha: '0',
    labelFontSize: '12',
    labelBorderPadding: '5',
    labelBorderThickness: '30',
    labelBorderRadius: '100',
    numDivLines: '11',
    divLineAlpha: '20',
    legendBorderAlpha: '0',
    legendPosition: 'top',
    legendBgAlpha: '0',
    legendItemFont: 'Roboto',
    legendItemFontSize: '18',
    legendItemFontBold: '1',
    interactiveLegend: '1',
    drawCustomLegendIcon: '1',
    legendIconSides: '0',
    valueBorderRadius: '22',
    borderRadius: '22',
    drawAnchors: '0',
  },
};

export const SpiderChart: FC<{ chartData: SpiderChartData | null }> = ({
  chartData,
}) => {
  return (
    <Chart>
      {chartData && (
        <ReactFusioncharts
          type="radar"
          width="100%"
          height="80%"
          dataFormat="JSON"
          dataSource={{ ...dataSource, ...chartData }}
        />
      )}

      <TextArea />
    </Chart>
  );
};

const Chart = styled.div`
  position: relative;
  display: flex;
  flex-direction: column;
  height: 580px;
  padding: 0 20px;
`;
