import {
  Button,
  Checkbox,
  FormControlLabel,
  Grid,
  TextField,
} from '@mui/material';
import { t } from 'i18next';
import { observer } from 'mobx-react';
import React, { useState } from 'react';
import styled from 'styled-components';
import { useStores } from '../../stores/hooks/useStores';

export const NewQuestionTemplate: React.FC = observer(() => {
  const [templateName, setTemplateName] = useState('');
  const [isMasterTemplate, setIsMasterTemplate] = useState(true);
  const {
    questionTemplateStore: { setIsNewTemplate, saveNewTemplate },
  } = useStores();

  const handleInputChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setTemplateName(event.target.value);
  };

  const handleSubmit = () => {
    try {
      saveNewTemplate(templateName, isMasterTemplate);
      setIsNewTemplate(false);

      if (templateName.trim() !== '') {
        setTemplateName('');
        setIsMasterTemplate(true);
      }
    } catch (error) {
      console.error(error);
    }
  };

  return (
    <Grid container spacing={2}>
      <Grid item xs={6}>
        <TextField
          label="Template Name"
          fullWidth
          variant="outlined"
          value={templateName}
          onChange={handleInputChange}
          margin="normal"
        />
      </Grid>
      <Grid
        item
        xs={6}
        style={{
          marginTop: '22px',
        }}
      >
        <FormControlLabel
          control={
            <Checkbox
              checked={isMasterTemplate}
              onChange={() => setIsMasterTemplate(!isMasterTemplate)}
              name="checked"
              color="primary"
            />
          }
          label="Set as master template"
        />
      </Grid>
      <Grid item xs={6}>
        <StyledButton
          variant="contained"
          color="primary"
          onClick={handleSubmit}
        >
          Create Template
        </StyledButton>
        <Button onClick={() => setIsNewTemplate(false)} variant="outlined">
          {t('common.cancel')}
        </Button>
      </Grid>
    </Grid>
  );
});

const StyledButton = styled(Button)`
  margin-right: 20px;
`;
