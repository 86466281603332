import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import TextField from '@mui/material/TextField';
import { t } from 'i18next';
import { observer } from 'mobx-react';
import * as React from 'react';
import { useStores } from '../../stores/hooks/useStores';

const SaveAsModal = () => {
  const {
    questionTemplateStore: {
      isSaveAsDialogOpen,
      setIsSaveAsDialogOpen,
      questionTemplate,
      saveAsNewTemplate,
    },
  } = useStores();

  const [name, setName] = React.useState(questionTemplate.name);
  const handleClose = () => {
    setIsSaveAsDialogOpen(false);
  };

  return (
    <Dialog
      open={isSaveAsDialogOpen}
      onClose={handleClose}
      PaperProps={{
        style: {
          width: '500px',
        },
        component: 'form',
        onSubmit: (event: React.FormEvent<HTMLFormElement>) => {
          event.preventDefault();

          saveAsNewTemplate(name, true, questionTemplate.template);

          handleClose();
        },
      }}
    >
      <DialogTitle>{t('common.saveAsANewQuestionTemplate')}</DialogTitle>
      <DialogContent>
        <DialogContentText>
          {t('common.questionTemplateNameMustBeUnique')}
        </DialogContentText>
        <TextField
          sx={{ marginTop: 2 }}
          label={t('common.QuestionTemplateName')}
          variant="outlined"
          fullWidth
          name="industry"
          value={name}
          onChange={(e) => setName(e.target.value)}
        />
      </DialogContent>
      <DialogActions>
        <Button variant="contained" color="primary" type="submit">
          {t('common.saveAs')}
        </Button>
        <Button onClick={handleClose}>Cancel</Button>
      </DialogActions>
    </Dialog>
  );
};

export default observer(SaveAsModal);
