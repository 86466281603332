import styled from 'styled-components';
import { CircleNumber } from '../../CircleNumber';
import { Logo } from '../../Logo/Logo';
import { PrintCheckbox } from '../../PrintCheckbox';
import { useContext, useState, useMemo } from 'react';
import { PagePrintContext } from '../../../context/PagePrintContext';
import { t } from 'i18next';

interface IHeaderProps {
  title: string;
  groupPageNum?: number;
  resultGroup?: number;
}

const headersWithGroup = [
  t('reports.performanceSummary'),
  t('reports.opportunityLandscape'),
  t('reports.performanceVSImportance'),
  t('reports.topFivePriorities'),
  t('reports.historicalComparison'),
];

const useFilteredHeaders = (skippedPages: string[]) => {
  const [groupDiff, setGroupDiff] = useState(0);

  const filteredArray = useMemo(() => {
    const filtered = headersWithGroup.filter(
      (element) => !skippedPages.includes(element),
    );
    setGroupDiff(headersWithGroup.length - filtered.length);
    return filtered;
  }, [skippedPages]);

  return { filteredArray, groupDiff };
};

export const Header: React.FC<IHeaderProps> = ({
  title,
  groupPageNum,
  resultGroup,
}) => {
  const { skippedPages } = useContext(PagePrintContext);
  const { filteredArray, groupDiff } = useFilteredHeaders(skippedPages);

  const calculateGroup = (title: string) => {
    return filteredArray.indexOf(title) + 1;
  };

  const titleWithGroupPageNum = groupPageNum
    ? title + ' ' + groupPageNum
    : title;

  return (
    <HeaderWrapper>
      <Title>
        {resultGroup && (
          <CircleNumber
            value={calculateGroup(title) || resultGroup - groupDiff}
            pushRight
            color="blue"
            size="l"
          />
        )}
        {title}
      </Title>
      <LogoWrapper>
        <PrintCheckboxWrapper>
          <PrintCheckbox title={titleWithGroupPageNum} />
        </PrintCheckboxWrapper>
        <Logo src="logo-inverted.png" />
      </LogoWrapper>
    </HeaderWrapper>
  );
};

const HeaderWrapper = styled.div`
  display: flex;
  height: 100px;
`;

const Title = styled.div`
  width: calc(100% - 280px);
  background: #eeeeee;
  border-bottom-right-radius: 20px;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  padding-left: 50px;
  font-size: 44px;
  color: #1f4b58;
  font-weight: 600;
  line-height: normal;
`;
const LogoWrapper = styled.div`
  width: 280px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
`;

const PrintCheckboxWrapper = styled.div`
  margin-left: auto;
`;
