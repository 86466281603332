import { t } from 'i18next';
import { useContext } from 'react';
import styled from 'styled-components';
import { PagePrintContext } from '../../context/PagePrintContext';
import { ReportDataContext } from '../../context/ReportDataContext';
import { PrintPage } from '../PrintPage/PrintPage';
import { PrioritiesTable } from '../PrioritiesTable';
import { Text } from '../Text';
import { Content } from './components/Content';
import { Header } from './components/Header';

export const TopFivePrioritiesPage = () => {
  const { skippedPages } = useContext(PagePrintContext);
  const { selectors } = useContext(ReportDataContext);

  return (
    <PrintPage
      className={
        skippedPages.includes(t('reports.topFivePriorities')) ? 'no-print' : ''
      }
    >
      <Header title={t('reports.topFivePriorities')} resultGroup={4} />

      <Content>
        <StyledText>
          <Text size="l" value={t('reports.topFivePrioritiesDescription')} />
        </StyledText>
        <PrioritiesTable top5={selectors.top5} />
      </Content>
    </PrintPage>
  );
};

const StyledText = styled.div`
  width: 700px;
`;
