import styled from 'styled-components';

export const PrintPage: React.FC<{ className?: string }> = styled.div`
  min-height: 800px;
  border: 1px solid grey;
  margin-bottom: 20px;

  @media print {
    page-break-after: always;
    zoom: 100%;
    margin: 0;
    padding: 0;
    min-height: 595px;
    border: 0;
    margin-bottom: 0;

    &.no-print {
      display: none;
    }
  }

  @page {
    size: A4 landscape;
  }
`;
